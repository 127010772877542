import React from 'react';

const LearnerNavTop = ( props ) =>{

    return(
        <div className={ 'row' }>

            {
                ( props.fHasAccess( 12 ) )
                ?   <div className={ 'col-sm-3 p-1' }>
                        <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                    action      = 'true'
                                    onClick     = { props.SwitchEditingMode } >Edit</button>
                    </div>
                : ""
            }


            <div className={ 'col-sm-3 p-1' }>
                <button className={'btn btn-dark btn-sm border-warning btn-block'} onClick={ props.LoadDashHandler }>Back</button>
            </div>

            {/* <div className={ 'col-sm-3 p-1' }>
                <button className={'btn btn-dark btn-sm border-warning btn-block'}>Report 1</button>
            </div>

            <div className={ 'col-sm-3 p-1' }>
                <button className={'btn btn-dark btn-sm border-warning btn-block'}>Report 2</button>
            </div> */}
        </div>
    );

}

export default LearnerNavTop;
import React from 'react';

const UserRow = ( props ) =>{

        return(
            <div    className   =   {'row border-warning text-white m-2 p-1'}
                    style       =   {{  border          : "1px solid white",
                                        borderRadius    : "0.3em",
                                        fontSize        : "1.2vw" }}>

                <div className={'col-sm-2'}>
                    <button     onClick     =   { props.loadUserViewHandler }
                                data-userid =   { props.data.id }
                                className   =   { 'btn btn-dark border-warning btn-sm' } >
                        VIEW
                    </button>
                </div>

                <div className={'col-sm-4'}>
                    { props.data.firstname } { props.data.lastname } <br />

                    { ( props.data.email !== "" )
                        ?   <div>
                                <img    src     =   { require( "../../icon/icon_mail.png" ) }
                                        className   =   { 'mr-2' }
                                        style   =   {{ height: "20px" }}
                                        alt     =   'Icon mail' />
                                <span className={ 'text-warning' } >{ props.data.email }</span>
                            </div>
                        :   "" }
                </div>

                <div className={'col-sm-4'}>
                    { props.data.department }<br />
                    { ( props.data.contactNo !== "" )
                        ?   <div>
                                <img    src     =   { require( "../../icon/icon_phone.png" ) }
                                        style   =   {{ height: "20px" }}
                                        className   =   { 'mr-2' }
                                        alt     =   'Icon phone' />
                                { props.data.contactNo }
                            </div>
                        :   "" }
                </div>

                <div className={'col-sm-2'}>
                    <img    src     =   { require( "../../icon/icon_delete.png" ) }
                            onClick =   { props.deleteUserHandler }
                            userid  =   { props.data.id }
                            style   =   {{  height: "20px",
                                            cursor: "pointer",
                                            borderRadius: "0.3em",
                                            border: "1px solid red",
                                            padding: "3px" }} alt='Icon delete' />
                </div>

            </div>
        )
}

export default UserRow;
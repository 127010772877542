import React from 'react'

const RowSearchResult = ( props ) =>{


    return(

            <div className={ 'card card-warning bg-dark border-warning m-1 p-2 text-white' }>

                <div className={ "container" }>
                    <div className={ "row" }>

                        <div className={ "col-sm-3" }>
                            <button     onClick             =   { props.SelectEditEvent }
                                        eventid             =   { props.data.eventID }
                                        className           =   { 'btn btn-dark border-warning btn-sm' } >
                                VIEW
                            </button>
                        </div>

                        <div className={ "col-sm-6" } style={{ fontSize: "1.3vw" }}>
                            <div>{ props.data.eventName }</div>
                            <div    className   =   { 'text-warning' }
                                    style       =   {{ fontSize: "1.1vw" }}>{ props.data.courseName }</div>
                        </div>

                        <div className={ "col-sm-3" }>
                            <img    src                 =   { require( "../../icon/icon_delete.png" ) }
                                    className           =  { "float-right" }
                                    onClick             =   { props.ModalRequestRemoveEventHandler }
                                    action              =   "confirm"
                                    eventid             =   { props.data.eventID }
                                    style               =   {{  height: "20px",
                                                                cursor: "pointer",
                                                                borderRadius: "0.3em",
                                                                border: "1px solid red",
                                                                padding: "3px" }}
                                    alt                 =   'Icon delete' />
                        </div>

                    </div>
                </div>
            </div>
    )
}

export default RowSearchResult
import React, {Component} from 'react'

import RowMessage from './RowMessage'

class LearnerMessages extends Component{

    constructor( props ){
        super( props )
        this.state={
                listMessages: [],
                messageHthml: ''
        }
        this.DeleteMessage = this.DeleteMessage.bind( this )

        console.log( "LearnerMessages : " + this.props.editLearnerID );
    }

    //***  FETCH THE LEARNER MESSAGES ***//
    async fetchLearnerMessages(){

        const inputData =   {
                                class: "messages",
                                method: "fetchLearnerMessages",
                                data: { learnerID       : this.props.editLearnerID  }
        }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){

                    this.setState( {    listMessages : getDataResponse.data,
                                        messageHthml : getDataResponse.data.map( ( row )=>
                            <RowMessage     key             =   { row.messageID }
                                            data            =   { row }
                                            fHasAccess      =   { this.props.fHasAccess }
                                            DeleteMessage   =   { this.DeleteMessage } />
                        )})

                }
            } catch( err ){ console.log( err ) }
    }

    //***  DELETE LEARNER MESSAGE ***//
    async DeleteMessage( e ){
        //console.log( "Delete message ID : " + e.target.getAttribute( "messageid" ) )
        this.setState( { messageHthml : '', listMessages: [] } )

        const inputData =   {
                                class: "messages",
                                method: "deleteMessage",
                                data: { messageID       : e.target.getAttribute( "messageid" )  }
        }
            try{    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                    if( getDataResponse.result === "success" ){
                        this.fetchLearnerMessages()
                    }
            } catch( err ){ console.log( err ) }
    }

    componentWillMount(){
        this.fetchLearnerMessages();
    }

    render(){


        return(

            <div className={ 'col-sm-9' }>
                <div className={'container idzDark text-white p-2'}>
                    <div className={ 'col-sm-12' }>
                        <h4 className={ "text-white" } >
                            Messages count : { this.state.listMessages.length }
                        </h4>
                    </div>
                    <div className={ 'col-sm-12' } style={{ height: "70vh", overflowY: "auto", overflowX: "hidden" }} >
                        { this.state.messageHthml }
                    </div>
                </div>
            </div>
        )
    }
}

export default LearnerMessages
import React from 'react'
//import { CSVLink, CSVDownload } from 'react-csv';
import { CSVLink } from 'react-csv';
import Auxiliary from '../HOC/Auxiliary'

const DownloadCSV = ( props ) =>{

        return(
            <Auxiliary>
                <CSVLink filename={ props.filename } data={ props.data } headers={ props.headers }>
                    <button     className   = { 'btn btn-danger btn-sm m-2'}
                                onClick     = { props.hide } >Download file</button>

                </CSVLink>
                <button     className   = { 'btn btn-dark border-danger btn-sm ml-2' }
                                onClick     = { props.hide } >Cancel</button>
            </Auxiliary>
        )
}

export default DownloadCSV
import React from 'react'

const Message = ( props ) =>{


    return(
        <div className={ "text-center" } >
            <textarea   className   =   { 'form-control' }
                        placeholder =   "Enter your message here"
                        style       =   {{ height: '200px' }}
                        name        =   'message'
                        id          =   'message'
                        value       =   { props.message }
                        onChange    =   { props.formFieldUpdate } >
            </textarea>

            <button className   =   { 'btn btn-warning border-orange mt-3 mx-auto' }
                    onClick     =   {  props.sendMessageHandler }>
                SEND MESSAGE NOW
            </button>
        </div>
    )
}

export default Message
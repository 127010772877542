import React from 'react'


const CourseProgressBlock =( props ) => {

    /*
        Expects:

            Guideline: An event is inked to a course. A course is offered by a service provider.

            Course status : Event has passed, Happening now, Upcoming
            Course name
            Course endDate
            Course dates []
            Service Provider name
            Course completion status
            Attendance confirmed []

            upcoming     in progress    completed
    */
    const data          = props.row;
    const today         = new Date().toISOString().slice(0, 10)

    console.log( data.endDate + " : " + today )

    let   eventStatus   = ( data.endDate > today ) ? "UPCOMING" : "PASSED"
          eventStatus   = ( data.endDate === today ) ? "IN PROGRESS" : eventStatus

    let color = 'warning'

    switch( eventStatus ){
        case( 'UPCOMING' ):
            color = 'info'
            break

        case( 'PASSED' ):
            color = 'warning'
            break

        case( 'IN PROGRESS' ):
            color = 'success'
            break

        default:
            break
    }

    return(
        <div className={ 'row text-white idzDark' }>

                        <div className={ 'col-sm-2 p-1' }>
                            <div className={ 'card border-' + color + ' bg-dark text-center p-2' }
                                    style={{ height: '100%' }} >
                            <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} >{ eventStatus }</h5>
                            </div>

                        </div>

                        <div className={ 'col-sm-8 p-1' }>
                            <div className={ ' card border-' + color + ' bg-dark text-left p-2' } style={{ height: '100%' }} >
                                <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} >{ data.courseName }</h5>
                                    <table style={{ fontSize: '1vw' }}>
                                        <tbody>
                                            <tr>
                                                <th>Started</th>
                                                <td>{ data.eventDates }</td>
                                            </tr>
                                            <tr>
                                                <th>Completion status</th>
                                                <td className={'text-danger pl-2'}>Not completed yet</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>

                        <div className={ 'col-sm-2 p-1' }>
                            <div className={ ' card border-' + color + ' bg-dark text-center align-middle p-2' } style={{ height: '100%' }} >
                                <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} >{ data.completionStatus }</h5>
                            </div>
                        </div>

                    </div>
    )
}

export default CourseProgressBlock
import React from 'react';


const SidebarHome = ( props ) =>{


    return(
        <div className={ 'col-md-3'}>
            <div className={ 'btn-group btn-group-toggle' } data-toggle='buttons'>

                <label className={ 'btn btn-dark active btn-outline-warning' }
                        onClick     = { props.showRolesListHandler }>
                <input  type        =   'radio'
                        name        =   'options'
                        id          =   'option1'
                        defaultChecked
                        readOnly /> Roles
                </label>

                <label  className   =   { 'btn btn-dark btn-outline-warning' }
                        onClick     =   { props.showRightsListHandler }>
                <input  type        =   'radio'
                        name        =   'options'
                        id          =   'option2'

                        readOnly /> Rights
                </label>
            </div>
        </div>
    );

}

export default SidebarHome;
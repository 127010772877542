import React, { Component } from 'react';

import HtmlModal from '../Helpers/HtmlModal'

import RowSearchResult from './RowSearchResult'

//***  SIDEBAR COMPONENTS  ***//
import SidebarHome from './SidebarHome'
import SidebarBlank from './SidebarBlank'
import SidebarSP from   './SidebarSP'

//***  CONTENT COMPONENTS  ***//
import BoxDash from './BoxDash'
import EditServiceProvider from './EditServiceProvider'
import BoxSPDocuments from './BoxSPDocuments'
import BoxTrainingHistory from './BoxSPTrainingHistory'

class ServiceProviders extends Component {

    constructor( props ){
        super( props )
        this.state = {
            sidebar   : props.sidebar,
            box       : props.box,

            serviceProvidersCount   :   "...loading...",

            searchTerm          : '',
            listSearch          : [],
            searchResultsHtml   : '',

            editServiceProviderID   : '',
            editingServiceProvider  : false,
            profileStatus           : "pending",
            profileStatusColor      : "danger",

            modal:  {   title       :      '',
                        message     :    '',
                        btnAction   :  '',
                        btnCancel   :   '',
                        UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                        actionMethod:   null,
                        data        :   null }
        }

        this.LoadDashHandler                = this.LoadDashHandler.bind( this )
        this.LoadDocumentsHandler           = this.LoadDocumentsHandler.bind( this )
        this.CreateNewServiceProvider       = this.CreateNewServiceProvider.bind( this )
        this.UpdateSearchTerm               = this.UpdateSearchTerm.bind( this )
        this.UpdateFormField                = this.UpdateFormField.bind( this )
        this.SearchProvidersHandler         = this.SearchProvidersHandler.bind( this )
        this.SelectServiceProviderHandler   = this.SelectServiceProviderHandler.bind( this )
        this.DeleteServiceProviderHandler   = this.DeleteServiceProviderHandler.bind( this )
        this.ViewEditServiceProviderHandler = this.ViewEditServiceProviderHandler.bind( this )
        this.SetProfileStatus               = this.SetProfileStatus.bind( this )
        this.ConfirmMakeServiceProviderActive = this.ConfirmMakeServiceProviderActive.bind( this )
        this.makeProfileActive              = this.makeProfileActive.bind( this )
        this.ViewTrainingHistoryHandler     = this.ViewTrainingHistoryHandler.bind( this )
    }

    componentDidMount(){
        this.FetchSPStatsHandler();
    }

    LoadDocumentsHandler(){
        this.setState( { box: "BoxSPDocuments" } )
    }

    SelectServiceProviderHandler( e ){
        const spID = e.target.getAttribute( "serviceproviderid" )
        this.setState( { editServiceProviderID: spID }, ()=>{ this.ViewEditServiceProviderHandler() } )
    }

    ViewTrainingHistoryHandler( e ){
        this.setState( { box: "BoxTrainingHistory" } )
    }

    ViewEditServiceProviderHandler(){
        this.setState( {    sidebar : "SidebarSP",
                            box     : "EditServiceProvider" } )
    }

    async FetchSPStatsHandler(){

        const inputData =   {
                                class: "serviceproviders",
                                method: "fetchSPStats",
                                data:   null
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            this.setState( { serviceProvidersCount : getDataResponse.data.serviceProvidersCount } )
                        }
                } catch( err ){ console.log( err ) }
    }

    async UpdateServiceProviderStatus (){

        const inputData =   {
                                class: "serviceproviders",
                                method: "updateSPStatus",
                                data:   {   serviceProviderID   : this.state.serviceProviderID,
                                            status              : "active" }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            const modal = this.state.modal

                            modal["title"]          = "SYSTEM CONFIRMATION"
                            modal["message"]        = "Service Provider status updated successfully"
                            modal["btnAction"]      = "OK"
                            modal["actionMethod"]   = null
                            modal["btnCancel"]      = "Cancel"
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATE, EDIT SAVE SERVICE PROVIDER  ***//
    async SearchProvidersHandler(){

        if( this.state.searchTerm.length === 0 ){
            return
        }

        //Clear current searchResults
        this.setState( { listsearch : [], searchResultsHtml : "" } )

        const inputData =   {
                                class: "serviceproviders",
                                method: "searchProviders",
                                data: { searchTerm : this.state.searchTerm }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){

                            console.log( getDataResponse.data );

                            this.setState( { listSearch : getDataResponse.data,
                                            searchResultsHtml : getDataResponse.data.map( ( row )=>
                                                <RowSearchResult    key             = { row.providerID }
                                                                    data            = { row }
                                                                    SelectServiceProviderHandler = { this.SelectServiceProviderHandler }
                                                                    DeleteServiceProviderHandler = { this.DeleteServiceProviderHandler } />
                                            ) } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  DELETE AND CONFIRM SERVICE PROVIDER  ***//
    async DeleteServiceProviderHandler( e ){

        const action                    = e.target.getAttribute( "action" )
        const serviceProviderID         = e.target.getAttribute( "serviceproviderid" )

        if( action === "confirm" ){

            const modalL = this.state.modal

            modalL["title"]          = "SYSTEM CONFIRMATION"
            modalL["message"]        = "Do you really want to delete this Service Provider"
            modalL["btnAction"]      = "Yes, delete Service Provider now"
            modalL["actionMethod"]   = this.DeleteServiceProviderHandler
            modalL["btnCancel"]      = "Cancel"
            modalL["data"]           = {    serviceproviderid   : serviceProviderID,
                                            action              : "confirmedDelete" }

            this.setState( { modal : modalL })

            //Make the modal visible
            document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"

            return
        }

        if( action === "confirmedDelete" ){

            const inputData =   {
                                    class: "serviceproviders",
                                    method: "removeProvider",
                                    data: { serviceProviderID : serviceProviderID }
            }
                try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                            if( getDataResponse.result === "success" ){
                                this.SearchProvidersHandler();
                            }
                    } catch( err ){ console.log( err ) }
        }
    }

    SetProfileStatus( status ){

        console.log( "Setting profile status " + status )

        this.setState( {    profileStatus : status,
                            profileStatusColor : ( status === "pending" ) ? "danger" : "success" },
                                ()=> console.log( "Profile status : " + this.state.profileStatus + " : " + this.state.profileStatusColor) );
    }

    LoadDashHandler(){
        this.setState( {    sidebar : "SidebarHome",
                            box     : "BoxDash"  } )
    }

    CreateNewServiceProvider(){
        console.log( "Create new SP" )
        this.setState( {    sidebar : "SidebarBlank",
                            box     : "EditServiceProvider",
                            editServiceProviderID   : ''  } )
    }

    //Update the search term
    UpdateSearchTerm( e ){
        const value = e.target.value
        this.setState( { searchTerm : value } )
    }

    UpdateFormField( event ){
        event.preventDefault()
        const name  = event.target.name
        const value = event.target.value

        //console.log( "Update : " + name + " : " + value );

        //shallow copy
        let data = this.state.learnerData
        //Assign new value to specific property of deeply nested value
        data[name] = value;
        //update state
        this.setState( { data } )
    }

    ConfirmMakeServiceProviderActive(){
        const modal = this.state.modal

        modal.title         = 'System Confirmation'
        modal.message       = 'Do you wish to make this Service Provider active?'
        modal.btnAction     = 'Yes, make active'
        modal.actionMethod  = this.makeProfileActive
        modal.btnCancel     = 'Cancel'

        this.setState( { modal: modal }, ()=>{
            document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
        })
    }

    //Get the number of active learners ( STATS )
    async makeProfileActive(){

        this.setState( { loading : true } );

        const inputData =   {   class: "serviceproviders",
                                method: "updateSPStatus",
                                data: { serviceProviderID: this.state.editServiceProviderID } }

            //console.log( 'ServiceProvider' + JSON.stringify( inputData ) );

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                if( getDataResponse.result === "success" ){
                    const modal = this.state.modal

                    modal.title         = 'System Confirmation'
                    modal.message       = 'The Service Prodiver profile has been activated'
                    modal.btnAction     = 'OK'
                    modal.actionMethod  = this.RefreshProfileAfterMakeActive()
                    modal.btnCancel     = ''

                    this.setState( { modal: modal }, ()=>{
                        document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
                    })
                }

            } catch( err ){
                console.log( err );
            }
    }

    RefreshProfileAfterMakeActive(){
            this.setState( {    profileStatus         : 'active',
                                profileStatusColor    : 'success' } )
    }

    render(){

        var componentList = {
            "SidebarHome"           : SidebarHome,
            "SidebarBlank"          : SidebarBlank,
            "SidebarSP"             : SidebarSP,

            "BoxDash"               : BoxDash,
            "EditServiceProvider"   : EditServiceProvider,
            "BoxSPDocuments"        : BoxSPDocuments,
            "BoxTrainingHistory"    : BoxTrainingHistory
        };

        //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
        const componentSidebar  = this.state.sidebar;
        const componentBox      = this.state.box;

        //RETURN A HANDLE TO THE COMPONENT
        const DynSidebar    = componentList[  componentSidebar ];
        const DynBox        = componentList[  componentBox ];

        return(
            <div className={ 'section' } >
                <div className={ 'row' }>

                { /* SIDEBAR */ }       <DynSidebar     CreateNewServiceProvider    = { this.CreateNewServiceProvider }
                                                        UpdateSearchTerm            = { this.UpdateSearchTerm }
                                                        SearchProvidersHandler      = { this.SearchProvidersHandler }
                                                        fHasAccess                  = { this.props.fHasAccess }
                                                        LoadDocumentsHandler        = { this.LoadDocumentsHandler }
                                                        LoadDashHandler             = { this.LoadDashHandler }
                                                        ViewEditServiceProviderHandler = { this.ViewEditServiceProviderHandler }
                                                        ConfirmMakeServiceProviderActive = { this.ConfirmMakeServiceProviderActive }
                                                        makeProfileActive           = { this.makeProfileActive }
                                                        ViewTrainingHistoryHandler  = { this.ViewTrainingHistoryHandler }

                                                        searchTerm                  = { this.state.searchTerm }
                                                        editServiceProviderID       = { this.editServiceProviderID }
                                                        profileStatusColor          = { this.state.profileStatusColor }
                                                        profileStatus               = { this.state.profileStatus }  />

                { /* BOX CONTENT */ }   <DynBox         getData                     = { this.props.getData }
                                                        UpdateServiceProvider       = { this.UpdateServiceProvider }
                                                        LoadDashHandler             = { this.LoadDashHandler }
                                                        DeleteServiceProviderHandler= { this.DeleteServiceProviderHandler }
                                                        fHasAccess                  = { this.props.fHasAccess }
                                                        SetProfileStatus            = { this.SetProfileStatus }


                                                        serviceProvidersCount       = { this.state.serviceProvidersCount }
                                                        editServiceProviderID       = { this.state.editServiceProviderID }
                                                        searchResultsHtml           = { this.state.searchResultsHtml } />

                </div>

                <HtmlModal modal = { this.state.modal } />
            </div>
        );
    }


}

export default ServiceProviders;
import React, { Component } from 'react'

import SystemLinkRightRow from './SystemLinkRightRow'

class LinkRights extends Component{

    constructor( props ){
        super( props )
        this.state = {
                        displayList : null,
                        feedback    : ''
        }
    }



    //***  GET SYSTEM ROLES FOR DISPLAY  ***/
    //this has a custom display output
    async getSystemRights(){

        this.setState( { loading : true } )

        const inputData =   {
                                class: "access",
                                method: "getRightsForRole",
                                data : { roleID : this.props.parameters.roleID }
        }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        //console.log( "Async complete" )
                        return response  } )
                //console.log( "LC" + getDataResponse.result )
                this.setState( { feedback : getDataResponse.result } );

                if( getDataResponse.result === "success" ){
                    //console.log( JSON.stringify( getDataResponse.data ) )
                    this.setState( {    loading : false,
                                        displayList : getDataResponse.data.map( ( row ) =>
                                            <SystemLinkRightRow updateLinkSystemRight   =   { this.updateLinkSystemRight }
                                                                roleid                  =   { this.props.parameters.roleID }
                                                                data                    =   { row }
                                                                key                     =   { row.id }
                                                                getData                 =   { this.props.getData } />
                                        ) } )
                } else{
                    this.setState( {    loading : false,
                        displayList : <tr><td className={ 'text-white' }>No system rights found</td></tr>
                      } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    componentDidMount(){
        this.getSystemRights();
        this.props.ResetParametersState();
    }

    render(){
        return(
            <div className={ 'row' }>

                <div className={ 'col-12 text-white' }>

                    <h4>
                        { this.props.parameters.roleName }
                        &nbsp;
                        &nbsp;
                        <img    src         =   { require( '../../icon/icon_back.png' ) }
                                className   =   { 'tblIcon' }
                                alt         =   'back'
                                onClick     =   { this.props.showRolesListHandler }
                                title       =   'Back to roles selection' />
                    </h4>
                    <div className={ 'alert alert-warning p-1' }>Auto saves on checkbox change</div>
                    <table>
                        <tbody>
                            { this.state.displayList }
                        </tbody>
                    </table>
                </div>

            </div>
        )
    }

}

export default LinkRights
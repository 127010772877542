import React, { Component } from 'react'

import Loading from '../Helpers/Loading'

class TrainingHistory extends Component{

    constructor( props ){
        super( props )
        this.state = {

                loadingHistory  : true,

                historyData     : [],

                modal: {
                    title       : '',
                    message     : '',
                    btnAction   : '',
                    actionMethod: null,
                    btnCancel   : '',
                    UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()
                }

        }

        this.fetchTrainingHistory       = this.fetchTrainingHistory.bind( this )
    }

    componentDidMount(){
        this.fetchTrainingHistory();
    }

    async fetchTrainingHistory(){
        const inputData =   {
            class: "events",
            method: "fetchEventsHistoryForLearner",
            data: { learnerID       : localStorage.userID,
                    sessionToken    : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse ){

                    let temp = [];

                    if( getDataResponse.result === "success" ){

                        getDataResponse.data.map( ( event )=>{

                                switch( event.courseStatus ){
                                    case( "competent" ):
                                        event.color = "warning"
                                        break

                                    case( "not yet competent" ):
                                        event.color = "danger"
                                        break

                                    case( "complete" ):
                                        event.color = "success"
                                        break

                                    default:
                                        event.color = "danger"
                                }


                                temp.push( event )
                        })

                    }

                    this.setState({ historyData : temp, loadingHistory : false }, ()=>{
                        console.log( "ArrHistory " +  this.state.historyData.length )
                    } )
                }

            } catch( err ){ console.log( err ) }
    }

    render(){


        return(
                <div    className={ 'container-fluid idzDark rounded p-1' }
                        style={{ height: "80vh" }} >

                    <div className={'row'}>

                        <div className={'col-12'}>

                            {
                                ( this.state.loadingHistory )
                                ?   <Loading />
                                :   this.state.historyData.map( (event, id )=>(
                                        <div key={ "key" + id } className={'container-fluid text-white'}>
                                            <div className={'row'}>
                                                <div className={'col-3 p-1'}>
                                                    <div className={'p-1 border rounded border-' + event.color }>
                                                        { event.startDate }
                                                    </div>
                                                </div>

                                                <div className={'col-6 p-1'}>
                                                    <div className={'p-1 border rounded border-' + event.color }>
                                                        { event.eventName }
                                                    </div>
                                                </div>

                                                <div className={'col-3 p-1'}>
                                                    <div className={'p-1 border rounded border-' + event.color }>
                                                        { event.courseStatus }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))
                            }

                        </div>

                    </div>

                </div>
        );
    }
}

export default TrainingHistory
import React from 'react'

import StatsBlock from '../Helpers/StatsBlock'

const Dashboard = ( props ) =>{

    /*
            eventsCount
            coursesCount
            learnersCount
            completedCount

    */

    return(
                <div className={'container-fluid text-white'}>

                    <div className= { 'row' }  style={{ padding: "0px"}} >
                        <div className={ 'col-sm-6' }>
                            <StatsBlock     heading='Training Programs'
                                            statistic={ props.stats.coursesCount }
                                            color='primary' />
                        </div>

                        <div className={ 'col-sm-6' }>
                            <StatsBlock     heading='Interventions count'
                                            statistic={ props.stats.eventsCount }
                                            color='info' />
                        </div>

                    </div>

                    <div className= { 'row mt-3' }  style={{ padding: "0px"}} >
                        <div className={ 'col-sm-6' }>
                            <StatsBlock     heading='Complete Interventions'
                                            statistic={ props.stats.completedCount }
                                            color='success' />
                        </div>

                        <div className={ 'col-sm-6' }>
                            <StatsBlock     heading='Incomplete Interventions'
                                            statistic={ parseInt( props.stats.allottedLearners ) - parseInt( props.stats.completedCount ) }
                                            color='warning' />
                        </div>

                    </div>

                </div>
    )
}

export default Dashboard
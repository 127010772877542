import React from 'react'


const RowLearner = ( props ) => {


    return(
        <tr>
                <td>
                    <button     onClick     =   { props.ClickToEditLearnerHandler }
                                learnerid   =   { props.data.id }
                                className   =   { 'btn btn-dark border-warning btn-sm' }>VIEW</button>
                </td>
                <td>
                    { props.data.id }
                </td>
                <td>
                    <div>{ props.data.firstname + ' ' + props.data.lastname }</div>
                    <div className={ 'text-danger smaller' } >{ props.data.email }</div>
                </td>
                <td>
                        <img    className   =   { 'border-danger' }
                                src         =   { require( "../../icon/icon_delete.png" ) }
                                learnerid   =   { props.data.id }
                                style       =   {{  borderRadius: '0.3em',
                                                    border: "1px solid white",
                                                    padding: "5px",
                                                    height: "40px",
                                                    cursor: "pointer" }}
                                alt         =   'Delete learner'
                                title       =   "Delete this learner profile"
                                onClick     =   { props.deleteLearnerProfile } />
                </td>
        </tr>
    )
}

export default RowLearner
import React from 'react'

import Loading from '../Helpers/Loading'
//import Auxiliary from '../HOC/Auxiliary';

const Search = ( props ) =>{

    //console.log( "Training " + JSON.stringify( props.searchResults ) )

    return(
        <div className={ null } >
            <div className={ 'input-group mb-3 mt-3' } style={{ width: '100%' }}>

                <input  type        =   'text'
                        id          =   'searchTerm'
                        name        =   'searchTerm'
                        value       =   { props.searchTerm }
                        onChange    =   { props.searchTermHandler }
                        className   =   { 'form-control' }
                        placeholder =   'search text' />

                <div    className       =   { 'input-group-append' }
                        style           =   {{ zIndex: "0" }}>
                    <button     className   =   { 'btn btn-dark border-warning' }
                                type        =   'button'
                                onClick     =   { props.doSearchHandler }>
                        Search
                    </button>
                </div>

            </div>

            <div className={ 'container' } >

                { ( props.loadingSearch )
                        ? <div className={ 'text-center' }><Loading /></div>
                        : "" }

                {   ( props.searchResults.length > 0 )

                     ?  <div    className={ 'card idzDark' }
                                style={{    overflowY: "auto",
                                            overflowX: "hidden",
                                            maxHeight: "300px" }}>
                            <div    className   =   { 'row border-warning mb-2' }
                                    style       =   {{ borderTop: "1px solid white", borderBottom: "1px solid white" }} >

                                <div className={ 'col-sm-6 text-white tblFontSizeSmall' } >
                                    Name
                                </div>

                                <div    className   ={ 'col-sm-6 text-white tblFontSizeSmall text-center' } >
                                    Add Recipient
                                </div>

                            </div>
                            { props.searchResults }
                        </div>

                    :   <div    className   =   { 'row mb-2 border-warning' }
                                style       =   {{ borderTop: "1px solid white", borderBottom: "1px solid white" }} >

                            <div className={ 'col-sm-12 text-warning text-center' } >
                                <i>...No results to display...</i>
                            </div>

                        </div>
                }
            </div>

        </div>
    )
}

export default Search
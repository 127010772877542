import React from 'react'

import StatsBlock from '../Helpers/StatsBlock'

const Dashboard = ( props ) =>{


    return(
            <div className={'container-fluid text-white'}>

                <div className= { 'row' }  style={{ padding: "0px"}} >
                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Messages' statistic={ props.stats.messages } color='primary' />
                    </div>

                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Enrolled Interventions' statistic={ props.stats.enrolments }  color='primary' />
                    </div>

                </div>

            </div>
    )
}

export default Dashboard
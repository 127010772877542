import React, { Component } from 'react'

class TrainingHistory extends Component{

    constructor( props ){
        super( props )
        this.state = {

                fetchingHistory : true,
                arrHistory      : []

        }

        this.fetchTrainingHistory           = this.fetchTrainingHistory.bind( this )
    }

    componentDidMount(){

        this.fetchTrainingHistory();
    }


    async fetchTrainingHistory(){
        const inputData =   {
            class: "events",
            method: "fetchEventsHistoryForServiceProvider",
            data: { entityID        : this.props.spData.id,
                    sessionToken    : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse ){

                    let temp = [];

                    if( getDataResponse.result === "success" ){

                        // eventID, eventName, startDate, dates, studentCount, competent, notCompetent, complete

                        getDataResponse.data.map( ( event )=>{
                                temp.push( event )
                        })

                    }

                    this.setState({ arrHistory : temp }, ()=>{ console.log( "ArrHistory " +  this.state.arrHistory.length ) } )
                }

            } catch( err ){ console.log( err ) }
    }

    render(){


        return(
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={ 'col-sm-12' } >

                            { this.state.historyHtml }

                            {
                                ( this.state.arrHistory.length === 0 )
                                ? <h3 className={'text-white'}>No training history found</h3>
                                : this.state.arrHistory.map( (event)=>(
                                    <div    key         =   { "key" + event.id }
                                            className   =   {'container-fluid border border-info rounded p-2 m-1'}>
                                        <div className={'row text-white'}>

                                            <div className={'col-6 rounded'} >
                                                <h4 className={'text-info'}>{ event.eventName }</h4>

                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Event start date</td>
                                                            <td className={'text-right'}>{ event.startDate }</td>
                                                        </tr>
                                                        <tr style={{ borderTop: '1px solid red' }}>
                                                            <td style={{ verticalAlign: 'top'}}>Event dates</td>
                                                            <td style={{ fontSize: '11px'}} className={'text-right'}>
                                                                {
                                                                    event.dates.split( "," ).map( (date)=>(
                                                                        <div key={"key"+date}>{ date }</div>
                                                                    ))
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className={'col-6'}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Learner count</td>
                                                            <td>{ event.studentCount }</td>
                                                        </tr>
                                                        <tr className={'text-warning'}>
                                                            <td>Not competent</td>
                                                            <td>{ event.notCompetent }</td>
                                                        </tr>
                                                        <tr className={'text-info'}>
                                                            <td>Completed</td>
                                                            <td>{ event.complete }</td>
                                                        </tr>
                                                        <tr className={'text-success'}>
                                                            <td>Competent</td>
                                                            <td>{ event.competent }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                ))
                            }




                        </div>
                    </div>
                </div>

        );
    }
}

export default TrainingHistory
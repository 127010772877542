import React from 'react';

//import PanelDisplayUserList from './PanelDisplayUserList';
import StatsBlock from '../Helpers/StatsBlock'

const BoxDash = ( props ) =>{

    return(
        <div className={ 'col-md-9 learnerContent' }>
                <div className= { 'row' }>
                    <div className={ 'col-sm-6 text-center p-1' }>
                        <StatsBlock     heading     =   'Users Count'
                                        statistic   =   { props.usersCount }
                                        color       =   'primary' />
                    </div>

                    <div className={ 'col-sm-6 text-center p-1' }>
                        <StatsBlock     heading     =   'Placeholder'
                                        statistic   =   '...loading...'
                                        color       =   'primary' />
                    </div>
                </div>

                <div className={ "row idzDark p-2" }
                        style={{ borderRadius: "0.3em" }}>

                    <div    className   =   {"container"}
                            style       =   {{  maxHeight:  "70vh",
                                                overflowY:  "auto",
                                                overflowX:  "hidden" }}>

                        { props.displaySearchResults }

                    </div>
                </div>
        </div>
    );

}

export default BoxDash;
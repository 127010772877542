import React from 'react';

const SidebarEvent = ( props ) =>{

    //console.log( "SidebarViewLearner : props : " + JSON.stringify( props ) )

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>
                <div className={ "text-center" }>
                    {/*<div className={'text-white'}>{ props.editLearnerID }</div>*/}
                    <h4 style={{ fontSize: '2vw' }}>Intervention</h4>

                    {/* <div className={ 'badge badge-success mb-2' }>{ props.profileStatus }</div> */}

                </div>
                <div className={ 'text-center' }>
                <img src={ require('../../icon/icon_man_tie.png') }  alt='Test' />
                </div>
                <br />
                <div className={ null }>
                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadEditEventHandler }>Information</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.EventDocumentsHandler } >Documents</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadLearnersHandler }>Learners</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadMilestonesHandler }>Milestones</button>
                </div>
            </div>
        </div>
    );

}

export default SidebarEvent;
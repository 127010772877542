import React from 'react'

const RowSearchResult = ( props ) =>{

    return(
            <div>
                <div className={'row tblFontSize pt-1 pb-1'} style={{ borderBottom: "1px solid rgba( 247,190,70,0.6 )" }} >
                    <div className={ 'col-sm-7' } >
                        { props.data.firstname + " " + props.data.lastname }<br />
                        <span className={ 'text-warning' }>{ props.data.email }</span><br />
                        <span className={ 'text-warning tblFontSizeSmall' } >TYPE: { props.data.type }</span>
                    </div>
                    <div className={ 'col-sm-3 pr-3 text-right' } >
                        <button className       =   { 'btn btn-dark btn-sm border-warning text-warning' }
                                style           =   {{ marginTop: '5px' }}
                                action          =   'add'
                                profilename     =   { props.data.firstname + " " + props.data.lastname }
                                profileid       =   { props.data.id }
                                email           =   { props.data.email }
                                type            =   { props.data.type }
                                onClick         =   { props.recipientHandler } >

                            <b style={{ pointerEvents: "none" }}>&#43;</b>
                        </button>
                    </div>
                    <div className={ 'col-sm-1' }></div>
                </div>
            </div>
    )
}

export default RowSearchResult
import React from 'react'

//import RowRecipient from './RowRecipient'
//import Auxiliary from '../HOC/Auxiliary'

const ListRecipients = ( props ) =>{

    //console.log( "block recipients " + JSON.stringify( props ) )

    return(
        <div className={ 'container' } >


                {
                ( props.listRecipients !== '' )

                    ?   <div    className={ 'card idzDark' }
                                style={{    overflowY: "auto",
                                            overflowX: "hidden",
                                            maxHeight: "240px" }}>
                            <div className={ 'container' } >
                                <div    className   =   { 'row border-success pt-1 pb-1' }
                                        style       =   {{ borderTop: "1px solid white", borderBottom: "1px solid white" }} >
                                    <div className={ 'col-sm-8 text-white' } >
                                        Recipients
                                        &nbsp;
                                        <div    className= { 'badge badge-success' } >
                                            { ( props.listRecipients !== undefined ) ? props.listRecipients.length : 0 }
                                        </div>
                                    </div>

                                    <div className={ 'col-sm-4 text-white text-right' } >
                                        Remove
                                    </div>
                                </div>
                                { props.recipientsHTML }
                            </div>
                        </div>
                    :   <div    className   =   { 'row mb-2 border-success' }
                                style       =   {{ borderTop: "1px solid white", borderBottom: "1px solid white" }} >
                            <div className={ 'col-sm-12 text-success' } >
                                <i>...No recipients to display...</i>
                            </div>
                        </div>
                }

        </div>
    )
}

export default ListRecipients
import React from 'react'

const StatsBlock = ( props ) =>{

    let image = ''
    let dynWidth = 'col-12'

    if( props.image ){
        //console.log( "Found image" )
        image = <div className={ 'col-4' }>
                    <img    src         =   { require( '../../icon/' + props.image ) }
                            className   =   { 'img-fluid float-left homepageIcon p-2' }
                            alt         =   'icon' />
                </div>
        dynWidth = 'col-8'
    } else{
        //console.log( "No image found" )
    }

    return(
        <div className={ 'card idzDark' }>
            <div className={ 'row' }>

                { image }

                <div className={ dynWidth + ' text-center' }>
                    <span className =   { 'text-white homepageFontLarge' }>{ props.heading }</span><br />
                    <span className =   { 'text-' + props.color + ' homepageFontCount' } >{ props.statistic }</span>
                </div>
            </div>


        </div>
    )
}

export default StatsBlock
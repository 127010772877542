import React from 'react';


const SidebarViewLearner = ( props ) =>{

    //console.log( "SidebarViewLearner : props : " + JSON.stringify( props ) )

    const profileStatusColor = ( props.profileStatus === "pending" ) ? "danger" : "success"

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>
                <div className={ "text-center" }>
                    {/*<div className={'text-white'}>{ props.editLearnerID }</div>*/}
                    <h4 style={{ fontSize: '2vw' }}>Learner</h4>

                    <button className   =   { 'btn btn-' + profileStatusColor + ' btn-sm mb-2' }
                            onClick     =   { props.ConfirmMakeLearnerActive }
                            disabled    =   { ( props.profileStatus === 'active' ) || !props.fHasAccess( 27 ) } >
                        { props.profileStatus }
                    </button>

                </div>
                <div className={ 'text-center' }>
                <img src={ require('../../icon/icon_man_tie.png') }  alt='Test' />
                </div>
                <br />
                <div className={ null }>
                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LearnerPersonalDataEditHandler }>Information</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadEmployerDetails } >Employer</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadViewLearnerDocuments }>Documents</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadTrainingHistory } >Training History</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.ViewMessagesHandler } >
                            Messaging
                    </button>
                </div>
            </div>
        </div>
    );

}

export default SidebarViewLearner;
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Auxiliary from '../HOC/Auxiliary';

import HtmlModal from '../Helpers/HtmlModal';

import Dashboard from './Dashboard'
import Information from './Information'
import Communication from './Communication'
import Documents from './Documents'
import TrainingHistory from './TrainingHistory'
import ManageStudents from './ManageStudents'
//import { delay } from 'q';

/*
    This is the main component for the Service Provider website.

    The Service Provider is stored in entityID localStorage.



*/

class ServiceProviderMain extends Component{

    constructor( props ){
        super( props )
        this.state = {

                box : "Dashboard",

                fetchingSPData  : true,

                serviceProviderData             : '',

                stats: {
                            eventsCount         : 0,
                            coursesCount        : 0,
                            learnersCount       : 0,
                            completedCount      : 0,
                            allottedLearners    : 0
                },

                modal: {
                    title       : '',
                    message     : '',
                    btnAction   : '',
                    actionMethod: null,
                    btnCancel   : '',
                    UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()
                }

        }

        this.DashboardHandler       = this.DashboardHandler.bind( this )
        this.CommunicationHandler   = this.CommunicationHandler.bind( this )
        this.DocumentsHandler       = this.DocumentsHandler.bind( this )
        this.TrainingHistoryHandler = this.TrainingHistoryHandler.bind( this )
        this.InformationHandler     = this.InformationHandler.bind( this )
        this.ManageStudentsHandler  = this.ManageStudentsHandler.bind( this )
        this.fetchSPData            = this.fetchSPData.bind( this )

        this.waitForElement         = this.waitForElement.bind( this )
    }

    componentDidMount(){
        //Double check if the localstorage value is valid for this user
        //console.log( "Service Providers mounted " + localStorage.entityID );
        //this.ConfirmEntityID();
        //delay( 50 )
        this.waitForElement();
    }

    waitForElement(){
        if(typeof localStorage.entityID !== "undefined"){
            //variable exists, do what you want
            this.getUniqueSPStats();
            this.fetchSPData();
        }
        else{
            setTimeout( this.waitForElement, 250);
        }
    }

    //***  STATS BLOCKS  ***//
    async getUniqueSPStats(){


        alert( localStorage.entityID );

        const inputData =   {
            class: "serviceproviders",
            method: "fetchUniqueSPStats",
            data: { entityID   : localStorage.entityID }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( "Stats : " + JSON.stringify( getDataResponse ) )

                const data = getDataResponse.data
                const temp = this.state.stats

                temp.eventsCount         = data.events
                temp.coursesCount        = data.courses
                temp.learnersCount       = data.allottedLearners
                temp.completedCount      = data.completed
                temp.allottedLearners    = data.allottedLearners

                this.setState( { stats : temp } )

            } catch( err ){ console.log( err ) }
    }

    async ConfirmEntityID(){
        const inputData =   {
            class: "serviceproviders",
            method: "confirmEntityID",
            data: { entityID   : localStorage.entityID,
                    sessionToken : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                //console.log( getDataResponse )

                if( !getDataResponse || getDataResponse.result !== "success" ){
                    //console.log( "User autehntication failed for the relevant service provider" );

                    this.props.setLogoutHandler();

                }
            } catch( err ){ console.log( err ) }
    }

    async fetchSPData(){
        const inputData =   {
            class: "serviceproviders",
            method: "fetchSPData",
            data: { editServiceProviderID   : localStorage.entityID,
                    sessionToken            : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse ){

                    let spData  = this.state.serviceProviderData
                        spData  = getDataResponse.data

                    this.setState( {    serviceProviderData : spData,
                                        fetchingSPData      : false } );
                }
            } catch( err ){ console.log( err ) }
    }

    //***   NAVIGATION  ***/
    DashboardHandler(){
        this.setState( { box: "Dashboard" } )
    }

    InformationHandler(){
        this.setState( { box: "Information" } )
    }

    DocumentsHandler(){
        this.setState( { box: "Documents" } )
    }

    TrainingHistoryHandler(){
        this.setState( { box: "TrainingHistory" } )
    }

    CommunicationHandler(){
        this.setState( { box: "Communication" } )
    }

    ManageStudentsHandler(){
        this.setState( { box: "ManageStudents" } )
    }
    //*********************/

    modalLogoutHandler = () =>{
        //Setup all the values for the modal
        const data = {  title       : "Do you really want to log out?",
                        message     : "",
                        btnAction   : "Yes, LOG OUT",
                        actionMethod: this.props.setLogoutHandler,
                        btnCancel   : "Cancel",
                        UUID        : this.state.modal.UUID,
                        data : null //this stuff will be spread and dynamically added to the action button as key->value pairs
                     }

        //Set the state so that the modal can update
        this.setState( { modal : data } );

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    render(){

        const componentsList = {
                Dashboard       : Dashboard,
                Communication   : Communication,
                Information     : Information,
                Documents       : Documents,
                TrainingHistory : TrainingHistory,
                ManageStudents  : ManageStudents
        }

        const DynamicComponent = componentsList[ this.state.box ]

        return (
            <Router>
                <Auxiliary>

                <div className={ 'container-fluid' }>
                    <div className={ 'row p-1' }>

                        <div className={ 'col-sm-3' } >
                            {/*{ loadingIcon }*/}
                        </div>

                        <div className={ 'col-sm-9 p-1' }>
                            <img src={ require( '../../icon/icon_logout.png') }
                                        alt         = "Log out button"
                                        className   = { 'logOutBtn float-right' }
                                        onClick     = { this.modalLogoutHandler }
                                        style       = {{ marginTop: '5px' }} />

                            <span className={ 'float-right text-white small p-1' }>
                                    STATUS : { this.props.appStatus } &nbsp; &nbsp;
                            </span>
                        </div>

                        <div className={ 'col-sm-3' }>

                                    <ul className={ "nav nav-pills flex-column idzDark p-2" }>

                                        <div className={'text-center text-white'} style={{ fontSize : "1.4vw" }}>
                                            { this.props.firstname + ' ' + this.props.lastname }
                                        </div>

                                        <li className={ "nav-item" }>
                                            <Link  to='/' className={ "nav-link bg-dark text-white text-center active" }>
                                                {/* <h1 style={{ fontSize : '4vw' }}>I D Z</h1> */}

                                                <img src={ require('../../icon/idz_logo_sml.png') }  alt='Test' />
                                            </Link>
                                        </li>

                                        { /*  IMAGE  */ }
                                        <img src={ require( "../../icon/icon_person.png" ) }
                                                className={ "m-2 mx-auto" } alt="Icon for person" />

                                        <li className={ "nav-item" }>
                                            <button className   =  { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =  { this.DashboardHandler }>
                                                Home
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.InformationHandler }
                                                    disabled    =   { this.state.fetchingSPData } >
                                                Information
                                            </button>
                                        </li>
{/*
                                        <li className={ "nav-item" }>
                                            <button className   =   { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.DocumentsHandler } >
                                                Documents
                                            </button>
                                        </li>
*/}
                                        <li className={ "nav-item" }>
                                            <button className   =   { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.TrainingHistoryHandler }>
                                                Training History
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.ManageStudentsHandler }>
                                                Manage Students
                                            </button>
                                        </li>
{/*
                                        <li className={ "nav-item" }>
                                            <button className   =   { 'nav-link btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.CommunicationHandler } >
                                                Communication
                                            </button>
                                        </li>
*/}
                                    </ul>
                        </div>

                        <div className={ 'col-sm-9' }>
                            <DynamicComponent   getData     = { this.props.getData }
                                                fHasAccess  = { this.props.fHasAccess }

                                                learnerID   = { this.props.userID }
                                                stats       = { this.state.stats }
                                                spData      = { this.state.serviceProviderData } />
                        </div>

                    </div>

                </div>
            <HtmlModal modal={ this.state.modal } />

            </Auxiliary>
            </Router>
        );

    }

}

export default ServiceProviderMain;
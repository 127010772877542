import React, { Component } from 'react'
import CourseProgressBlock from '../Helpers/CourseProgressBlock'

class TrainingHistory extends Component{

    constructor( props ){
        super( props )

        this.state = {
                learnerID   : this.props.editLearnerID,
                eventsCount : 0,
                eventsList  : '',
                feedback    : ''
        }
    }

    componentDidMount(){
        this.getLearnerEvents();
    }

    //Get the number of active learners ( STATS )
    async getLearnerEvents(){

        this.setState( { loading : true } );

        const inputData =   {   class: "learners",
                                method: "getLearnerEvents",
                                data: { learnerID : this.state.learnerID } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response ){
                        return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( {    eventsCount     :   getDataResponse.count,
                                        eventsList      :   getDataResponse.data.map( (event) =>
                                                                <CourseProgressBlock key={event.enrolmentID} row={event} />
                                                             )
                                    } )
                } else{
                    this.setState( { feedback : <div className={'text-white'}>{getDataResponse.result}</div> } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    render(){


        return(
                <div className={ 'col-sm-9' } >
                    <div className={'container-fluid'}>

                        { ( this.state.feedback.length !== 0 ) ? this.state.feedback : "" }
                        { ( this.state.eventsList.length !== 0 ) ? this.state.eventsList : "" }

                    </div>
                </div>
        );
    }
}

export default TrainingHistory
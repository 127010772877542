import React, { Component } from 'react';

import HtmlModal from '../Helpers/HtmlModal'

//***  SIDEBAR COMPONENTS  ***//
import SidebarHome from './SidebarHome'
import SidebarBlank from './SidebarBlank'
import SidebarCourse from './SidebarCourse'

//***  CONTENT COMPONENTS  ***//
import BoxDash from './BoxDash'
import BoxEditCourse from './BoxEditCourse'
import RowSearchResult from './RowSearchResult'
import BoxCourseDocuments from './BoxCourseDocuments'
//import { timingSafeEqual } from 'crypto';

class ServiceProviders extends Component {

    constructor( props ){
        super( props )
        this.state = {

            //stats
            loading         : false,
            deletingCourse  : false,

            coursesCount    :   0,
            activeCourses   :   0,

            sidebar   : props.sidebar,
            box       : props.box,

            searchTerm          : '',
            listsearch          : '',
            searchResultsHtml   : '',

            editCourseID        : '',
            deleteCourseID      : '',
            trainingProgramStatus           : "Pending",
            trainingProgramStatusColor      : "danger",

            listCourseCategories: [],

            modal:  {   title       :      '',
                                message     :    '',
                                btnAction   :  '',
                                btnCancel   :   '',
                                UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                actionMethod:   null,
                                data        :   null }
        }
        this.LoadDashHandler        = this.LoadDashHandler.bind( this )
        this.UpdateEditCourseID     = this.UpdateEditCourseID.bind( this )
        this.UpdateCourseHandler    = this.UpdateCourseHandler.bind( this )
        this.UpdateSearchTerm       = this.UpdateSearchTerm.bind( this )
        this.SearchCoursesHandler   = this.SearchCoursesHandler.bind( this )
        this.DeleteCourseHandler    = this.DeleteCourseHandler.bind( this )
        this.SelectCourseHandler    = this.SelectCourseHandler.bind( this )
        this.ViewCourseDocumentsHandler = this.ViewCourseDocumentsHandler.bind( this )
        this.ConfirmMakeTrainingProgramActive = this.ConfirmMakeTrainingProgramActive.bind( this )
        this.fUpdateTraingProgramStatusAfterSelectingIt = this.fUpdateTraingProgramStatusAfterSelectingIt.bind( this )
        this.maketrainingProgramActive = this.maketrainingProgramActive.bind( this )
        this.fActuallyDeleteTrainingProgram = this.fActuallyDeleteTrainingProgram.bind( this )
    }

    componentDidMount(){
        this.FetchCoursesStats()
        this.FetchCourseCategoriesHandler()     //Get the available categories
        this.FetchServiceProviders()            //Get the available service providers
    }

    ViewCourseDocumentsHandler(){
        this.setState( {    sidebar : "SidebarCourse",
                            box     : "BoxCourseDocuments" } )
    }

    UpdateEditCourseID( courseID ){
        this.setState( { editCourseID : courseID }, ()=>{ this.UpdateCourseHandler() } )
    }

    UpdateCourseHandler(){
        // **** If not course ID then set a blank sidebar
        if( this.state.editCourseID === '' ){
            this.setState( {    sidebar         :   "SidebarBlank",
                                box             :   "BoxEditCourse" } )
        } else{
            this.setState( {    sidebar         :   "SidebarCourse",
                                box             :   "BoxEditCourse" } )
        }
    }

    //Update the search term
    UpdateSearchTerm( e ){
        const value = e.target.value
        this.setState( { searchTerm : value } )
    }

    SelectCourseHandler( e ){
        const courseID = e.target.getAttribute( "courseid" )
        //console.log( "Loading COURSE ID : " + courseID )
        this.setState( {    sidebar         :   "SidebarHome",
                            box             :   "BoxDash",
                            editCourseID    :   courseID }, ()=>{ this.UpdateCourseHandler() } )
    }

    LoadDashHandler(){
        this.setState( {    sidebar         :   "SidebarHome",
                            box             :   "BoxDash",
                            editCourseID    :   '' } )
    }

    DeleteCourseHandler( e ){
        alert( "Want to delete course with ID : " + e.target.getAttribute( "courseid" ) )

        //const courseID = e.target.getAttribute( "courseid" )
        const action   = e.target.getAttribute( "action" )

        this.setState( { "deleteCourseID" : e.target.getAttribute( "courseid" ) }, () =>{
            this.fConfirmToDeleteCourse();
        })

    }

    fConfirmToDeleteCourse(){
        const modal = this.state.modal

        modal.title         = 'System Confirmation'
        modal.message       = 'Do you wish to remove this Training Program?'
        modal.btnAction     = 'Yes'
        modal.actionMethod  = this.fActuallyDeleteTrainingProgram
        modal.btnCancel     = 'Cancel'

        this.setState( { modal: modal }, ()=>{
            document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
        })
    }

    async fActuallyDeleteTrainingProgram(){
        this.setState( { deletingCourse : true } );

        const inputData =   {   class: "courses",
                                method: "removeCourse",
                                data: { courseID: this.state.deleteCourseID } }

            //console.log( 'Training program send make active to db : ' + JSON.stringify( inputData ) );

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                console.log( "Training program : " + getDataResponse )

                if( getDataResponse.result === "success" ){
                    const modal = this.state.modal

                    modal.title         = 'System Confirmation'
                    modal.message       = 'The Training Course has been removed'
                    modal.btnAction     = 'OK'
                    modal.actionMethod  = null
                    modal.btnCancel     = ''

                    this.setState( {    modal           : modal,
                                        deleteCourseID  : '',
                                        deletingCourse  : false }, ()=>{
                        document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
                        this.SearchCoursesHandler()
                    })
                }

            } catch( err ){
                console.log( err );
            }
    }

    fUpdateTraingProgramStatusAfterSelectingIt( trainingProgramStatus ){
        console.log( "Program status after loading course: " + trainingProgramStatus )
        let curState = this.state

        //alert( 'State program status : ' + this.state.trainingProgramStatus )
        curState.trainingProgramStatus = trainingProgramStatus
        curState.trainingProgramStatusColor = ( ( trainingProgramStatus === 'pending' ) ? 'danger' : 'success' )

        this.setState( { curState } );
    }

    //***  MAKE TRAINING PROGRAM ACTIVE ***/
    ConfirmMakeTrainingProgramActive(){
        const modal = this.state.modal

        modal.title         = 'System Confirmation'
        modal.message       = 'Do you wish to make this Training Program active?'
        modal.btnAction     = 'Yes, make active'
        modal.actionMethod  = this.maketrainingProgramActive
        modal.btnCancel     = 'Cancel'

        this.setState( { modal: modal }, ()=>{
            document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
        })
    }

    //Get the number of active learners ( STATS )
    async maketrainingProgramActive(){

        console.log( "Make it active" );

        this.setState( { loading : true } );

        const inputData =   {   class: "courses",
                                method: "updateTrainingProgramStatus",
                                data: { trainingProgramID: this.state.editCourseID } }

            //console.log( 'Training program send make active to db : ' + JSON.stringify( inputData ) );

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                console.log( "Training program : " + getDataResponse )

                if( getDataResponse.result === "success" ){
                    const modal = this.state.modal

                    modal.title         = 'System Confirmation'
                    modal.message       = 'The Training Course has been activated'
                    modal.btnAction     = 'OK'
                    modal.actionMethod  = this.RefreshProfileAfterMakeActive()
                    modal.btnCancel     = ''

                    this.setState( { modal: modal }, ()=>{
                        document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
                    })
                }

            } catch( err ){
                console.log( err );
            }
    }

    RefreshProfileAfterMakeActive(){
            this.setState( {    trainingProgramStatus         : 'active',
                                trainingProgramStatusColor    : 'success' } )
    }

    //***  Fetch stats  ***//
    async FetchCoursesStats(){

        const inputData =   {   class: "courses",
                                method: "fetchCoursesStats",
                                data: {  } }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                                this.setState( {    coursesCount : getDataResponse.data.coursesCount,
                                                    activeCourses: getDataResponse.data.activeCourses } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATE, EDIT SAVE SERVICE PROVIDER  ***//
    async SearchCoursesHandler(){

        if( this.state.searchTerm.length === 0 ){
            this.setState(  {   listSearch : '',
                                searchResultsHtml : '' } )
            return
        }

        //Clear current searchResults
        this.setState( { listsearch : [], searchResultsHtml : "" } )

        const inputData =   {
                                class: "courses",
                                method: "searchCourses",
                                data: { searchTerm : this.state.searchTerm }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            this.setState( {    listSearch          : getDataResponse.data,
                                                searchResultsHtml   : getDataResponse.data.map( ( row )=>
                                                <RowSearchResult    SelectCourseHandler = { this.SelectCourseHandler }
                                                                    DeleteCourseHandler = { this.DeleteCourseHandler }
                                                                    key             = { row.id }
                                                                    data            = { row } />
                                            ) } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  Course options  ***//
    async FetchCourseCategoriesHandler(){

        //console.log( "Sending to PHP : " + JSON.stringify( this.state.spData ) )

        const inputData =   {
                                class: "courses",
                                method: "fetchCourseCategories",
                                data: {  }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                                this.setState( { listCourseCategories : getDataResponse.data } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  Course options  ***//
    async FetchServiceProviders(){

        //console.log( "Sending to PHP : " + JSON.stringify( this.state.spData ) )

        const inputData =   {
                                class: "courses",
                                method: "fetchServiceProviders",
                                data: {  }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                                this.setState( { listServiceProviders : getDataResponse.data } )
                        }
                } catch( err ){ console.log( err ) }
    }

    render(){

        var componentList = {
            "SidebarHome"       : SidebarHome,
            "SidebarBlank"      : SidebarBlank,
            "SidebarCourse"     : SidebarCourse,

            "BoxDash"           : BoxDash,
            "BoxEditCourse"     : BoxEditCourse,
            "BoxCourseDocuments": BoxCourseDocuments
        };

        //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
        const componentSidebar  = this.state.sidebar;
        const componentBox      = this.state.box;

        //RETURN A HANDLE TO THE COMPONENT
        const DynSidebar    = componentList[ componentSidebar ];
        const DynBox        = componentList[ componentBox ];

        return(
            <div className={ 'section' } >
            <div className={ 'row' }>

            { /* SIDEBAR */ }       <DynSidebar     UpdateSearchHandler     = { this.UpdateSearchHandler }
                                                    UpdateCourseHandler     = { this.UpdateCourseHandler }
                                                    SearchCoursesHandler    = { this.SearchCoursesHandler }
                                                    UpdateSearchTerm        = { this.UpdateSearchTerm }
                                                    fHasAccess              = { this.props.fHasAccess }
                                                    ViewCourseDocumentsHandler = { this.ViewCourseDocumentsHandler }
                                                    LoadDashHandler         = { this.LoadDashHandler }
                                                    ConfirmMakeTrainingProgramActive = { this.ConfirmMakeTrainingProgramActive }

                                                    editCourseID            = { this.state.editCourseID }
                                                    searchTerm              = { this.state.searchTerm }
                                                    trainingProgramStatus   = { this.state.trainingProgramStatus }
                                                    trainingProgramStatusColor = { this.state.trainingProgramStatusColor } />

            { /* BOX CONTENT */ }   <DynBox         getData                 = { this.props.getData }
                                                    LoadDashHandler         = { this.LoadDashHandler }
                                                    UpdateEditCourseID      = { this.UpdateEditCourseID }
                                                    SelectCourseHandler     = { this.SelectCourseHandler }
                                                    fHasAccess              = { this.props.fHasAccess }
                                                    DeleteCourseHandler     = { this.DeleteCourseHandler }
                                                    ConfirmMakeTrainingProgramActive = { this.ConfirmMakeTrainingProgramActive }
                                                    fUpdateTraingProgramStatusAfterSelectingIt = { this.fUpdateTraingProgramStatusAfterSelectingIt }
                                                    SearchCoursesHandler    = { this.SearchCoursesHandler }

                                                    editCourseID            = { this.state.editCourseID }
                                                    listCourseCategories    = { this.state.listCourseCategories }
                                                    listServiceProviders    = { this.state.listServiceProviders }
                                                    searchResultsHtml       = { this.state.searchResultsHtml }

                                                    coursesCount            = { this.state.coursesCount }
                                                    activeCourses           = { this.state.activeCourses }
                                                    deletingCourse          = { this.state.deletingCourse } />

            </div>

            <HtmlModal modal = { this.state.modal } />
        </div>
        );
    }


}

export default ServiceProviders;
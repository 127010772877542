import React, { Component } from 'react';

import HtmlModal from '../Helpers/HtmlModal'
import Loading from '../Helpers/Loading'

//import SystemRoleRow from './SystemRoleRow';

class EditSystemRole extends Component{

    constructor( props ){
        super( props )
        this.state = {  modal: {
                        title           : '',
                        message         : '',
                        btnAction       : '',
                        actionMethod    : null,
                        btnCancel       : '',
                        UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                        data            : {} }
                    };
        this.ActionSaveSystemRoleHandler = this.ActionSaveSystemRoleHandler.bind( this )
    }

    fUpdateSystemRole = () =>{
        // console.log( document.querySelector( "#name" ).value );
        const roleName = document.querySelector( "#name" ).value;

        //Setup all the values for the modal
        const data = {  title       : "Do you really want to update this system role?",
                        message     : "Click the button to confirm adding this system role",
                        btnAction   : "Update system role now",
                        actionMethod: this.ActionSaveSystemRoleHandler,
                        btnCancel   : "Cancel",
                        data : { rolename : roleName } //this stuff will be spread and dynamically added to the action button as key->value pairs
                     }

        //Set the state so that the modal can update
        this.setState( { modal : data } );

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    //Get the data attributes from the event button click
    async ActionSaveSystemRoleHandler( event ){
        // console.log( event.target.getAttribute( "rolename" ) )
        // console.log( "Attempting to save systemrole to db" );

        const newRoleName = event.target.getAttribute( "rolename" );

            this.setState( { loading : true } )

            const inputData =   {
                                    class: "access",
                                    method: "createSystemRole",
                                    data : { roleName : newRoleName }
            }

                try{
                    const getDataResponse = await this.props.getData( inputData, function( response){
                            //console.log( "Async complete" )
                            return response  } )
                    //console.log( "LC" + getDataResponse.result )
                    this.setState( { feedback : getDataResponse.result } );

                    if( getDataResponse.result === "success" ){
                        //console.log( JSON.stringify( getDataResponse.data ) )
                        this.props.showRolesListHandler()
                    }

                } catch( err ){
                    console.log( err );
                }
    }

    render(){

        const loading = ( this.state.loading ) ? <Loading /> : null

        return(
            <div className={ 'row' }>
                <h4 className={ 'text-white' }>Add System Role</h4>
                <input type='text' name='name' id='name' className={ 'form-control' } />
                <button className   =   { 'btn btn-dark btn-outline-warning mt-2' }
                        onClick     =   { this.fUpdateSystemRole }>
                    SAVE
                </button>

                <button className   =   { 'btn btn-secondary btn-outline-danger mt-2 ml-2' }
                        onClick     =   { this.props.showRolesListHandler } >
                    CANCEL
                </button>

                { loading }
                <HtmlModal modal={ this.state.modal }/>
            </div>
        );
    }
}

export default EditSystemRole;
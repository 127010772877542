import React, {Component} from 'react'
import TrainingHistoryBlock from '../Helpers/TrainingHistoryBlock'

import HtmlModal from '../Helpers/HtmlModal'

class BoxTrainingHistory extends Component{

    constructor( props ){
        super( props )

                this.state={    serviceProviderID       : props.editServiceProviderID,

                                historyList             : [],
                                feedback                : '',

                                modal:  {   title       :   '',
                                            message     :   '',
                                            btnAction   :   '',
                                            btnCancel   :   '',
                                            UUID        :   String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                            actionMethod:   null,
                                            data        :   null }


        }

        this.getTrainingHistory         = this.getTrainingHistory.bind( this )
    }

    componentDidMount(){
        this.getTrainingHistory();
    }

    async getTrainingHistory(){

        this.setState( { loading : true } );

        const inputData =   {   class: "serviceproviders",
                                method: "getTrainingHistory",
                                data: { serviceProviderID : this.state.serviceProviderID } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response ){
                        return response  } )

                if( getDataResponse.result === "success" && Array.isArray( getDataResponse.data ) ){

                    console.log( getDataResponse.data )

                    /********************  FIELDS  ********************/
                    /*  eventID, name, startDate, endDate, eventDays,
                        courseID, courseName, learnerCount, competent,
                        notCompetent, complete
                    /**************************************************/

                    console.log( "Display History" )
                    this.setState( {    historyList      :   getDataResponse.data.map( (event) =>
                                                                <TrainingHistoryBlock key={event.eventID} row={event} />
                                                             )
                                    } )
                } else{
                    this.setState( { feedback : <div className={'text-white'}>{getDataResponse.data}</div> } )
                    console.log( "Setting feedback" )
                }

            } catch( err ){
                console.log( err );
            }
    }

    render(){

        return(
            <div className={ 'col-sm-9 idzDark' } >
                <div className={'container-fluid'}>

                    <div className='row'>
                        <div className='col'>
                            <h4 style={{ fontSize: '2vw' }} className={'text-white'}>Training History</h4>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>

                            { ( this.state.feedback.length !== 0 ) ? this.state.feedback : "" }
                            { ( this.state.historyList.length !== 0 ) ? this.state.historyList : "" }

                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default BoxTrainingHistory
import React, { Component } from 'react'


import Label from '../Helpers/Label'

import HtmlModal from '../Helpers/HtmlModal'
import RowEventDate from './RowEventDate';

import RowSearchCourse from './RowSearchCourse'

import Auxiliary from '../HOC/Auxiliary'

class EditEvent extends Component{

    constructor( props ){
        super( props )

        this.state = {  editEventID                     : this.props.editEventID,

                        editingEvent                    : false,

                        searchCoursesTerm               : '',
                        searchCoursesResult             : [],

                        selectedCourseID                : '',
                        selectedCourseName              : '',
                        selectedServiceProviderName     : '',

                        displayEventDateRows    : [],

                        eventData: {
                                        name                :   '',
                                        courseID            :   '',
                                        notes               :   '',
                                        eventDays           :   1,
                                        eventDates          :   [],
                                        startDate           :   '',
                                        endDate             :   '',
                                        funder              :   '',
                                        eventAddress        :   '',
                                        eventCity           :   '',
                                        eventPostalCode     :   '',
                                        eventProvince       :   '',
                                        notesServiceProvider:   '',
                                        trainingCentre      :   '',
                                        exitOpportunity     :   ''
                        },

                        modal       :  {
                                            title       : '',
                                            message     : '',
                                            btnAction   : '',
                                            btnCancel   : '',
                                            UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                            actionMethod:   null,
                                            data        :   null }
                    }
                    this.AdjustEventDaysHandler         = this.AdjustEventDaysHandler.bind(this)
                    this.ManageEventDates               = this.ManageEventDates.bind( this )
                    this.UpdateFormField                = this.UpdateFormField.bind( this )
                    this.UpdateSearchCoursesTermHandler = this.UpdateSearchCoursesTermHandler.bind( this )
                    this.UpdateSearchServiceProviderTermHandler = this.UpdateSearchServiceProviderTermHandler.bind( this )
                    this.SearchCourses                  = this.SearchCourses.bind( this )
                    this.SelectCourseHandler            = this.SelectCourseHandler.bind( this )
                    this.UpdateEventHandler             = this.UpdateEventHandler.bind( this )
                    this.SwitchEditingMode              = this.SwitchEditingMode.bind( this )
    }

    SwitchEditingMode( e ){
        console.log( e.target.getAttribute( "action" ) )
        const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
        this.setState( {    editingEvent : value },
                            ()=>{
                                    console.log( "EVENTS SwitchEditingMode : Trying to redisplay eventDates " + this.state.editingEvent )
                                    if( this.state.editingEvent ) { this.DisplayEventDateRows() }
                                    else { this.DisplayEventDateRows() }
                        }
                    )
    }

    componentDidMount(){

        if( this.state.editEventID !== '' ){
            /* fetch existing data */
            this.FetchEventData();
        } else{
            this.setState( { editingEvent : true } )
            this.DisplayEventDateRows();
        }
    }

    async FetchEventData(){

        //console.log( "ComponentDidUpdate will load eventID : " + this.state.editEventID )

        const inputData =   {   class   : "events",
                                method  : "fetchEventData",
                                data    : { eventID     :   this.state.editEventID }
                            }
                            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                                if( getDataResponse.result === "success" ){
                                    console.log( "Fetched selected event : " +  JSON.stringify( getDataResponse.data ) )

                                    const f     = getDataResponse.data      //the fetched data
                                    let set     = this.state.eventData      //create shallow copy

                                    set.name            =   f.name
                                    set.funder          =   f.funder
                                    set.courseID        =   f.courseID
                                    set.notes           =   f.notes
                                    set.eventDays       =   f.eventDays
                                    set.eventDates      =   f.eventDates.split(",")
                                    set.startDate       =   f.startDate
                                    set.endDate         =   f.endDate
                                    set.eventAddress    =   f.eventAddress
                                    set.eventCity       =   f.eventCity
                                    set.eventPostalCode =   f.eventPostalCode
                                    set.eventProvince   =   f.eventProvince
                                    set.notesServiceProvider = f.notesServiceProvider
                                    set.trainingCentre  =   f.trainingCentre
                                    set.exitOpportunity =   f.exitOpportunity

                                    this.setState(  {   selectedCourseID    :   f.courseID,
                                                        selectedCourseName  :   f.courseName,
                                                        selectedServiceProviderName : f.serviceProviderName,
                                                        eventData           : set
                                                    }, ()=>{ this.DisplayEventDateRows() } )
                                }
                            } catch( err ){ console.log( err ) }
    }

    UpdateSearchCoursesTermHandler( e ){
        if( e.target.value.length >= 0 ){
            this.setState( { searchCoursesTerm : e.target.value }, ()=>{ console.log( "Update search courses to " + this.state.searchCoursesTerm ) } )
        }
    }

    UpdateSearchServiceProviderTermHandler( e ){
        if( e.target.value.length > 0 ){
            this.setState( { searchServiceProvidersTerm : e.target.value }, ()=>{ console.log( "Update search service providers to " + this.state.searchCoursesTerm ) } )
        }
    }

    /* This is when you click on a course from the results list */
    SelectCourseHandler( e ){

        const evData    = this.state.eventData
        evData.courseID = e.target.getAttribute( "courseid" )

        this.setState( {    selectedCourseID            : e.target.getAttribute( "courseid" ),
                            selectedCourseName          : e.target.getAttribute( "coursename" ),
                            selectedServiceProviderName : e.target.getAttribute( "serviceprovidername" ),

                            eventData           : evData,

                            searchCoursesTerm   :   '',
                            searchCoursesResult :   [] } )
    }

    async UpdateEventHandler(){
        const inputData =   {
                                class   : "events",
                                method  : "updateEvent",
                                data    : { eventID     :   this.state.editEventID,
                                            data        :   this.state.eventData }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            this.setState( { editEventID : getDataResponse.eventID }, ()=>{
                                //Confirm update modal
                                const modal = this.state.modal
                                modal.title        = "System confirmation"
                                modal.message      = "Update complete"
                                modal.btnAction    = ''
                                modal.actionMethod = null
                                modal.btnCancel    = "OK"

                                this.setState( { modal : modal } )

                                document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                            } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATESEARCH COURSES  ***//
    async SearchCourses(){
        const inputData =   {
                                class   : "events",
                                method  : "searchCourses",
                                data    : { searchTerm     : this.state.searchCoursesTerm }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            console.log( JSON.stringify( getDataResponse.data ) )
                            this.setState( {    searchCoursesResult :    getDataResponse.data.map( (row)=>(
                                                                        <RowSearchCourse    key                 =   { row.courseID }
                                                                                            data                =   { row }
                                                                                            SelectCourseHandler =   { this.SelectCourseHandler } /> ) ),
                                                searchServiceProvidersTerm      : '',
                                                searchServiceProvidersResult    : [] } )
                        }
                } catch( err ){ console.log( err ) }
    }

    UpdateFormField( event ){
        event.preventDefault()
        const name  = event.target.name
        const value = event.target.value

        //console.log( "Update : " + name + " : " + value );

        //shallow copy
        let data = this.state.eventData
        //Assign new value to specific property of deeply nested value
        data[name] = value;
        //update state
        this.setState( { data } )
    }

    //Add the selected date at the correct index in the array
    ManageEventDates( e ){

        const arrIndex  = e.target.id
        const eventData = this.state.eventData      //shallow copy to make it mutable

        eventData.eventDates[arrIndex] = e.target.value

        //this.setState( { eventData : eventData }, console.log( JSON.stringify( this.state.eventData.eventDates ) ) )
        this.setState( {    displayEventDateRows    :   [],
                            eventData               : eventData }, () => { this.DisplayEventDateRows() } )
    }

    /*  This method handles both adding of additional date slots, and deleting specific slots  */
    AdjustEventDaysHandler( e ){
        const action    = e.target.getAttribute( "action" );
        const eventDataTemp = this.state.eventData      //shallow copy to make it mutable
        let count       = Number(this.state.eventData.eventDays)

        //****************  INCREASE  *****************/
                if( action === "increase" ) {
                    count++
                    eventDataTemp.eventDays = count
                    this.setState( {    displayEventDateRows    : [],
                                        eventData               : eventDataTemp }, () => { this.DisplayEventDateRows() } )
                    return
                }

        //****************  DECREASE  *****************/

                if( action === "decrease" ){

                    const tempArray = eventDataTemp.eventDates      //try unique copy

                    count = ( count > 1 ) ? count - 1 : 0

                    if( count > 0 ){

                        const btnindex = e.target.getAttribute( "dateindex" )

                        for( let i = 0; i < ( tempArray.length - 1 ); i++ ){
                            if( tempArray[i] === tempArray[ btnindex ] ){
                                tempArray.splice(i, 1);
                                console.log( "After splice " + JSON.stringify( tempArray ) )
                            }
                        }

                    }

                    eventDataTemp.eventDays     = count
                    eventDataTemp.eventDates    = tempArray
                    this.setState( {    displayEventDateRows    : [],
                                        eventData               : eventDataTemp }, ()=>{ this.DisplayEventDateRows() } )
                }
    }

    DisplayEventDateRows(){
        let objects = [];

        if( this.state.eventData.eventDates ){ this.state.eventData.eventDates.sort() } //sort the array by date

        for( let i = 0; i < (this.state.eventData.eventDays); i++ ){

            const showDate = ( this.state.eventData.eventDates[i] !== '' ) ? this.state.eventData.eventDates[i] : ''

            objects.push( <RowEventDate     key                     = { "key" + i }
                                            date                    = { showDate }
                                            dateIndex               = { i }
                                            editingEvent            = { this.state.editingEvent }
                                            ManageEventDates        = { this.ManageEventDates }
                                            AdjustEventDaysHandler  = { this.AdjustEventDaysHandler } /> )
        }
        this.setState( { displayEventDateRows : objects } )
    }

    render(){

        return(
            <div className = { ( ( this.state.editEventID === '' ) ? "col-sm-12" : "col-sm-9" ) }>
                <div className={ 'container idzDark pb-3'}>

                    {
                            ( this.state.editEventID !== '' )
                            ?       <div className= { 'row' }>
                                            {
                                                    ( this.props.fHasAccess( 19 ) )
                                                    ?   <div className={ 'col-sm-3 p-1' }>
                                                            <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                                                        action      = 'true'
                                                                        onClick     = { this.SwitchEditingMode } >Edit
                                                            </button>
                                                    </div>
                                                    : ""
                                            }

                                            <div className={ 'col-sm-3 p-1' }>
                                                    <button className={'btn btn-dark btn-sm border-warning btn-block'} onClick={ this.props.LoadDashHandler }>Back</button>
                                            </div>
                                    </div>
                            : ""
                    }

                    <div className= { 'row' }>

                            {/*  BUTTONS   */}
                            <div className={ 'col-sm-12 text-white mb-2' } >
                                    <h4 className={ 'inlineBlock' }>
                                            { ( this.state.editEventID !== '' ) ? "Edit" : "Add" } Intervention &nbsp; &nbsp;
                                            { ( this.state.editEventID !== '' )
                                                    ? <span className={ 'text-warning' }>
                                                            { this.state.eventData.name }
                                                    </span>
                                                    : "" }
                                    </h4>

                                {
                                    ( this.state.editingEvent )
                                    ?   <Auxiliary>
                                            {/***  SAVE / UPDATE  ***/}
                                            <button     className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                            disabled        =       { false }
                                                            onClick         =       { this.UpdateEventHandler } >
                                                    {  this.state.editEventID !== ''
                                                            ? "Update" : "Save" }
                                            </button>

                                            {/***  CANCEL  ***/}
                                            <button className   =   { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                    onClick     =   { ( this.state.editEventID ) ? this.SwitchEditingMode : this.props.LoadDashHandler }
                                                    action      =   'cancel' >
                                                    Cancel
                                            </button>
                                        </Auxiliary>
                                    : ""
                                }

                            </div>

                    </div>

                    <div className= { 'row text-white' }>

                        <div className={ 'col-sm-4' }>

                            <Label text='Intervention Name' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-danger'
                                    id          ='name'
                                    name        ='name'
                                    placeholder = 'event name'
                                    value       ={ this.state.eventData.name }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingEvent } />

                            <Label text='Funder' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='funder'
                                    name        ='funder'
                                    placeholder = 'event funder'
                                    value       ={ this.state.eventData.funder }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingEvent } />

                            {/* DISPLAY THE COURSE NAME AND SERVICE PROVIDER NAME IF SELECTED */}
                            <div style={{ display: ( this.state.selectedCourseID !== '' ) ? "block" : "none" }}>
                                <Label text='Selected Course' />
                                <div className={'card card-success bg-dark border-success p-1'}>

                                    <div className={'row'}>
                                        <div className={'col-sm-12 text-warning'} style={{ fontSize: "1.1vw" }}>
                                            Course:
                                        </div>
                                        <div className={'col-sm-12 text-white text-right'} style={{ fontSize: "1.1vw" }}>
                                            { this.state.selectedCourseName }
                                        </div>
                                    </div>

                                    <div className={'row'}>
                                        <div className={'col-sm-12 text-warning'} style={{ fontSize: "1.1vw" }}>
                                            Service Provider:
                                        </div>
                                        <div className={'col-sm-12 text-white text-right'} style={{ fontSize: "1.1vw" }}>
                                            { this.state.selectedServiceProviderName }
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <Label text='Intervention or Service Provider Name' />
                            <div style={{ fontSize: '1vw' }} className={ 'text-warning' }>
                                The result will return interventions if the Service Provider has available interventions.
                            </div>
                            <div className={ 'text-center input-group' }>
                                <input  type        =   'text'
                                            id          =   'courseName'
                                            name        =   'courseName'
                                            className   =   { "form-control form-control-sm border-success" }
                                            value       =   { this.state.searchCoursesTerm }
                                            onChange    =   { this.UpdateSearchCoursesTermHandler }
                                            placeholder =   'type search here'
                                            readOnly    =   { !this.state.editingEvent } />

                                <span className={ "input-group-btn" }>

                                    <button     className   =   { "btn btn-search btn-sm btn-outline-success" }
                                                type        =   "button"
                                                onClick     =   { this.SearchCourses }
                                                disabled    =   { !this.state.editingEvent }>
                                        Search
                                    </button>
                                </span>
                            </div>

                            <div    className   =   { 'card card-success border-success bg-dark p-2 mt-2' }
                                    style       =   {{ minHeight: "10px" ,maxHeight: "40vh", overflowY: "auto" }}>
                                { this.state.searchCoursesResult }
                            </div>

                            {/* <Label text='Service Provider' />
                            <div className={ 'text-center input-group' }>
                                <input  type        =   'text'
                                            id          =   'serviceProvider'
                                            name        =   'serviceProvider'
                                            className   =   { "form-control form-control-sm border-success" }
                                            value       =   { this.searchServiceProvidersTerm }
                                            onChange    =   { this.UpdateSearchServiceProviderTermHandler }
                                            placeholder =   'search for service provider'/>

                                <span className={ "input-group-btn" }>

                                    <button     className   =   { "btn btn-search btn-sm btn-outline-success" }
                                                type        =   "button"
                                                onClick     =   { null }>
                                        Search
                                    </button>
                                </span>
                            </div> */}

                            <Label text='Notes' />
                            <textarea  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white mt-1'
                                    id          ='notes'
                                    name        ='notes'
                                    value       ={ this.state.eventData.notes }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingEvent } />

                        </div>

                        <div className={ 'col-sm-4' } >

                            <Label text='Total number of days for this course' />
                            <div className={ 'container' } >
                                <div className={ 'row' }>

                                    <div className={ 'text-center input-group' }>
                                        <input  type        =   'text'
                                                    id          =   'eventDays'
                                                    name        =   'eventDays'
                                                    className   =   { "form-control form-control-sm border-success text-center" }
                                                    value       =   { this.state.eventData.eventDays }
                                                    style       =   {{ marginRight: "20px" }}
                                                    disabled    =   "disabled"
                                                    placeholder =   'search for service provider' />

                                        <span className={ "input-group-btn" }>
                                            <div className={ 'btn-group' } role="group">

                                                <button     className   =   { "btn btn-search btn-sm btn-outline-dark text-white" }
                                                            type        =   "button"
                                                            action      =   "increase"
                                                            onClick     =   { this.AdjustEventDaysHandler }
                                                            disabled    =   { !this.state.editingEvent } >
                                                    &#x2b;
                                                </button>
                                            </div>
                                        </span>
                                    </div>

                                </div>
                            </div>

                            <Label text='Start date' />
                            <input  type        ='date'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='startDate'
                                    name        ='startDate'
                                    value       ={ this.state.eventData.startDate }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingEvent } />

                            <Label text='End date' />
                            <input  type        ='date'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='endDate'
                                    name        ='endDate'
                                    value       ={ this.state.eventData.endDate }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingEvent } />

                            <div className={ 'bg-success mt-4 p-1' } >Days List</div>

                            { this.state.displayEventDateRows }
                        </div>

                        <div className={ 'col-sm-4' }>

                                <Label text='Intervention Address' />
                                <textarea
                                        id          =   'eventAddress'
                                        name        =   'eventAddress'
                                        value       =   { this.state.eventData.eventAddress }
                                        onChange    =   { this.UpdateFormField }
                                        className   =   'form-control form-control-sm bg-dark text-white'
                                        readOnly    =   { !this.state.editingEvent } ></textarea>

                                <Label text='City / Town' />
                                <input  type        =   'text'
                                        id          =   'eventCity'
                                        name        =   'eventCity'
                                        value       =   { this.state.eventData.eventCity }
                                        onChange    =   { this.UpdateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =   { !this.state.editingEvent } />

                                <Label text='Postal Code' />
                                <input  type        =   'text'
                                        id          =   'eventPostalCode'
                                        name        =   'eventPostalCode'
                                        value       =   { this.state.eventData.eventPostalCode }
                                        onChange    =   { this.UpdateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =   { !this.state.editingEvent } />

                                <Label text='Province' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'eventProvince'
                                        name        =   'eventProvince'
                                        value       =   { this.state.eventData.eventProvince }
                                        onChange    =   { this.UpdateFormField }
                                        disabled    =   { !this.state.editingEvent } >
                                        <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                        <option value={ 'Free State' } >Free State</option>
                                        <option value={ 'Gauteng' } >Gauteng</option>
                                        <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                        <option value={ 'Limpopo' } >Limpopo</option>
                                        <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                        <option value={ 'North West' } >North West</option>
                                        <option value={ 'Northern Cape' } >Northern Cape</option>
                                        <option value={ 'Western Cape' } >Western Cape</option>
                                </select>

                                <Label text='Service Provider Notes' />
                                <textarea   type        ='text'
                                            className   ='form-control form-control-sm text-white bg-dark border-white mt-1'
                                            id          ='notesServiceProvider'
                                            name        ='notesServiceProvider'
                                            value       ={ this.state.eventData.notesServiceProvider }
                                            onChange    ={ this.UpdateFormField }
                                            readOnly    ={ !this.state.editingEvent } />

                                <Label text='Training Centre' />
                                <textarea   type        ='text'
                                            className   ='form-control form-control-sm text-white bg-dark border-white mt-1'
                                            id          ='trainingCentre'
                                            name        ='trainingCentre'
                                            value       ={ this.state.eventData.trainingCentre }
                                            onChange    ={ this.UpdateFormField }
                                            readOnly    ={ !this.state.editingEvent } />

                                <Label text='Exit Opportunity' />
                                <textarea   type        ='text'
                                            className   ='form-control form-control-sm text-white bg-dark border-white mt-1'
                                            id          ='exitOpportunity'
                                            name        ='exitOpportunity'
                                            value       ={ this.state.eventData.exitOpportunity }
                                            onChange    ={ this.UpdateFormField }
                                            readOnly    ={ !this.state.editingEvent } />
                        </div>

                    </div>
                </div>

                <HtmlModal modal={ this.state.modal } />
            </div>
        )
    }
}

export default EditEvent
import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

//***  SIDEBAR COMPONENTS  ***//
import SidebarHome from './SidebarHome';
import SidebarBlank from '../Sidebar/SidebarBlank';
import BoxDash from './BoxDash';
import BoxUserData from './BoxUserData';

import UserRow from './UserRow'

import HtmlModal from '../Helpers/HtmlModal'

//***  CONTENT COMPONENTS  ***//

class Users extends Component {

    constructor( props ){
        super( props )
        this.state = {
                        //stats
                        usersCount  : 0,


                        sidebar   : props.sidebar,
                        box       : props.box,
                        editUserID: '',

                        searchTerm  :   '',

                        displaySearchResults    :   '',

                        modal:  {
                            title           :   "SYSTEM CONFIRMATION",
                            message         :   "Do you really want to delete the user?",
                            actionMethod    :   this.actuallyDeleteUserHandler.bind( this ),
                            btnAction       :   "Delete user profile now",
                            btnCancel       :   "Cancel",
                            UUID            :   String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                            data            :   {}
                        }
        }

        this.editUserHandler            = this.editUserHandler.bind( this )
        this.UpdateSearchTermHandler    = this.UpdateSearchTermHandler.bind( this )
        this.getAdminUsers              = this.getAdminUsers.bind( this )
        this.loadUserViewHandler        = this.loadUserViewHandler.bind( this )
        this.deleteUserHandler          = this.deleteUserHandler.bind( this )
        this.actuallyDeleteUserHandler  = this.actuallyDeleteUserHandler.bind( this )
        this.loadUserDashHandler        = this.loadUserDashHandler.bind( this )

    }

    componentDidMount(){
        this.UserStats()
    }

    async UserStats(){

        this.setState( { loading : true } )

        const inputData =   {   class: "users",
                                method: "userStats",
                                data : null }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( {    usersCount  : getDataResponse.data.usersCount } )
                }

            } catch( err ){ console.log( err ) }
    }

    async getAdminUsers(){

        //console.log( "Clicked users search" )

        this.setState( { loading : true } )

        const inputData =   {   class: "users",
                                method: "returnAdminUsers",
                                data : {} }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( {    loading                 : false,
                                        displaySearchResults    : getDataResponse.data.map( ( row ) =>
                                            <UserRow    loadUserViewHandler =   { this.loadUserViewHandler }
                                                        editUserHandler     =   { this.editUserHandler }
                                                        deleteUserHandler   =   { this.deleteUserHandler }

                                                        data                =   { row }
                                                        key                 =   { row.id } />
                                        ) } )
                }

            } catch( err ){ console.log( err ) }
    }

    async actuallyDeleteUserHandler( e ){
        const deleteUserID = e.target.getAttribute( "userid" )

        const inputData =   {   class   : "users",
                                method  : "removeAdminUser",
                                data    : { userID : deleteUserID } }

        try{ const getDataResponse = await this.props.getData(  inputData,
                                                                function( response){ return response  } )

            if( getDataResponse.result === "success" ){
                this.getAdminUsers()        //reload admin users
            }

        } catch( err ){ console.log( err ) }
    }

    /***  POPUP CONFIRM DELETE USER ***/
    deleteUserHandler( e ){
        const userID = e.target.getAttribute( "userid" );

        //Set the state so that the modal can update
        //shallow copy
        let modalData = this.state.modal.data

        modalData["userid"] = userID

        this.setState( { modalData } );

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    UpdateSearchTermHandler( e ){
        const searchTerm = e.target.value
        console.log( "search term  : " + searchTerm )
        this.setState( { searchTerm : searchTerm } )
    }

    addNewUserHandler = () =>{
        this.setState( {    sidebar     : "SidebarBlank",
                            box         : "BoxUserData",
                            editUserID  : '' } );
    }

    editUserHandler(){
        console.log( "Editing user" )
        this.setState( { sidebar: "SidebarBlank", box: "BoxUserData" } );
    }

    //Load the Users Dashboard
    loadUserDashHandler = () =>{
        this.setState( { sidebar: "SidebarHome", box: "BoxDash" } );
    }

    loadUserViewHandler = ( e ) =>{
        const userID = e.target.getAttribute( "data-userid" );
        console.log( "Clicked to view userID: " + userID );
        this.setState( { editUserID : userID } )

        this.editUserHandler();
    }

    render(){

        var componentList = {
            "SidebarHome"   : SidebarHome,
            "SidebarBlank"  : SidebarBlank,

            "BoxDash"       : BoxDash,
            "BoxUserData"   : BoxUserData

        };

        // //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
        const componentSidebar  = this.state.sidebar;
        const componentBox      = this.state.box;

        // //RETURN A HANDLE TO THE COMPONENT
         const DynSidebar    = componentList[  componentSidebar ];
         const DynBox        = componentList[  componentBox ];

        return(
                <div className={ 'section' } >
                    <div className={ 'row' }>

                        { /* SIDEBAR */ }       <DynSidebar     addNewUserHandler       = { this.addNewUserHandler }
                                                                UpdateSearchTermHandler = { this.UpdateSearchTermHandler }
                                                                getAdminUsers           = { this.getAdminUsers }
                                                                fHasAccess              = { this.props.fHasAccess }

                                                                editUserID          = { this.state.editUserID }
                                                                searchTerm          = { this.state.searchTerm }
                                                                userAccessRights    = { this.props.userAccessRights } />

                        { /* BOX CONTENT */ }   <DynBox getData             = { this.props.getData }
                                                        loadUserDashHandler = { this.loadUserDashHandler }
                                                        editUserHandler     = { this.editUserHandler }
                                                        fHasAccess          = { this.props.fHasAccess }

                                                        editUserID          = { this.state.editUserID }
                                                        displaySearchResults= { this.state.displaySearchResults }
                                                        userAccessRights    = { this.props.userAccessRights }

                                                        usersCount          = { this.state.usersCount } />

                        <HtmlModal  modal={ this.state.modal } />
                    </div>
                </div>
        );
    }

}

export default Users;
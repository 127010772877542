import React from 'react'

const Filters = ( props ) =>{

    //console.log( "Filters received : " + JSON.stringify( props ) )

    return(
        <div className={ 'container' } >

            <div    className   =   { 'row mb-2 border-info' }
                    style       =   {{ borderTop: "1px solid white", borderBottom: "1px solid white" }} >

                <div className={ 'col-sm-12 text-info text-center' } >
                    Filter & search Options
                </div>

            </div>

            <div className={ 'row' } >

                <div className={ 'col-sm-8' } >By Event</div>
                <div className={ 'col-sm-4' } >
                    <input  type        =   'checkbox'
                            name        =   'events'
                            id          =   'events'
                            checked     =   { props.filters.events }
                            onChange    =   { props.updateFilters } />
                </div>

            </div>

            <div className={ 'row' } >

                <div className={ 'col-sm-8' } >All Users</div>
                <div className={ 'col-sm-4' } >
                    <input  type        =   'checkbox'
                            name        =   'users'
                            id          =   'users'
                            checked     =   { props.filters.users }
                            onChange    =   { props.updateFilters } />
                </div>

            </div>

            <div className={ 'row' } >

                <div className={ 'col-sm-8' } >All Service Provides</div>
                <div className={ 'col-sm-4' } >
                    <input  type        =   'checkbox'
                    name        =   'serviceProviders'
                    id          =   'serviceProviders'
                    checked     =   { props.filters.serviceProviders }
                    onChange    =   { props.updateFilters } />
                </div>

            </div>

            <div className={ 'row' } >

                <div className={ 'col-sm-8' } >All Learners</div>
                <div className={ 'col-sm-4' } >
                    <input  type        =   'checkbox'
                            name        =   'learners'
                            id          =   'learners'
                            checked     =   { props.filters.learners }
                            onChange    =   { props.updateFilters } />
                </div>

            </div>
        </div>
    )
}

export default Filters
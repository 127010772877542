import React, {Component} from 'react'

import NewDocument from '../Helpers/NewDocument'

import DocumentsList from '../Helpers/DocumentsList'

import HtmlModal from '../Helpers/HtmlModal'

import UploadDocument from '../Helpers/UploadDocument'
//import axios from 'axios'
//import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'

class BoxCourseDocuments extends Component{

    constructor( props ){
        super( props )

                this.state={    documentsList           : [],

                                uploadFile              : [],

                                pdfBlob                 : '',

                                modal:  {   title       :   '',
                                            message     :   '',
                                            btnAction   :   '',
                                            btnCancel   :   '',
                                            UUID        :   String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                            actionMethod:   null,
                                            data        :   null }
        }

        this.PopupNewDocumentHandler        =   this.PopupNewDocumentHandler.bind( this )
        this.SaveNewFilenameHandler         =   this.SaveNewFilenameHandler.bind( this )
        this.RequestRemoveDocumentHandler   =   this.RequestRemoveDocumentHandler.bind( this )
        this.ConfirmRemoveDocumentHandler   =   this.ConfirmRemoveDocumentHandler.bind( this )
        this.PopupUploadDocumentHandler     =   this.PopupUploadDocumentHandler.bind( this )
        this.FileSelectedHandler            =   this.FileSelectedHandler.bind( this )
        this.FileUploadHandler              =   this.FileUploadHandler.bind( this )
        this.CompleteFileUpload             =   this.CompleteFileUpload.bind( this )
        this.FileDownloadHandler            =   this.FileDownloadHandler.bind( this )
    }

    componentDidMount(){
        this.FetchFilesHandler();
    }

    //Reads the file input control and sets state value for file that is about to be uploaded
    FileSelectedHandler( e ){
        console.log( e.target.files[0] )
        if( e.target.files[0].size < 5242880){
            this.setState( { uploadFile : e.target.files[ 0 ] } )
        } else{
            e.target.value = ""
            this.setState( { uploadFile : [] }, ()=>{ alert( "The file exceeds the maximum PDF filesize of 5mb" ) })
        }
    }

    //The upload button calls this first
    //Then the FileReader method is called with a callback function
    //The file complete upload method is passed as the callback function reference
    //After the blob has been read, the AJAX upload is called
    FileUploadHandler( e ){

        if( this.state.uploadFile.size > 0 ){
            const documentID = e.target.getAttribute( "documentid" )
            this.ReadBlob( documentID, this.CompleteFileUpload )

            const modal = this.state.modal

            modal.title         =   "..file uploading..."
            modal.message       =   <i className={'text-info'}>Please wait while your file uploads</i>
            modal.btnAction     =   ""
            modal.actionMethod  =   null
            modal.btnCancel     =   ""

            this.setState( { modal: modal } )
        } else{
            alert( "You have not selected a file to upload" )
        }


    }

    ReadBlob( documentID, callback ){
        var file    = this.state.uploadFile;
        var reader  = new FileReader();
        let blob    = '';

            reader.onload = function( e ) {
                blob = reader.result;
                callback( documentID, blob )
            }
            reader.readAsDataURL(file)
    }

    async CompleteFileUpload( documentID, blob ){
        const inputData =   {   class   : "documents",
                                method  : "uploadDocument",
                                data    : { documentID  : documentID,
                                            pdf         : blob } }

        try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
            if( getDataResponse.result === "success" ){
                console.log( "Upload complete" )

                const modal = this.state.modal
                modal.title         =   "SYSTEM CONFIRMATION"
                modal.message       =   <i className={'text-warning'}>FILE UPLOAD COMPLETE</i>
                modal.btnAction     =   "OK"
                modal.actionMethod  =   null
                modal.btnCancel     =   ""

                this.setState( { modal: modal }, ()=>{ this.FetchFilesHandler() } )
            }
        } catch( err ){ console.log( err ) }
    }

    RequestRemoveDocumentHandler( e ){
        const documentID = e.target.getAttribute( "documentid" )

        const modal = this.state.modal
        modal.title         =   "SYSTEM CONFIRMATION"
        modal.message       =   "Do you really want to remove this document"
        modal.btnAction     =   "Yes, remove this document"
        modal.actionMethod  =   this.ConfirmRemoveDocumentHandler
        modal.btnCancel     =   "Cancel"
        modal.data          =   { documentid : documentID }

        this.setState( { modal : modal }, ()=>{
            document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
        })
    }

    async ConfirmRemoveDocumentHandler( e ){
        const documentID = e.target.getAttribute( "documentid" )

        const inputData =   {
                    class   : "documents",
                    method  : "removeDocument",
                    data    : { documentID  : documentID }
        }
        try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
            if( getDataResponse.result === "success" ){
                this.FetchFilesHandler()
            }
        } catch( err ){ console.log( err ) }
    }

    PopupNewDocumentHandler(){
        const modal = this.state.modal

        modal.title     =   "Create a new document reference"
        modal.message   =   <NewDocument    refName = { String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now() }
                                            modalID                 =   { this.state.modal.UUID }
                                            SaveNewFilenameHandler  =   { this.SaveNewFilenameHandler } />
        modal.btnAction =   ""
        modal.actionMethod = ""
        modal.btnCancel =   "Cancel"

        this.setState( { modal : modal }, ()=>{
            document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
        } )
    }

    PopupUploadDocumentHandler( e ){
        const documentID = e.target.getAttribute( "documentid" )

        const modal =   this.state.modal
                modal.title         = "Upload document"
                modal.message       = <UploadDocument   documentid          = { documentID }
                                                        FileSelectedHandler = { this.FileSelectedHandler }
                                                        FileUploadHandler   = { this.FileUploadHandler }
                                                        CompleteFileUpload  = { this.CompleteFileUpload } />
                modal.btnCancel     = 'Cancel'
                modal.data          =   { documentid : documentID }

        this.setState( {    modal : modal },
                            ()=>{ document.querySelector("#" + this.state.modal.UUID ).style.display = "block" } )
    }

    //***  SAVE NEW FILENAME  ***//
    async FileDownloadHandler( e ){

        const fileReference = e.target.getAttribute( "fileReference" )

        const inputData =   {
                                class   : "documents",
                                method  : "download",
                                data    : { fileReference : fileReference }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                        //const filename          = getDataResponse.filename
                        //const uri               = getDataResponse.blob          //  PHP->  $blob = 'data:application/pdf;base64,'.base64_encode(file_get_contents( $filepath ));
                        const downloadLink      = document.createElement("a")
                        downloadLink.href       = getDataResponse.blob          //  PHP->  $blob = 'data:application/pdf;base64,'.base64_encode(file_get_contents( $filepath ))
                        downloadLink.download   = getDataResponse.filename

                        document.body.appendChild(downloadLink)
                        downloadLink.click();
                        document.body.removeChild(downloadLink)

                } catch( err ){ console.log( err ) }
    }

    //***  SAVE NEW FILENAME  ***//
    async SaveNewFilenameHandler( e ){

        let     filename = e.target.getAttribute( "inputref" );
                filename = document.querySelector( "#" + filename ).value;

        //console.log( "BoxCourseDocuments : " +  filename );

        document.querySelector( "#" + e.target.getAttribute( "modalid" ) ).style.display = "none"

        const inputData =   {
                                class   : "documents",
                                method  : "createDocumentName",
                                data    : { type        : "course",
                                            parentID    : this.props.editCourseID,
                                            description : filename }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            this.FetchFilesHandler()
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  FETCH FILES LIST  ***//
    async FetchFilesHandler( e ){

        const inputData =   {
                                class   : "documents",
                                method  : "fetchDocuments",
                                data    : { type        : "course",
                                            parentID    : this.props.editCourseID }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){

                            //console.log( "Files data from PHP : " + JSON.stringify( getDataResponse.data ) )

                            this.setState( {    documentsList :
                                                getDataResponse.data.map( ( row )=>(
                                                    <DocumentsList  doc             =   { row }
                                                                    key             =   { row.documentID }
                                                                    FileDownloadHandler          = { this.FileDownloadHandler }
                                                                    fHasAccess                   = { this.props.fHasAccess }
                                                                    RequestRemoveDocumentHandler = { this.RequestRemoveDocumentHandler }
                                                                    PopupUploadDocumentHandler   = { this.PopupUploadDocumentHandler } />
                                                ) )})
                        }
                } catch( err ){ console.log( err ) }
    }

    render(){

        return(
            <div className={ 'col-sm-9 text-white' }>

                    <div className={ 'container-fluid idzDark p-2' }>

                        <div className={ 'row' }>
                            <div className={ 'col-sm-12' }>
                                <h4 className   = { 'text-warning mx-auto' }
                                    style       = {{ fontSize: "1.6vw", display: "inline-block" }}>
                                    Documents
                                </h4>

                                <button     className   = { 'btn btn-sm border-warning bg-dark text-white float-right' }
                                            style       = {{ display: "inline-block" }}
                                            onClick     = { this.PopupNewDocumentHandler } >
                                    Add document
                                </button>
                            </div>
                        </div>

                        <div className={ 'card card-warning border-warning bg-dark m-2' }>
                            <div className={ 'container-fluid' }>
                                { this.state.documentsList }
                            </div>
                        </div>


                        {/*<NewDocument />*/}
                        <HtmlModal  modal={ this.state.modal } />

                    </div>
            </div>
        )
    }
}

export default BoxCourseDocuments
import React from 'react';


const Feedback = ( props ) => {

    let comp = "";

    if( props.message !== "" ){
        comp =  <div className={ "alert alert-info"}>
                    { props.message }
                </div>
    } else{
        comp =  <div />
    }

    return(
        <div>
            { comp }
        </div>
    );

}

export default Feedback;
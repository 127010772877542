import React, {Component} from 'react';

import Loading from '../Helpers/Loading'
import SystemRightRow from './SystemRightRow'

class RightsList extends Component{

        constructor( props ){
            super( props )
            this.state = {
                            listOfRights : null,
                            loading     : false
            }
        }

        //***  GET SYSTEM ROLES FOR DISPLAY  ***/
        async getSystemRights(){

            this.setState( { loading : true } )

            const inputData =   {
                                    class: "access",
                                    method: "getAllRights",
                                    data : ''
            }

                try{
                    const getDataResponse = await this.props.getData( inputData, function( response){
                            //console.log( "Async complete" )
                            return response  } )
                    //console.log( "LC" + getDataResponse.result )
                    this.setState( { feedback : getDataResponse.result } );

                    if( getDataResponse.result === "success" ){
                        //console.log( JSON.stringify( getDataResponse.data ) )
                        this.setState( {    loading : false,
                                            listOfRights : getDataResponse.data.map( ( row ) =>
                                                <SystemRightRow data={ row } key={ row.id } />
                                            ) } )
                    } else{
                        this.setState( {    loading : false,
                            listOfRights : <div className={ 'text-white' }>No system rights found</div>
                          } )
                    }

                } catch( err ){
                    console.log( err );
                }
        }

        componentDidMount(){
            this.getSystemRights();
        }

        render(){

            let loading = ( this.state.loading ) ? <Loading /> : "";

            return(
                <div className={ 'section' }>
                    <div className= { 'row' }>
                        <div className={ 'col-md-12' }>

                            <table>
                                <tbody>
                                    <tr>
                                        <td><h4 className={ 'text-warning' }>List of System Access Rights</h4></td>
                                        <td className={ 'pl-4' }>
                                                <button className={ 'btn btn-sm btn-success btn-outline-success float-right' }
                                                        onClick={ this.props.UpdateSystemRightHandler }>
                                                    ADD SYSTEM RIGHT
                                                </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                                { loading }     {/*  LOADING ICON */}

                            <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                                <table className={ 'table-sm mt-2' }>
                                    <tbody>
                                        { this.state.listOfRights }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
}

export default RightsList;
import React from 'react'


const SidebarSP = ( props ) =>{

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>
                <div className={ "text-center" }>
                    {/*<div className={'text-white'}>{ props.editLearnerID }</div>*/}

                    <h4 style={{ fontSize: '2vw' }}>Service Provider</h4>


                    <button className   =   { 'btn btn-' + props.profileStatusColor + ' btn-sm mb-2' }
                            onClick     =   { props.ConfirmMakeServiceProviderActive }
                            disabled    =   { ( props.profileStatus === 'active' ) || !props.fHasAccess( 26 ) } >
                            { props.profileStatus }
                    </button>

                </div>
                <div className={ 'text-center' }>
                <img src={ require('../../icon/icon_man_tie.png') }  alt='Test' />
                </div>
                <br />
                <div className={ null }>
                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.ViewEditServiceProviderHandler }>Information</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.LoadDocumentsHandler } >Documents</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.ViewTrainingHistoryHandler }>Training History</button>

                </div>
            </div>
        </div>
    )
}

export default SidebarSP
import React from 'react'


const RowRecipient = ( props ) =>{

    //console.log( "RowRecipient " + JSON.stringify( props ) )

    return(
            <div className={'row'}  style={{ borderBottom: "1px solid rgba( 40,167,69,0.6 )" }} >
                <div className={ 'col-sm-8' } >
                    { props.data.profilename }<br />
                    <span className={ 'text-success' }>{ props.data.email }</span><br />
                    <span className={ 'text-success tblFontSizeSmall' }>TYPE:{ props.data.type }</span><br />
                </div>
                <div className={ 'col-sm-4' } >
                    <button     className   =   { 'btn btn-dark btn-sm border-danger float-right text-danger mt-2' }
                                action      =   "remove"
                                onClick     =   { props.recipientHandler }
                                profileid   =   { props.data.profileid } >
                        <b style={{ pointerEvents: "none" }}>&#8722;</b>
                    </button>
                </div>
            </div>
    )
}


export default RowRecipient
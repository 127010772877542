import React from 'react'

const RowMessage = ( props ) =>{

    return(
        <div className={ 'row' }>
            <div className={ 'col-sm-12 m-1' }>
                <div className={'card card-dark bg-dark border-success'}>

                    <div className='container-fluid bg-dark'>
                            <div className={ "row bg-dark" }>
                                {/*  HEADER  */}
                                <div className={ "col-sm-6" }  style={{ fontSize: "0.9vw" }}>
                                    <span className={ 'text-white' }>FROM : IDZ Procurement { props.data.sender }</span><br/>
                                    <span className={ 'text-warning' }>{ props.data.timeSent }</span>
                                </div>
                                <div className={ "col-sm-6" }>
                                    {
                                        ( props.fHasAccess( 11 ) )
                                        ?   <img        src         =   { require( '../../icon/icon_delete.png' ) }
                                                        className   =   { 'btn btn-light btn-sm border-danger float-right text-danger mt-2' }
                                                        onClick     =   { props.DeleteMessage }
                                                        style       =   {{ height: "28px", width: "30px", top: "-40px" }}
                                                        messageid   =   { props.data.messageID }
                                                        alt         =   "Remove message" />
                                        : ""
                                    }

                                </div>

                                {/*  BODY  */}
                                <div    className   =   { 'col-sm-12 bg-dark text-white p-2' }
                                        style       =   {{ fontSize: "1.1vw" }}>
                                    { props.data.message }
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RowMessage
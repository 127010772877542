import React, {Component} from 'react'

import Feedback from '../Helpers/Feedback'

import HtmlModal from '../Helpers/HtmlModal'

class ResetPassword extends Component{

    constructor( props ){
        super( props )

        this.state  =   {   feedback : '',

                            resetEmail  : '',
                            resetReady  : false,

                            modal   :   {   title           : '',
                                            message         : '',
                                            UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                            btnAction       : '',
                                            actionMethod    : null,
                                            btnCancel       : ''
                                        }
                        }

        this.RequestResetPassword   =   this.RequestResetPassword.bind( this )
        this.UpdateResetEmail       =   this.UpdateResetEmail.bind( this )
        this.ConfirmResetPassword   =   this.ConfirmResetPassword.bind( this )
    }

    UpdateResetEmail( e ){

        const resetReady = e.target.checkValidity()

        //console.log( "Validity: " + resetReady )

        this.setState( {    resetEmail  : e.target.value,
                            resetReady  : resetReady } )
    }

    RequestResetPassword(){

        const modal = this.state.modal

        modal.title         = "Password reset request"
        modal.message       = "Do you want to continue to reset your password?"
        modal.btnAction     = "Yes, reset password now"
        modal.actionMethod  = this.ConfirmResetPassword
        modal.btnCancel     = "Cancel"

        this.setState( { modal : modal } )

        document.querySelector("#" + this.state.modal.UUID ).style.display = "block"
    }

    async ConfirmResetPassword(){

        console.log( "Waiting for email setup to finalize password reset " )

        const inputData =   {   class: "users",
                                method: "confirmResetPassword",
                                data: { email   : this.state.resetEmail,
                                        userID  : this.props.userID } }

            try{ const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){

                    const modal = this.state.modal

                    modal.title         = "Email reset confirmed"
                    modal.message       = "An email with password reset instructions has been emailed to you."
                    modal.btnAction     = "Go back to the login page"
                    modal.btnCancel     = ''
                    modal.actionMethod  = this.props.LoadHomePage

                    this.setState( { modal : modal }, ()=>{
                        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                    })
                } else{

                    console.log( "No result " )
                    const modal = this.state.modal

                    modal.title         = "Password reset request failed"
                    modal.message       = `We're sorry.\n
                                           We tried to process your request but we were unable to do so.\n
                                            Please contact our offices for support.`
                    modal.btnAction     = "Go back to the login page"
                    modal.btnCancel     = ''
                    modal.actionMethod  = this.props.LoadHomePage

                    this.setState( { modal : modal }, ()=>{
                        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                    })
                }

            } catch( err ){ console.log( err ) }
    }

    render(){
        return(
            <div className={ 'container vertical-center' } >
                        <div className={ "row" } >

                            <div className={ "col-4 mx-auto text-center text-white  p-3" } >
                                <h1>I D Z</h1>

                                <h5 className={ "text text-info"} >Industrial Development Zone</h5>

                                <div className={ "idzDark p-3" } style={{ borderTop: "6px solid #17a2b8" }} >

                                                        <h2 style={{ fontSize: "1.5vw" }}>Request Reset Password</h2>
                                                        <br />

                                                        <div className={ "form-group" } >
                                                                <input  type        =   'email'
                                                                    placeholder =   'email address'
                                                                    name          =   'email'
                                                                    className   =   { 'form-control col-sm-8 mx-auto' }
                                                                    value       =   { this.resetEmail }
                                                                    onChange    =   { this.UpdateResetEmail }/>
                                                        </div>

                                                        <br />

                                                        <button type='button'
                                                            className   =   { 'btn btn-dark mx-auto m-2 border-info' }
                                                            onClick     =   { this.RequestResetPassword }
                                                            disabled    =   { ( !this.state.resetReady ) }>
                                                            REQUEST RESET NOW
                                                        </button>
                                                        <br />

                                                        {
                                                            ( this.state.feedback !== '' )
                                                            ?   <Feedback  message={ null } />
                                                            :   ""
                                                        }


                                        <button     type        =   "button"
                                                    className   =   { "btn btn-outline-white bg-dark mt-5 text-white" }
                                                    onClick     =   { this.props.LoadHomePage }  >
                                            <i>Back to Login page</i>
                                        </button>
                                </div>
                        </div>
                    </div>
                    <HtmlModal modal = { this.state.modal } />
            </div>
        )
    }
}

export default ResetPassword
import React from 'react'

/********************  FIELDS  ********************/
                    /*  eventID, name, startDate, endDate, eventDays,
                        courseID, courseName, learnerCount, competent,
                        notCompetent, complete
                    /**************************************************/

const TrainingHistoryBlock =( props ) => {

    // const data          = props.row;
    // const data = {  endDate         : '2019-09-21',
    //                 eventDatesdata  : '2019-09-21',
    //                 completionStatus: 'complete' }
    const today         = new Date().toISOString().slice(0, 10)

    // console.log( data.endDate + " : " + today )

    let   eventStatus   = ( props.row.endDate >= today ) ? "This intervention is IN PROGRESS" : "This intervention has PASSED"
    if( props.row.startDate > today ) { eventStatus =  'UPCOMING'; }

    let color = 'warning'

    switch( eventStatus ){
        case( 'UPCOMING' ):
            color = 'info'
            break

        case( 'PASSED' ):
            color = 'warning'
            break

        case( 'IN PROGRESS' ):
            color = 'success'
            break

        default:
            break
    }

    return(
        <div className={ 'row text-white idzDark' }>

                        <div className={ 'col-sm-2 p-1' }>
                            <div className={ 'card border-' + color + ' bg-dark text-center p-2' }
                                    style={{ height: '100%' }} >
                            <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} >{ eventStatus }</h5>
                            </div>

                        </div>

                        <div className={ 'col-sm-8 p-1' }>
                            <div className={ ' card border-' + color + ' bg-dark text-left p-2' } style={{ height: '100%' }} >
                                <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} >{ props.row.name }</h5>
                                    <table style={{ fontSize: '1vw' }}>
                                        <tbody>
                                            <tr>
                                                <th>Start Date</th>
                                                <td>{ props.row.startDate }</td>
                                            </tr>
                                            <tr>
                                                <th>End Date</th>
                                                <td>{ props.row.endDate }</td>
                                            </tr>
                                            <tr>
                                                <th>Student Count</th>
                                                <td>{ props.row.learnerCount }</td>
                                            </tr>
                                            <tr>
                                                <th>Not yet competent</th>
                                                <td>{ props.row.notCompetent }</td>
                                            </tr>
                                            <tr>
                                                <th>Competent</th>
                                                <td>{ props.row.competent }</td>
                                            </tr>
                                            <tr>
                                                <th>Complete</th>
                                                <td>{ props.row.complete }</td>
                                            </tr>
                                            <tr>
                                                <th>Incomplete</th>
                                                <td>{ props.row.incomplete }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>

                        <div className={ 'col-sm-2 p-1' }>
                            <div className={ ' card border-' + color + ' bg-dark text-center align-middle p-2' } style={{ height: '100%' }} >
                                <h5 style={{ fontSize: '1vw', fontWeight: 'bold' }} ><i>...placeholder...</i></h5>
                            </div>
                        </div>

                    </div>
    )
}

export default TrainingHistoryBlock
import React, { Component } from 'react';

import SidebarHome from './SidebarHome'
//import BoxDash from './BoxDash'
import RolesList from './RolesList'
import RightsList from './RightsList'
import EditSystemRole from './EditSystemRole'
import EditSystemRight from './EditSystemRight'

import LinkRights from './LinkRights'

class AccessRights extends Component{

    constructor( props ){
        super( props )
        this.state ={   sidebar : props.sidebar,
                        box     : props.box,
                        parameters    : null }

        //console.log( "ACCESS RIGHTS.js " + JSON.stringify( this.props ) )
    }

   ResetParametersState(){
        //***  CLEAR THE PARAMETERS AFTER EVERY COMPONENT SUCCESSFULL UPDATE ***/
        //const noValue = null;
        //this.setState( { parameters : noValue } )
        //console.log( this.state.parameters );
    }

    //***  LOAD THE LIST OF SYSTEM ACCESS ROLES ***//
    showRolesListHandler= () =>{
        this.setState( { box : 'RolesList' } )
    }

    //***  LOAD THE LIST OF SYSTEM ACCESS RIGHTS ***//
    showRightsListHandler= () =>{
        this.setState( { box : 'RightsList' } )
    }

    //***  UPDATE SYSTEM ROLE ***//
    UpdateSystemRoleHandler = () =>{
        this.setState( { box : 'EditSystemRole' } );
    }

    //***  UPDATE SYSTEM ROLE ***//
    UpdateSystemRightHandler = () =>{
        this.setState( { box : 'EditSystemRight' } )
    }

    //***  LINK SYSTEM ROLES TO ACCESS RIGHTS ***//
    LinkRightsHandler = ( e ) =>{
        //console.log( "Load screen for Role : " + e.target.getAttribute( "data-roleid" ) )

        this.setState( {    box     : 'LinkRights',
                            parameters    : {   roleID      : e.target.getAttribute( "data-roleid" ),
                                                roleName    : e.target.getAttribute( "data-rolename" ) }
                        } )
    }

        render(){

            var componentList = {
                "SidebarHome"   : SidebarHome,

                "RolesList"     : RolesList,
                "RightsList"    : RightsList,
                "EditSystemRole": EditSystemRole,
                "EditSystemRight"   : EditSystemRight,

                "LinkRights"    : LinkRights

            };

            // //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
            const componentSidebar  = this.state.sidebar;
            const componentBox      = this.state.box;

            // //RETURN A HANDLE TO THE COMPONENT
            const DynSidebar    = componentList[  componentSidebar ];
            const DynBox        = componentList[  componentBox ];

            return(

                <div className={ 'section' } >
                    <div className={ 'row' }>

                        { /* SIDEBAR */ }       <DynSidebar showRightsListHandler = { this.showRightsListHandler }
                                                            showRolesListHandler = { this.showRolesListHandler } />

                        { /* BOX CONTENT */ }   <DynBox getData                 = { this.props.getData }
                                                        UpdateSystemRoleHandler = { this.UpdateSystemRoleHandler }
                                                        modalHandler            = { this.props.modalHandler }
                                                        LinkRightsHandler       = { this.LinkRightsHandler }
                                                        showRolesListHandler    = { this.showRolesListHandler }
                                                        showRightsListHandler   = { this.showRightsListHandler}
                                                        UpdateSystemRightHandler= { this.UpdateSystemRightHandler }
                                                        parameters              = { this.state.parameters }
                                                        ResetParametersState    = { this.ResetParametersState } />
                    </div>
                </div>

            );
        }

}

export default AccessRights;
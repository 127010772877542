import React from 'react';


const SidebarHome = ( props ) =>{

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>

                {
                    ( props.fHasAccess( 16 ) )
                    ?   <div className={ null }>
                            <button className   =   { 'btn btn-dark btn-lg border-warning btn-block' }
                                    onClick     =   { props.UpdateCourseHandler }
                                    style       =   {{ fontSize: '1.5vw', whiteSpace: 'normal' }} >
                                Add New Training Program
                            </button>
                            <br />
                        </div>
                    : ""
                }

                <div className={ 'text-center input-group' }>
                    <input  type        =   'text'
                                id          =   'searchTerm'
                                name        =   'searchTerm'
                                className   =   { "form-control form-control-sm" }
                                value       =   { props.searchTerm }
                                onChange    =   { props.UpdateSearchTerm }
                                placeholder =   'enter search text'/>

                    <span className={ "input-group-btn" }>

                        <button     className   =   { "btn btn-search btn-sm btn-outline-warning" }
                                    type        =   "button"
                                    onClick     =   { props.SearchCoursesHandler }>

                            <img    src         =   { require( '../../icon/icon_magnifier.png') }
                                    className   =   { "tblIcon" } alt='Magnifier icon'
                                    style       =   {{ pointerEvents: "none" }} />

                        </button>
                    </span>
                </div>
            </div>
        </div>
    );

}

export default SidebarHome;
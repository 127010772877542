import React, { Component } from 'react';

import HtmlModal from '../Helpers/HtmlModal'

//***  SIDEBAR COMPONENTS  ***//
import SidebarHome from './SidebarHome'
import SidebarBlank from './SidebarBlank'
import SidebarEvent from './SidebarEvent'

//***  CONTENT COMPONENTS  ***//
import BoxDash from './BoxDash'
import EditEvent from './EditEvent'
import BoxAddLearners from './BoxAddLearners'
import BoxEventDocuments from './BoxEventDocuments'
import MilestoneDocuments from './MilestoneDocuments'

import RowSearchResult from './RowSearchResult'

class Events extends Component {

    constructor( props ){
        super( props )
        this.state = {

            //stats
            eventsCount :   0,
            activeEvents:   0,

            sidebar   : props.sidebar,
            box       : props.box,

            editEventID : '',

            searchTerm  : '',

            searchEventsDisplay     :   [],

            modal:  {   title       :      '',
                                message     :    '',
                                btnAction   :  '',
                                btnCancel   :   '',
                                UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                actionMethod:   null,
                                data        :   null }
        }
        this.LoadEditEventHandler   =   this.LoadEditEventHandler.bind( this )
        this.LoadDashHandler        =   this.LoadDashHandler.bind( this )
        this.SelectEditEvent        =   this.SelectEditEvent.bind( this )
        this.LoadLearnersHandler    =   this.LoadLearnersHandler.bind( this )
        this.LoadMilestonesHandler  =   this.LoadMilestonesHandler.bind( this )
        this.EventDocumentsHandler  =   this.EventDocumentsHandler.bind( this )
        this.SearchEvents           =   this.SearchEvents.bind( this )
        this.UpdateSearchTerm       =   this.UpdateSearchTerm.bind( this )

        this.ModalRequestRemoveEventHandler =   this.ModalRequestRemoveEventHandler.bind( this )
        this.ConfirmRemoveEventHandler      =   this.ConfirmRemoveEventHandler.bind( this )
    }

    componentDidMount(){
        this.EventsStats()
        //this.SearchEvents()
    }

    UpdateSearchTerm( e ){
        if( e.target.value === "" ){
            this.setState( {    searchTerm          : "",
                                searchEventsDisplay : []  } )
        } else{
            this.setState( { searchTerm : e.target.value } )
        }

    }

    LoadDashHandler(){
        this.setState( {    sidebar     : "SidebarHome",
                            box         : "BoxDash" } )
    }

    LoadEditEventHandler(){

        const sidebar = ( this.state.editEventID !== '' ) ? "SidebarEvent" : "SidebarBlank"

        this.setState( {    sidebar     : sidebar,
                            box         : "EditEvent" } )
    }

    EventDocumentsHandler(){
        this.setState( { box : "BoxEventDocuments" } )
    }

    SelectEditEvent( e ){
        console.log( "SelectEditEvent eventID : " + e.target.getAttribute( "eventID" ) )
        this.setState( { editEventID : e.target.getAttribute( "eventid" ) }, ()=>{ this.LoadEditEventHandler() } )
    }

    LoadLearnersHandler(){
        this.setState( {    sidebar : "SidebarEvent",
                            box     : "BoxAddLearners" } )
    }

    LoadMilestonesHandler(){
        this.setState( {    sidebar : "SidebarEvent",
                            box     : "MilestoneDocuments" } )
    }

    //***  STATS  ***//
    async EventsStats(){
        const inputData =   {   class   : "events",
                                method  : "eventsStats",
                                data    : '' }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            this.setState(  {   eventsCount     :   getDataResponse.data.eventsCount,
                                                activeEvents    :   getDataResponse.data.activeEvents } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATE SEARCH EVENTS  ***//
    async SearchEvents(){
        const inputData =   {
                                class   : "events",
                                method  : "searchEvents",
                                data    : { searchTerm :  this.state.searchTerm }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            //console.log( JSON.stringify( getDataResponse.data ) )
                            this.setState( {    searchEventsDisplay :    getDataResponse.data.map( (row)=>(
                                                                        <RowSearchResult    key                 =   { row.eventID }
                                                                                            data                =   { row }
                                                                                            SelectEditEvent     =   { this.SelectEditEvent }
                                                                                            ModalRequestRemoveEventHandler = { this.ModalRequestRemoveEventHandler } /> ) ) } )
                        }
                } catch( err ){ console.log( err ) }
    }

    ModalRequestRemoveEventHandler( e ){
        console.log( "Request remove Intervention" )

        const modal = this.state.modal

                modal.title         =   "PLEASE CONFIRM"
                modal.message       =   "Do you really want to remove this event?"
                modal.btnAction     =   "Yes, remove event"
                modal.actionMethod  =   this.ConfirmRemoveEventHandler
                modal.btnCancel     =   "Cancel"
                modal.data          =   { eventid : e.target.getAttribute( "eventid" ) }

        this.setState( { modal : modal }, ()=>{ document.querySelector( "#" + this.state.modal.UUID ).style.display = "block" } )
    }

    //***  REMOVE EVENT  ***//
    async ConfirmRemoveEventHandler( e ){
        const inputData =   {
                                class   : "events",
                                method  : "removeEvent",
                                data    : { eventID : e.target.getAttribute( "eventid" ) }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                                this.SearchEvents()
                        }
                } catch( err ){ console.log( err ) }
    }

    render(){

        var componentList = {
            "SidebarHome"       : SidebarHome,
            "SidebarBlank"      : SidebarBlank,
            "SidebarEvent"      : SidebarEvent,

            "BoxDash"           : BoxDash,
            "EditEvent"         : EditEvent,
            "BoxAddLearners"    : BoxAddLearners,
            "BoxEventDocuments" : BoxEventDocuments,
            "MilestoneDocuments": MilestoneDocuments
        };

        //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
        const componentSidebar  = this.state.sidebar;
        const componentBox      = this.state.box;

        //RETURN A HANDLE TO THE COMPONENT
        const DynSidebar    = componentList[  componentSidebar ];
        const DynBox        = componentList[  componentBox ];

        return(
            <div className={ 'section' } >
            <div className={ 'row' }>

            { /* SIDEBAR */ }       <DynSidebar     LoadEditEventHandler    = { this.LoadEditEventHandler }
                                                    LoadLearnersHandler     = { this.LoadLearnersHandler }
                                                    LoadMilestonesHandler   = { this.LoadMilestonesHandler }
                                                    fHasAccess              = { this.props.fHasAccess }
                                                    EventDocumentsHandler   = { this.EventDocumentsHandler }
                                                    SearchEvents            = { this.SearchEvents }
                                                    UpdateSearchTerm        = { this.UpdateSearchTerm } />

            { /* BOX CONTENT */ }   <DynBox         editEventID             = { this.state.editEventID }
                                                    searchEventsDisplay     = { this.state.searchEventsDisplay }
                                                    fHasAccess              = { this.props.fHasAccess }

                                                    LoadDashHandler         = { this.LoadDashHandler }
                                                    getData                 = { this.props.getData }

                                                    eventsCount             = { this.state.eventsCount }
                                                    activeEvents            = { this.state.activeEvents } />

            </div>

            <HtmlModal modal = { this.state.modal } />
        </div>
        );
    }


}

export default Events;
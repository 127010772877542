import React, { Component } from 'react';

class SystemRightRow extends Component{

    constructor( props ){
        super( props )
        this.updateLinkSystemRight = this.updateLinkSystemRight.bind( this )

        this.state = {
                            boxChecked : this.props.data.checked
        }
    }

    //***  GET SYSTEM ROLES FOR DISPLAY  ***/
    //this has a custom display output
    async updateLinkSystemRight( e ){

        //this.setState( { loading : true } )

        //console.log( e.target.id + " : " + e.target.getAttribute( "data-roleID" ) );
        const action = ( e.target.checked ) ? "link" : "unlink";

        //console.log( action )

        const inputData =   {
                                class: "access",
                                method: "linkAccessRight",
                                data : {    roleID          : e.target.getAttribute( "data-roleID" ),
                                            accessRightID   : e.target.id,
                                            action          : action }
        }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        //console.log( "Async complete" )
                        return response  } )
                //console.log( "LC" + getDataResponse.result )
                this.setState( { feedback : getDataResponse.result } );

                if( getDataResponse.result === "success" ){
                    //console.log( JSON.stringify( getDataResponse.data ) )
                    this.setState( { boxChecked : ( action === "link" ) ? "checked" : "" } )
                } else{
                    this.setState( {    loading : false,
                                        feedback: 'Could not link system right' } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    render(){
            return(
                <tr>
                    <td className={ 'text-white pr-3' }>
                        <input  type        =   'checkbox'
                                id          =   { this.props.data.id }
                                checked     =   { this.state.boxChecked }
                                data-roleid =   { this.props.roleid}
                                className   =   { 'imgCursorOver' }
                                onChange    =   { this.updateLinkSystemRight } />
                    </td>
                    <td className={ 'text-white' }>{ this.props.data.name }</td>
                </tr>
            )
    }
}

export default SystemRightRow;
import React from 'react'


const SidebarSP = ( props ) =>{

    console.log( 'Side props : ' + JSON.stringify( props ) )

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>
                <div className={ "text-center" }>
                    {/*<div className={'text-white'}>{ props.editLearnerID }</div>*/}
                    <h4 style={{ fontSize: '2vw' }}>Training Program</h4>

                    { /* <div className={ 'badge badge-success mb-2' }>Pending</div> */ }
                    <button className   =   { 'btn btn-' + props.trainingProgramStatusColor + ' btn-sm mb-2' }
                            onClick     =   { props.ConfirmMakeTrainingProgramActive }
                            disabled    =   { ( props.trainingProgramStatus === 'active' ) || !props.fHasAccess( 29 ) } >
                            { props.trainingProgramStatus }
                    </button>

                </div>
                <div className={ 'text-center' }>
                <img src={ require('../../icon/icon_man_tie.png') }  alt='Test' />
                </div>
                <br />
                <div className={ null }>
                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.UpdateCourseHandler }>Information</button>

                    <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { props.ViewCourseDocumentsHandler } >Documents</button>
{ /*
                     <button className   =   { "btn btn-dark btn-lg border-success btn-block" }
                            style       =   {{ fontSize: '1.5vw' }}
                            onClick     =   { null }>Event History</button>  */ }

                </div>
            </div>
        </div>
    )
}

export default SidebarSP
export default function Validation( value, validationType ){

        switch( validationType ){
            case( "birthdate" ):
                const arr           = value.split("-")        //break apart the date
                const currentYear   = new Date().getFullYear()
                arr[0] = ( arr[0] >= (currentYear - 70 ) && arr[0] <= (currentYear + 1 ) )
                                    ? arr[0] : currentYear
                value = arr[0] + "-" + arr[1] + "-" + arr[2]  //combine the parts again
                break;

            case( "idnumber" ):
                value = value.replace(/\D/g,'')             //only allow digits
                value = ( value.length > 12 ) ? value.substring(0,13) : value
                break;

            case( "postalcode" ):
                value = value.replace(/\D/g,'')             //only allow digits
                value = ( value.length > 4 ) ? value.substring(0,4) : value
                break;

            case( "phonenumber" ):
                value = value.replace(/\D/g,'')             //only allow digits
                value = ( value.length > 10 ) ? value.substring(0,10) : value
                break;

            default: break;
        }
    return value
}
import React, { Component } from 'react';

import HtmlModal from '../Helpers/HtmlModal'
import Loading from '../Helpers/Loading'

class EditSystemRight extends Component{

    constructor( props ){
        super( props )
        this.state = {  modal: {
                            title           : '',
                            message         : '',
                            btnAction       : '',
                            actionMethod    : null,
                            btnCancel       : '',
                            UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                            data            : {} }
                    }
        this.ActionSaveSystemRightHandler = this.ActionSaveSystemRightHandler.bind( this )
    }

    fUpdateSystemRight = () =>{
        console.log( document.querySelector( "#name" ).value )
        const rightName = document.querySelector( "#name" ).value

        //Setup all the values for the modal
        const data = {  title       : "Do you really want to update this system right?",
                        message     : "Click the button to confirm adding the system right",
                        btnAction   : "Update system right now",
                        actionMethod: this.ActionSaveSystemRightHandler,
                        btnCancel   : "Cancel",
                        UUID        : this.state.modal.UUID,
                        data : { rightname : rightName } //this stuff will be spread and dynamically added to the action button as key->value pairs
                     }

        //Set the state so that the modal can update
        this.setState( { modal : data } )

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    //Get the data attributes from the event button click
    async ActionSaveSystemRightHandler( event ){
        // console.log( event.target.getAttribute( "rightname" ) )
        // console.log( "Attempting to save system right to db" );

        const newRightName = event.target.getAttribute( "rightname" )

            this.setState( { loading : true } )

            const inputData =   {
                                    class: "access",
                                    method: "createSystemRight",
                                    data : { rightName : newRightName }
            }

                try{
                    const getDataResponse = await this.props.getData( inputData, function( response){
                            //console.log( "Async complete" )
                            return response  } )
                    //console.log( "LC" + getDataResponse.result )
                    this.setState( { feedback : getDataResponse.result } )

                    if( getDataResponse.result === "success" ){
                        //console.log( JSON.stringify( getDataResponse.data ) )
                        this.props.showRightsListHandler()
                    }

                } catch( err ){
                    console.log( err )
                }
    }

    render(){

        const loading = ( this.state.loading ) ? <Loading /> : null

        return(
            <div className={ 'row' }>
                <h4 className={ 'text-white' }>Add System Access Right</h4>
                <input type='text' name='name' id='name' className={ 'form-control' } />
                <button className   =   { 'btn btn-dark btn-outline-warning mt-2' }
                        onClick     =   { this.fUpdateSystemRight }>
                    SAVE
                </button>

                <button className   =   { 'btn btn-secondary btn-outline-danger mt-2 ml-2' }
                        onClick     =   { this.props.showRightsListHandler } >
                    CANCEL
                </button>

                { loading }
                <HtmlModal modal={ this.state.modal }/>
            </div>
        );
    }
}

export default EditSystemRight
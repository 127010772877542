import React, { Component } from 'react';

import Auxiliary from '../HOC/Auxiliary';

import Loading from '../Helpers/Loading'

import HtmlModal from '../Helpers/HtmlModal';

import Dashboard from './Dashboard'
import Communication from './Communication'
import Documents from './Documents'
import Information from './Information'
import TrainingHistory from './TrainingHistory'

//Main is called by App.js

class LearnerMain extends Component{

    constructor( props ){
        super( props )
        this.state = {

                box                 : 'Dashboard',

                loadingLearnerData  : true,

                learnerData         : null,

                stats:{
                      messages : 0,
                      enrolments : 0
                },

                modal: {
                    title       : '',
                    message     : '',
                    btnAction   : '',
                    actionMethod: null,
                    btnCancel   : '',
                    UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()
                }

        }

        this.DashboardHandler       = this.DashboardHandler.bind( this )
        this.DocumentsHandler       = this.DocumentsHandler.bind( this )
        this.InformationHandler     = this.InformationHandler.bind( this )
        this.CoursesHandler         = this.CoursesHandler.bind( this )
        this.CommunicationHandler   = this.CommunicationHandler.bind( this )

        this.fetchLearnerData       = this.fetchLearnerData.bind( this )
        this.fetchLearnerStats      = this.fetchLearnerStats.bind( this )
        this.waitForElement         = this.waitForElement.bind( this )
    }

    componentDidMount(){
        this.waitForElement();      //allow for initialization time
    }

    waitForElement(){
        if(typeof(localStorage.userID) !== "undefined"){
            //variable exists, do what you want
            this.fetchLearnerStats()
            this.fetchLearnerData()

        }
        else{
            setTimeout( this.waitForElement, 250);
        }
    }

    //***   NAVIGATION  ***/
    DashboardHandler(){
        this.setState( { box: "Dashboard" } )
    }

    InformationHandler(){
        this.setState( { box: "Information" } )
    }

    DocumentsHandler(){
        this.setState( { box: "Documents" } )
    }

    CoursesHandler(){
        this.setState( { box: "TrainingHistory" } )
    }

    CommunicationHandler(){
        this.setState( { box: "Communication" } )
    }

    async fetchLearnerStats(){

        const inputData =   {
            class: "learners",
            method: "fetchLearnerStats",
            data: { learnerID       : localStorage.userID,
                    sessionToken    : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse ){
                    let data = getDataResponse.data

                    let temp = this.state.stats
                    temp.messages   = data.messages
                    temp.enrolments = data.enrolments

                    this.setState({ temp }, ()=>{
                        console.log( this.state.stats )
                    })
                }
            } catch( err ){ console.log( err ) }
    }

    async fetchLearnerData(){
        const inputData =   {
            class: "learners",
            method: "returnLearnerData",
            data: { learnerID       : localStorage.userID,
                    sessionToken    : localStorage.sessionToken }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse ){

                    let learnerData = this.state.learnerData
                    let loading     = this.loadLearnerData

                    this.setState( {    learnerData         : getDataResponse.data,
                                        loadingLearnerData  : false }, ()=>{
                                            console.log( this.state.learnerData )
                                        } );
                }
            } catch( err ){ console.log( err ) }
    }

    //*********************/

    modalLogoutHandler = () =>{
        //Setup all the values for the modal
        const data = {  title       : "Do you really want to log out?",
                        message     : "",
                        btnAction   : "Yes, LOG OUT",
                        actionMethod: this.props.setLogoutHandler,
                        btnCancel   : "Cancel",
                        UUID        : this.state.modal.UUID,
                        data : null //this stuff will be spread and dynamically added to the action button as key->value pairs
                     }

        //Set the state so that the modal can update
        this.setState( { modal : data } );

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    render(){

        const componentsList = {
                Dashboard       : Dashboard,
                Communication   : Communication,
                Information     : Information,
                TrainingHistory : TrainingHistory,
                Documents       : Documents
        }

        const DynamicComponent = componentsList[ this.state.box ]

        return (
                <Auxiliary>

                <div className={ 'container-fluid' }>
                    <div className={ 'row p-1' }>

                        <div className={ 'col-sm-3' } >
                            {/*{ loadingIcon }*/}
                        </div>

                        <div className={ 'col-sm-9 p-1' }>
                            <img src={ require( '../../icon/icon_logout.png') }
                                        alt         = "Log out button"
                                        className   = { 'logOutBtn float-right' }
                                        onClick     = { this.modalLogoutHandler }
                                        style       = {{ marginTop: '5px' }} />

                            <span className={ 'float-right text-white small p-1' }>
                                    STATUS : { this.props.appStatus } &nbsp; &nbsp;
                            </span>
                        </div>

                        <div className={ 'col-sm-3' }>

                                    <ul className={ "nav nav-pills flex-column idzDark p-2" }>

                                        <div className={'text-center text-white'} style={{ fontSize : "1.4vw" }}>
                                            { this.props.firstname + ' ' + this.props.lastname + " : " + this.props.userID }
                                        </div>

                                        <li className   =   { "nav-item" }
                                            onClick     =   { this.DashboardHandler } >
                                            <span className={ "nav-link bg-dark text-white text-center active" }>
                                                <h1 style={{ fontSize : '4vw' }}>I D Z</h1>
                                            </span>
                                        </li>

                                        { /*  IMAGE  */ }
                                        <img src={ require( "../../icon/icon_person.png" ) }
                                                className={ "m-2 mx-auto" } alt="Icon for person" />

                                        <li className={ "nav-item" }>
                                            <button className   =  { 'btn btn-block nav-link bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =  { this.DashboardHandler }>
                                                Home
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.InformationHandler }
                                                    disabled    =   { this.state.loadingLearnerData } >
                                                Information
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.DocumentsHandler } >
                                                Documents
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.CoursesHandler }>
                                                Training History
                                            </button>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <button className   =   { 'btn btn-block bg-dark text-white mt-1 active mainMenuFont' }
                                                    onClick     =   { this.CommunicationHandler } >
                                                Communication
                                            </button>
                                        </li>



                                    </ul>
                        </div>

                        <div className={ 'col-sm-9' }>
                            <DynamicComponent   getData     = { this.props.getData }
                                                fHasAccess  = { this.props.fHasAccess }

                                                learnerID   = { this.props.userID }
                                                learnerData = { this.state.learnerData }
                                                stats       = { this.state.stats } />
                        </div>

                    </div>

                </div>
            <HtmlModal modal={ this.state.modal } />
            </Auxiliary>
        );

    }

}

export default LearnerMain;
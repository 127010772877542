import React, { Component } from 'react'

import AddLearnersSearch from './AddLearnersSearch'
import AddLearnersList from './AddLearnersList'

import RowAddLearner from './RowAddLearner'
import RowRemoveLearner from './RowRemoveLearner'

class BoxAddLearners extends Component{


    constructor( props ){
        super( props )

        this.state  =   {   searchTerm              :   '',
                            searchAllocatedLearners :   '',
                            displaySearchResults    :   [],
                            allocatedLearnersList   :   [],
                            originalLearnerslist    :   [],
                            displayAllocatedLearners:   [] }

        this.UpdateSearchTermHandler        = this.UpdateSearchTermHandler.bind( this )
        this.SearchToAddLearnersHandler     = this.SearchToAddLearnersHandler.bind( this )
        this.AddLearnerToEventHandler       = this.AddLearnerToEventHandler.bind( this )
        this.RemoveAllocatedLearnerHandler  = this.RemoveAllocatedLearnerHandler.bind( this )
        this.FilterAllocatedLearners        = this.FilterAllocatedLearners.bind( this )
        this.UpdateSearchAllocatedLearnersHandler = this.UpdateSearchAllocatedLearnersHandler.bind( this )
    }

    componentDidMount(){
        this.FetchEnrolmentsFromDB()
    }

    UpdateSearchTermHandler( e ){
        this.setState( { searchTerm : e.target.value } )
    }

    UpdateSearchAllocatedLearnersHandler( e ){
        console.log( e.target.value )
        this.setState( { searchAllocatedLearners : e.target.value } )
    }

    AddLearnerToEventHandler( e ){
        const firstname     =   e.target.getAttribute( "firstname" )
        const lastname      =   e.target.getAttribute( "lastname" )
        const learnerID     =   e.target.getAttribute( "learnerid" )

        //console.log( firstname + " : " + lastname + " : " + learnerID )

        const tempArray = this.state.allocatedLearnersList

        //console.log( "Before adding clicked learner : " + JSON.stringify( this.state.displayLearnersList ) )

        //Loop through and check if this learner is already in the array
        let canAdd = true
        for( let i = 0; i < tempArray.length; i++ ){

            const checkItem = tempArray[i]

            if( checkItem.learnerID === learnerID ){
                canAdd = false
            }
        }

        if( canAdd ){
            tempArray.push( {   firstname   : firstname,
                                lastname    : lastname,
                                learnerID   : learnerID } )
        }
        //console.log( "After checking to add learner se number of enrolled users is " + JSON.stringify( tempArray ) )

        //Need to map the result to an array
        this.setState( { allocatedLearnersList : tempArray },
                ()=>{   this.DisplayAllocatedLearners()
                        this.UpdateEventEnrolments() } )

    }

    DisplayAllocatedLearners(){

            //console.log( "BoxAddLearners->DisplayAllocatedLearners" + JSON.stringify( this.state.allocatedLearnersList ) )

                this.setState( { displayAllocatedLearners : this.state.allocatedLearnersList.map( ( row ) =>(
                            <RowRemoveLearner   data    =   { row }
                                                key     =   { row.learnerID }
                                                RemoveAllocatedLearnerHandler = { this.RemoveAllocatedLearnerHandler } />
                ) ) } )
    }

    async UpdateEventEnrolments(){
        const inputData =   {
                                class   : "events",
                                method  : "updateEnrolments",
                                data    : { eventID             : this.props.editEventID,
                                            allocatedLearners   : this.state.allocatedLearnersList }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            //console.log( "UpdateEventEnrolments : even enrolments updated" )
                        }
                } catch( err ){ console.log( err ) }
    }

    async FetchEnrolmentsFromDB(){
        // alert('fetching');
        const inputData =   {
                                class   : "events",
                                method  : "FetchEnrolmentsFromDB",
                                data    : { eventID             : this.props.editEventID }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                        if( getDataResponse.result === "success" ){
                            // alert( 'success' );
                            if( getDataResponse.data.length > 0 ){
                                // console.log( JSON.stringify( 'fetching enrolled learners' + getDataResponse.data ) )
                                this.setState( {    allocatedLearnersList   : getDataResponse.data,
                                                    originalLearnerslist    : getDataResponse.data },
                                                                ()=>{ this.DisplayAllocatedLearners() } )
                            }
                        } else{
                            // console.log( 'An error occurred fetching the enrolled learners' )
                        }
                } catch( err ){ console.log( err ) }
    }

    FilterAllocatedLearners(){

        console.log( 'FilterAllocatedLearners ' )

        let localList = [];

        if( this.state.searchAllocatedLearners.length === 0 ){
            console.log( 'not necessary to filter' )

            localList = this.state.originalLearnerslist;    //reset the list
        } else{

            for( let i = 0; i < this.state.originalLearnerslist.length; i++ ){

                const learner = this.state.originalLearnerslist[ i ];

                console.log( "Learner : " + JSON.stringify( learner ) )

                /*  Match strings in lowercase  */
                if( learner.firstname.toLowerCase().indexOf( this.state.searchAllocatedLearners.toLowerCase() ) >= 0
                    || learner.lastname.toLowerCase().indexOf( this.state.searchAllocatedLearners.toLowerCase() ) >= 0 ){

                    console.log( "Pushed" )

                    localList.push( learner )

                }
            }

        }
        this.setState( { allocatedLearnersList : localList }, ()=>{ this.DisplayAllocatedLearners() } )
    }

    RemoveAllocatedLearnerHandler( e ){
        const learnerID = e.target.getAttribute( "learnerid" )
        //console.log( "Remove Allocated Learner : " + learnerID )

        let tempList = this.state.allocatedLearnersList

        //Loop through and remove the learner from the array
        for( let i = 0; i < tempList.length; i++ ){

            const row = tempList[i];
            if( row.learnerID === learnerID ){
                tempList.splice( i, 1 )
                //console.log( "Found item at index " + i )
            }
        }

        this.setState( { allocatedLearnersList : tempList }, ()=>{ this.DisplayAllocatedLearners() } )
    }

    async SearchToAddLearnersHandler(){
        const inputData =   {
                                class   : "events",
                                method  : "searchToAddLearners",
                                data    : { searchTerm  : this.state.searchTerm,
                                            eventID     : this.props.editEventID }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                            //console.log( JSON.stringify( getDataResponse.data ) )

                            if( getDataResponse.data.length > 0 ){
                                    //console.log( "Preparing search results" )
                                    this.setState( {    displaySearchResults :  getDataResponse.data.map( ( row )=>(
                                                                                <RowAddLearner  key     =   { row.learnerID }
                                                                                                data    =   { row }
                                                                                                AddLearnerToEventHandler = { this.AddLearnerToEventHandler } /> ) ) } )
                            }

                        }
                } catch( err ){ console.log( err ) }
    }

    render(){

        return(
            <div className={'col-sm-9 pl-2 idzDark'} style={{ height: "90vh" }}>

                <div className={'container-fluid'}>

                    <div className={'row'}>
                        <span className={ 'text-info' }>{ this.state.allocatedLearnersList.length } enrolled users</span>
                    </div>

                    <div className={'row'}>
                        <div className={'col-sm-6 p-2'}>
                            <AddLearnersSearch  fHasAccess                  =   { this.props.fHasAccess }
                                                UpdateSearchTermHandler     =   { this.UpdateSearchTermHandler }
                                                SearchToAddLearnersHandler  =   { this.SearchToAddLearnersHandler }

                                                searchTerm                  =   { this.state.searchTerm }
                                                displaySearchResults        =   { this.state.displaySearchResults } />
                        </div>

                        <div className={'col-sm-6 p-2'}>
                            <AddLearnersList    fHasAccess                  =   { this.props.fHasAccess }
                                                UpdateSearchAllocatedLearnersHandler =   { this.UpdateSearchAllocatedLearnersHandler }
                                                FilterAllocatedLearners     =   { this.FilterAllocatedLearners }

                                                searchAllocatedLearners     =   { this.state.searchAllocatedLearners }
                                                displayAllocatedLearners    =   { this.state.displayAllocatedLearners } />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default BoxAddLearners
import React from 'react';

import StatsBlock from '../Helpers/StatsBlock'

const BoxDash = ( props ) =>{

    return(
        <div className={ 'col-9' } style={{ padding: "0px"}}>
            <div className={'container-fluid text-white'}>

                <div className= { 'row' }  style={{ padding: "0px"}} >
                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Registered Learner Count' statistic={ props.learnersCount } color='primary' />
                    </div>

                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Active Learners' statistic={ props.activeLearners } color='primary' />
                    </div>

                </div>

                {
                    ( props.fHasAccess(25) )
                    ?   <div className={'row p-2'}>
                            <div className={'col-sm-3'}>
                                <button     className       =   {'btn btn-warning btn-sm idzDark text-white'}
                                            onClick         =   { props.FDownloadCSV }
                                            filename        =   "export_learners.csv" >
                                    <img    src     =   { require( '../../icon/icon_report.png' ) }
                                            style   =   {{ height: "22px" }}
                                            alt     =   'Download report of active learners'
                                            title   =   'Download report of active learners'   /> &nbsp; Export Learners
                                </button>
                                { props.downloadBox }
                            </div>
                        </div>
                    :   ""
                }


                <div className= { 'row idzDark mt-1' }>
                    <div className={ 'col-12' }>
                        <div className={ 'text-center' }><h4>Search Results</h4></div>
                        {
                            ( props.displaySearchResults !== null )
                                ?
                                    <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                                        <table className={ 'table table-sm' }>
                                            <tbody>
                                                { props.displaySearchResults }
                                            </tbody>
                                        </table>
                                    </div>

                                : <div className={ 'text-center text-warning' }>No search results to display</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default BoxDash;
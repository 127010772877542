import React, { Component } from 'react';

import LearnerNavTop from './LearnerNavTop'
import Label from '../Helpers/Label'

import Auxiliary from "../HOC/Auxiliary"

import HtmlModal from "../Helpers/HtmlModal"

import Validation from '../Helpers/Validation'

class LearnerData extends Component{

        constructor( props ){

                super( props )

                this.state={    editingLearner : false,

                                loadedEmail      : '',
                                loadedRSA_ID     : '',

                                checkEmailUnique : true,
                                checkIDNumberUnique: false,

                                emailFormatValid : false,

                                lengthHomePhone     : false,
                                lengthMobile1       : false,
                                lengthMobile2       : false,

                                learnerData :
                                        {
                                                learnerID       :       '',
                                                firstname       :       '',
                                                lastname        :       '',
                                                email           :       '',
                                                mobile1         :       '',
                                                idNumber        :       '',
                                                birthDate       :       '',

                                                homeNumber              : '',
                                                mobile2                 : '',
                                                gender                  : 'm',
                                                race                    : 'Black',
                                                disability              : 'no',
                                                nationality             : 'RSA',
                                                qualification           : 'Grade 9 - 12',
                                                employmentStatus        : 'Unemployed',
                                                previousLearnerships    : '',

                                                residentialAddress      : '',
                                                residentialCity         : '',
                                                residentialPostalCode   : '',
                                                residentialProvince     : '',

                                                postalAddress           : '',
                                                postalCity              : '',
                                                postalPostalCode        : '',
                                                postalProvince          : ''
                                        },
                                btnSave :       true,
                                updateLearnerLoading : false,
                                localGetData : this.props.getData,

                                modal:  {       title       :      '',
                                                message     :    '',
                                                btnAction   :  '',
                                                btnCancel   :   '',
                                                UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                                actionMethod:   null,
                                                data        :   null }
                                }
                this.updateFormField    =       this.updateFormField.bind( this )
                this.updateLearner      =       this.updateLearner.bind( this )
                this.fetchLearnerData   =       this.fetchLearnerData.bind( this )
                this.SwitchEditingMode  =       this.SwitchEditingMode.bind( this )
                this.CopyAddressHandler =       this.CopyAddressHandler.bind( this )
                this.ShowValidationGuide=       this.ShowValidationGuide.bind( this )
                this.EmailOnKeyUpValidity =     this.EmailOnKeyUpValidity.bind( this )
        }

        CopyAddressHandler(){
                const data = this.state.learnerData
                data.postalAddress = data.residentialAddress
                data.postalCity    = data.residentialCity
                data.postalPostalCode = data.residentialPostalCode
                data.postalProvince   = data.residentialProvince

                this.setState( { learnerData : data } )
        }

        //called before render
        componentWillMount(){

                if( this.props.editLearnerID ) {
                        let data                = this.state.learnerData;
                        data["learnerID"]       = this.props.editLearnerID
                        this.setState( { data } )
                        this.fetchLearnerData();
                } else{
                        //console.log( "BoxLearnerData ComponentWillMount no learnerID to edit" )
                }
        }

        updateFormField( event ){
                event.preventDefault()
                const name  = event.target.name
                let   value = event.target.value

                //******  VALIDATION ******/
                if( event.target.getAttribute("validation") ){
                    const validationType = event.target.getAttribute("validation") //this must be set on the element
                    value                = Validation( value, validationType ) //use the imported helper function
                }
                //******************************/

                //shallow copy
                let data = this.state.learnerData

                //Save the current values
                    let lengthHomePhone = this.state.lengthHomePhone
                    let lengthMobile1   = this.state.lengthMobile1
                    let lengthMobile2   = this.state.lengthMobile2

                //See if the incoming form field matches one of the validations
                    if( name === "homeNumber" ){
                        lengthHomePhone   = ( name === 'homeNumber' && value.length === 10 )? true : false;
                    }

                    if( name === "mobile1" ){
                        lengthMobile1   = ( name === 'mobile1' && value.length === 10 )? true : false;
                    }

                    if( name === "mobile2" ){
                        lengthMobile2   = ( name === 'mobile2' && value.length === 10 )? true : false;
                    }

                //Assign new value to specific property of deeply nested value
                data[name] = value;
                //update state
                this.setState( {    data,
                                    lengthHomePhone : lengthHomePhone,
                                    lengthMobile1   : lengthMobile1,
                                    lengthMobile2   : lengthMobile2 },

                                ()=>{ this.ReadyToSaveProfile() } )
        }

        //Validation is a fuck up

            /*
                phone numbers need to be 10 characters in length, but not all phone numbers are required
                email needs to be unique
                ID number needs to be unique
            */

        ShowValidationGuide(){
            const modal = this.state.modal

            modal.title     = "Profile Validation Guide"
            modal.message   =  <center>
                                    <div style={{ textAlign: "left" }}>
                                        <h4 className={'text-warning'}>Required fields:</h4>
                                        <ul>
                                            <li>Firstname</li>
                                            <li>Surname</li>
                                            <li>Mobile number (Cellphone)</li>
                                            <li>Email address unique (Required to reset password)</li>
                                            <li>ID Number must be unique</li>
                                            </ul><br />
                                        <h4 className={'text-warning'}>Input data validation</h4>
                                        <ul>
                                        <li>Email address must be unique and in a valid format.</li>
                                        <li>ID number must be 13 digits in length, no spaces.</li>
                                        <li>Phone numbers must be 10 digits in length, no spaces.</li>
                                        <li>Postal code must be only digits, no spaces.</li>
                                        </ul>
                                    </div>
                                </center>
            modal.actionMethod = null
            modal.btnAction = ''
            modal.btnCancel = "OK"

            this.setState( { modal: modal }, ()=>{
                document.querySelector( "#" + this.state.modal.UUID).style.display = "block"
            } )
        }

        EmailOnKeyUpValidity( e ){
            console.log( "Email on key up validation : " + e.target.checkValidity() )
            this.setState( { emailFormatValid :  e.target.checkValidity() } )
        }

        //check if email unique and ID number unique
        async ReadyToSaveProfile(){

            if( this.state.learnerData.email.length > 0 || this.state.learnerData.idNumber.length === 13 )

            {       const inputData =   {   class: "users",
                                            method: "checkUnique",
                                            data: { email       : this.state.learnerData.email,
                                                    idNumber    : this.state.learnerData.idNumber  } }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                return response  } )

                                console.log( "readytosave : " + JSON.stringify( getDataResponse ) )

                        if( getDataResponse.result === "success" ){

                                const cState = this.state
                                cState.checkEmailUnique        = ( this.state.learnerData.email !== this.state.loadedEmail )
                                                                    ? getDataResponse.emailReady : true

                                // console.log( "Email unique feedback : " + getDataResponse.emailReady )

                                cState.checkIDNumberUnique     = ( this.state.learnerData.idNumber !== this.state.loadedRSA_ID )
                                                                    ? getDataResponse.idNumberReady : true

                                // console.log( "ID number unique feedback : " + getDataResponse.idNumberReady )

                                this.setState( {  cState },
                                                ()=>{   //console.log( "state update: " + this.state.checkEmailUnique )
                                                        this.ActivatupdateButtonHandler() })
                        }
                    } catch( err ){ console.log( err ) }
            } else{ this.ActivatupdateButtonHandler()  }
        }

        ActivatupdateButtonHandler(){

            //console.log( this.state.lengthHomePhone + " : " + this.state.lengthMobile1 + " : " + this.state.lengthMobile2 )

            let phoneNumbersValidation = true;

            // if( this.state.learnerData.homeNumber.length > 0 && !this.state.lengthHomePhone) { phoneNumbersValidation = false }
            if( this.state.learnerData.mobile1.length > 0 && !this.state.lengthMobile1) { phoneNumbersValidation = false }
            // if( this.state.learnerData.mobile2.length > 0 && !this.state.lengthMobile2) { phoneNumbersValidation = false }

            if(     this.state.learnerData.firstname !== ''
                    && this.state.learnerData.lastname !== ''
                    && this.state.learnerData.mobile1 !== ''
                    && this.state.learnerData.idNumber !== ''
                    && this.state.checkEmailUnique
                    && this.state.emailFormatValid
                    && this.state.checkIDNumberUnique && ( this.state.learnerData.idNumber.length === 13 )
                    && this.state.learnerData.mobile1.length === 10 )
            {
                    this.setState( { btnSave : false } )
            }
            else
            {
                    this.setState( { btnSave : true } )
            }
        }

        SwitchEditingMode( e ){
                //console.log( 'Learner switched editing mode : ' + e.target.getAttribute( "action" ) )
                const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
                if( value ) { console.log( 'Yes this is a boolean true and ID : ' + this.state.learnerData.learnerID ) }
                this.setState( { editingLearner : value } )
        }

        //***  FETCH THE LEARNER DATA IF learnerID exists ***//
        async fetchLearnerData(){

                //console.log( "Attempting to fetch learner data for learner ID : " + this.state.learnerData.learnerID );
                //this.setState( { loading : true } );

                const inputData =   {
                                        class: "learners",
                                        method: "returnLearnerData",
                                        data: { learnerID       : this.state.learnerData.learnerID  }
                }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                //console.log( "Async complete" )
                                return response  } )
                        //console.log( "LC" + getDataResponse.result )
                        this.setState( { feedback : getDataResponse.result } );

                        if( getDataResponse.result === "success" ){

                                //Shallow copy
                                let oldData     =       this.state.learnerData
                                //now overwrite it with the data from the web

                                //console.log( "Fetched learner data " + JSON.stringify( getDataResponse.count ) + " rows" )
                                oldData         =       this.state.learnerData
                                const learnerID       =       this.state.learnerData.learnerID      //prevent overwrite
                                oldData         =       getDataResponse.data

                                oldData.learnerID = learnerID

                                //console.log( oldData );

                                /*  Save the old email and old idNumber to compare against
                                    and prevent unique check  */
                                this.setState( {    learnerData : oldData,
                                                    loadedEmail : oldData.email,
                                                    loadedRSA_ID: oldData.idNumber,
                                                    checkEmailUnique: true,
                                                    checkIDNumberUnique: true,
                                                    emailFormatValid: true } )

                                //*** UPDATE LEARNER PROFILE STATUS  ***/
                                /*
                                        Send learner profile status to Learner.js to push it down to the sidebar view
                                */
                               this.props.DisplayLearnerProfileStatusHandler( getDataResponse.data.status )
                        }

                    } catch( err ){
                        console.log( err );
                    }
            }

        async updateLearner(){

                //console.log( "BoxLearnerData.js : Running update user method for creating and updating learner " + this.props.editLearnerID );
                this.setState( { loading : true } );

                const inputData =   {
                                        class: "learners",
                                        method: "updateLearner",
                                        data: {
                                                learnerID       : this.props.editLearnerID,
                                                firstname       : this.state.learnerData.firstname,
                                                lastname        : this.state.learnerData.lastname,
                                                email           : this.state.learnerData.email,
                                                mobile1         : this.state.learnerData.mobile1,
                                                idNumber        : this.state.learnerData.idNumber,
                                                birthDate       : this.state.learnerData.birthDate,

                                                homeNumber              : this.state.learnerData.homeNumber,
                                                mobile2                 : this.state.learnerData.mobile2,
                                                gender                  : this.state.learnerData.gender,
                                                race                    : this.state.learnerData.race,
                                                disability              : this.state.learnerData.disability,
                                                nationality             : this.state.learnerData.nationality,
                                                qualification           : this.state.learnerData.qualification,
                                                employmentStatus        : this.state.learnerData.employmentStatus,
                                                previousLearnerships     : this.state.learnerData.previousLearnerships,

                                                residentialAddress      : this.state.learnerData.residentialAddress,
                                                residentialCity         : this.state.learnerData.residentialCity,
                                                residentialPostalCode   : this.state.learnerData.residentialPostalCode,
                                                residentialProvince     : this.state.learnerData.residentialProvince,

                                                postalAddress           : this.state.learnerData.postalAddress,
                                                postalCity              : this.state.learnerData.postalCity,
                                                postalPostalCode        : this.state.learnerData.postalPostalCode,
                                                postalProvince          : this.state.learnerData.postalProvince
                                        }
                }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                //console.log( "Async complete" )
                                return response  } )

                        if( getDataResponse.result === "success" ){ this.setState( { loading : false } );

                            if( getDataResponse.learnerID ){

                                //Update Learners.js state : editLearnerID, profileStatus so that side panel can be displayed correctly after creating learner
                                this.props.CreateNewLearnerSetEditLearnerID( getDataResponse.learnerID, getDataResponse.status )

                                //shallow copy
                                let data = this.state.learnerData
                                //Assign new value to specific property of deeply nested value
                                data[ "learnerID" ] = getDataResponse.learnerID;
                                //update state
                                this.setState( { data }, ()=>{
                                        //Confirm update modal
                                        const modal = this.state.modal
                                        modal.title        = "System confirmation"
                                        modal.message      = "Update complete"
                                        modal.btnAction    = ''
                                        modal.actionMethod = null
                                        modal.btnCancel    = "OK"

                                        this.setState( { modal : modal } )

                                        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                                } )
                            }
                        }

                    } catch( err ){
                        console.log( err );
                    }
            }

        componentDidMount(){
                if( this.state.learnerData.learnerID === '') {
                        this.setState( { editingLearner : true } )
                }
        }

    render(){

        return(
                <div className={ ( this.state.learnerData.learnerID !== "" ) ? "col-sm-9" : "col-sm-12" } >

                <div className={ 'container' } >

                        { ( this.state.learnerData.learnerID !== "" )
                                ?  <LearnerNavTop       LoadDashHandler         = { this.props.LoadDashHandler }
                                                        fHasAccess              = { this.props.fHasAccess }
                                                        SwitchEditingMode       = { this.SwitchEditingMode } />
                                : ""  }


                        <div className= { 'row' }>

                                {/* CANCEL & SAVE */}
                                <div className={ 'col-sm-12 text-white mb-2' } >
                                        <h4 className={ 'inlineBlock' }>
                                                { ( this.state.learnerData.learnerID !== '' ) ? "Edit" : "Add" } Learner &nbsp; &nbsp;
                                                { ( this.state.learnerData.learnerID !== '' )
                                                        ? <span className={ 'text-warning' }>
                                                                { this.state.learnerData.firstname + " " + this.state.learnerData.lastname }
                                                        </span>
                                                        : "" }
                                        </h4>

                                        <button className   =   'btn btn-sm btn-warning btn-outline-warning'
                                                onClick     =   { this.ShowValidationGuide }>
                                            Profile guide
                                        </button>

                                        {
                                                ( this.state.editingLearner )
                                                ?       <Auxiliary>
                                                                <button     className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                                disabled        =       { this.state.btnSave }
                                                                                onClick         =       { this.updateLearner } >
                                                                        { ( typeof( this.state.learnerData.learnerID ) !== 'undefined'
                                                                            && this.state.learnerData.learnerID !== '' )
                                                                                ? "Update" : "Save" }
                                                                </button>

                                                                <button className       = { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                        onClick         = { ( this.state.learnerData.learnerID !== '' )
                                                                                                ? this.SwitchEditingMode
                                                                                                : this.props.LoadDashHandler }
                                                                        action          = 'cancel' >
                                                                        Cancel
                                                                </button>
                                                        </Auxiliary>
                                                : ""
                                        }

                                </div>

                        </div>

                        <div className={ 'row text-white p-2' } >

                        <div className={ 'col-3 idzDark pb-2 pt-2' } >

                                <input  type            =       'hidden'
                                        name            =       'learnerID'
                                        defaultValue    =       { this.state.learnerData.learnerID }
                                        onChange        =       { this.updateFormField } />

                                <Label text='Name' />
                                <input  type        =   'text'
                                        id          =   'firstname'
                                        name        =   'firstname'
                                        value       =   { this.state.learnerData.firstname }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-danger text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Surname' />
                                <input  type        =   'text'
                                        id          =   'lastname'
                                        name        =   'lastname'
                                        value       =   { this.state.learnerData.lastname }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-danger text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Email' />
                                <input  type        =   'email'
                                        id          =   'email'
                                        name        =   'email'
                                        value       =   { this.state.learnerData.email }
                                        onChange    =   { this.updateFormField }
                                        onKeyUp     =   { this.EmailOnKeyUpValidity }
                                        className   =   'form-control form-control-sm bg-dark border-danger text-white'
                                        readOnly    =  { !this.state.editingLearner } />
                                {
                                        ( ( !this.state.checkEmailUnique )
                                        ? <div className={'text-danger'} style={{ fontSize: "0.9vw" }}>
                                                This email already exists on the system.
                                          </div>
                                        : "" )
                                }
                                {
                                        ( ( !this.state.emailFormatValid )
                                        ? <div className={'text-danger'} style={{ fontSize: "0.9vw" }}>
                                                Please enter a valid email address.
                                          </div>
                                        : "" )
                                }


                                <Label text='Home Nr' />
                                <input  type        =   'text'
                                        id          =   'homeNumber'
                                        name        =   'homeNumber'
                                        validation  =   'phonenumber'
                                        value       =   { this.state.learnerData.homeNumber }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label  text    ='Mobile Nr 1' />
                                {/* <img src={ require('../../icon/icon_alarm.png') } style={{ height: "20px" }} /> */}
                                <input  type        =   'text'
                                        id          =   'mobile1'
                                        name        =   'mobile1'
                                        validation  =   'phonenumber'
                                        value       =   { this.state.learnerData.mobile1 }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-danger text-white'
                                        readOnly    =  { !this.state.editingLearner } />


                                <Label text='Mobile Nr 2' />
                                <input  type        =   'text'
                                        id          =   'mobile2'
                                        name        =   'mobile2'
                                        validation  =   'phonenumber'
                                        value       =   { this.state.learnerData.mobile2 }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='ID nr' />
                                <input  type        =   'text'
                                        id          =   'idNumber'
                                        name        =   'idNumber'
                                        value       =   { this.state.learnerData.idNumber }
                                        validation  =   "idnumber"
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-danger text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                {
                                        (   this.state.loadedRSA_ID === this.state.learnerData.idNumber
                                            || ( this.state.checkIDNumberUnique
                                            && this.state.learnerData.idNumber.length === 13 ) )
                                        ? ""
                                        :   <div className={'text-danger'} style={{ fontSize: "0.8vw" }}>
                                                Please enter a valid ID number
                                            </div>
                                }

                                <Label text='Birthdate' />
                                <input  type        =   'date'
                                        id          =   'birthDate'
                                        name        =   'birthDate'
                                        validation  =   'birthdate'
                                        value       =   { this.state.learnerData.birthDate }
                                        onChange    =   { this.updateFormField }
                                        onBlur      =   { this.updateFormField }  //need this to prevent false value
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />
                        </div>

                        <div className={ 'col-sm-3 idzDark pb-2 pt-2' } >



                                <Label text='Gender' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'gender'
                                        name        =   'gender'
                                        value       =   { this.state.learnerData.gender }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option value={ 'm' } >Male</option>
                                        <option value={ 'f' } >Female</option>
                                </select>

                                <Label text='Race' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'race'
                                        name        =   'race'
                                        value       =   { this.state.learnerData.race }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option value={ 'Black' } >Black: African</option>
                                        <option value={ 'Coloured' } >Black: Coloured</option>
                                        <option value={ 'Indian' } >Black: Indian</option>
                                        <option value={ 'White' } >White</option>
                                        <option value={ 'Other' } >Other</option>
                                </select>

                                <Label text='Disability' />
                                <input  type        =   'radio'
                                        value       =   'no'
                                        id          =   'disabilityNo'
                                        checked     =   { this.state.learnerData.disability === "no" }
                                        onChange    =   { ( this.state.editingLearner ) ? this.updateFormField : ()=>{} }
                                        name        =   'disability'  /> &nbsp; No &nbsp;

                                <input  type        =   'radio'
                                        value       =   'yes'
                                        id          =   'disabilityYes'
                                        checked     =   { this.state.learnerData.disability === "yes" }
                                        onChange    =   { ( this.state.editingLearner ) ? this.updateFormField : ()=>{} }
                                        name        =   'disability' /> &nbsp; Yes

                                <Label text='Nationality' />
                                <select className       =       { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id              =       'nationality'
                                        name            =       'nationality'
                                        value           =       { this.state.learnerData.nationality }
                                        onChange        =       { this.updateFormField }
                                        disabled        =       { !this.state.editingLearner }>
                                        <option value={ 'RSA' } >RSA</option>
                                        <option value={ 'Other' } >Other</option>
                                </select>

                                <Label text='Highest Qualification Type' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'qualification'
                                        name        =   'qualification'
                                        value       =   { this.state.learnerData.qualification }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option value={ 'Grade 9' } >Grade 9</option>
                                        <option value={ 'Grade 10' } >Grade 10</option>
                                        <option value={ 'Grade 11' } >Grade 11</option>
                                        <option value={ 'Grade 12' } >Grade 12</option>
                                        <option value={ 'Higher Certificate' } >Higher Certificate</option>
                                        <option value={ 'National Diploma' } >National Diploma</option>
                                        <option value={ 'Bachelors Degree' } >Bachelor's Degree</option>
                                        <option value={ 'Honours Degree' } >Honour's Degree</option>
                                        <option value={ 'Masters Degree' } >Master's Degree</option>
                                        <option value={ 'Doctors Degree' } >Doctor's Degree</option>
                                </select>

                                <Label text='Employement Status' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'employmentStatus'
                                        name        =   'employmentStatus'
                                        value       =   { this.state.learnerData.employmentStatus }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option value={ 'Unemployed' } >Unemployed</option>
                                        <option value={ 'Employed' } >Employed</option>
                                </select>

                                <Label text='Previous Learnerships Undertaken' />
                                <textarea
                                        id          =   'previousLearnerships'
                                        name        =   'previousLearnerships'
                                        value       =   { this.state.learnerData.previousLearnerships }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white' ></textarea>

                        </div>

                        <div className={ 'col-sm-3 idzDark pb-2 pt-2' } >

                                <div className={ 'text-center' }>
                                <h5 style={{ fontSize: '1.3vw' }}>RESIDENTIAL ADDRESS</h5>
                                </div>

                                <Label text='Residential Address' />
                                <textarea
                                        id          =   'residentialAddress'
                                        name        =   'residentialAddress'
                                        value       =   { this.state.learnerData.residentialAddress }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='City / Town' />
                                <input  type        =   'text'
                                        id          =   'residentialCity'
                                        name        =   'residentialCity'
                                        value       =   { this.state.learnerData.residentialCity }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Postal Code' />
                                <input  type        =   'text'
                                        id          =   'residentialPostalCode'
                                        name        =   'residentialPostalCode'
                                        validation  =   'postalcode'
                                        value       =   { this.state.learnerData.residentialPostalCode }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Province' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'residentialProvince'
                                        name        =   'residentialProvince'
                                        value       =   { this.state.learnerData.residentialProvince }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option >Select a province</option>
                                        <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                        <option value={ 'Free State' } >Free State</option>
                                        <option value={ 'Gauteng' } >Gauteng</option>
                                        <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                        <option value={ 'Limpopo' } >Limpopo</option>
                                        <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                        <option value={ 'North West' } >North West</option>
                                        <option value={ 'Northern Cape' } >Northern Cape</option>
                                        <option value={ 'Western Cape' } >Western Cape</option>
                                </select>

                        </div>

                        <div className={ 'col-sm-3 idzDark pb-2 pt-2' } >

                                <div className={ 'text-center' }>
                                <h5 style={{ fontSize: '1.3vw' }}>POSTAL ADDRESS</h5>
                                </div>

                                <Label text='Postal Address' />
                                <textarea
                                        id          =   'postalAddress'
                                        name        =   'postalAddress'
                                        value       =   { this.state.learnerData.postalAddress }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='City / Town' />
                                <input  type        =   'text'
                                        id          =   'postalCity'
                                        name        =   'postalCity'
                                        value       =   { this.state.learnerData.postalCity }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Postal Code' />
                                <input  type        =   'text'
                                        id          =   'postalPostalCode'
                                        name        =   'postalPostalCode'
                                        validation  =   'postalcode'
                                        value       =   { this.state.learnerData.postalPostalCode }
                                        onChange    =   { this.updateFormField }
                                        className   =   'form-control form-control-sm bg-dark border-light text-white'
                                        readOnly    =  { !this.state.editingLearner } />

                                <Label text='Province' />
                                <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                        id          =   'postalProvince'
                                        name        =   'postalProvince'
                                        value       =   { this.state.learnerData.postalProvince }
                                        onChange    =   { this.updateFormField }
                                        disabled    =  { !this.state.editingLearner } >
                                        <option >Select a province</option>
                                        <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                        <option value={ 'Free State' } >Free State</option>
                                        <option value={ 'Gauteng' } >Gauteng</option>
                                        <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                        <option value={ 'Limpopo' } >Limpopo</option>
                                        <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                        <option value={ 'North West' } >North West</option>
                                        <option value={ 'Northern Cape' } >Northern Cape</option>
                                        <option value={ 'Western Cape' } >Western Cape</option>
                                </select>

                                <center>
                                <button className  = {'btn btn-info btn-sm mt-5'}
                                        onClick    = { this.CopyAddressHandler }
                                        style      = {{ whiteSpace : "normal" }} >

                                        Copy from Residential
                                </button>
                                </center>
                        </div>
                </div>

                </div>
                                        <HtmlModal modal={ this.state.modal } />
                </div>
        );
    }

}

export default LearnerData
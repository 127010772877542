import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import Main from './components/Main';

import LearnerMain from './components/LearnerSite/LearnerMain'
import ServiceProviderMain from './components/ServiceProviderSite/ServiceProviderMain'

import Login from './components/Login/Login';
import ResetPassword from './components/Login/ResetPassword'
import NewPassword from './components/Login/NewPassword'

import Auxiliary from './components/HOC/Auxiliary';

class App extends Component{

    constructor( props ){
        super( props );

        const cookies = new Cookies();

        this.state ={

            dynamicComponent: '',

            appStatus       : "",
            userID          : "",
            firstname       : "",
            lastname        : "",
            userAccessRights: [],
            entityID        : "",
            sessionToken    : "",
            type            : "",
            cookies         : cookies,
            debug           : false,
            resetToken      : ''
        }

        this.setDebug       = this.setDebug.bind( this )
        this.getData        = this.getData.bind( this )
        this.fHasAccess     = this.fHasAccess.bind( this )
        this.ResetPassword  = this.ResetPassword.bind( this )
        this.LoadHomePage   = this.LoadHomePage.bind( this )
    }

    componentDidMount(){

        //console.log( "GET params : " + window.location.search )
        if( window.location.search.includes("reset")  ){
            let str = window.location.search
                str = str.replace( "?reset=", "", str )

            //console.log( "GET parameter save : " + str )
            this.setState( {    type : "reset",
                                resetToken: str }, ()=>{ console.log( "State resetToken: " + this.state.resetToken ) } )
            return      //stop executing
        }

        if( localStorage.sessionToken !== undefined ){
            const newState = this.state

            newState.sessionToken   = localStorage.sessionToken
            newState.userID         = localStorage.userID
            newState.firstname      = localStorage.firstname
            newState.lastname       = localStorage.lastname
            newState.type           = atob( localStorage.type )
            newState.userAccessRights = atob( localStorage.userAccessRights )

            this.setState( { newState }, ()=>{
                //console.log( "Fetched state from localStorage " + JSON.stringify( this.state ) )
                this.setState( { appStatus : "loggedIn" })
            } )
        }
    }

    setDebug( e ){
        if( e.target.isChecked ){
            this.setState( { debug: true } )
        } else{
            this.setState( { debug: false } )
        }
    }

    fHasAccess( checkAccessRightID ){

        //console.log( "APP.js : Checking if accessRightID " + checkAccessRightID + " is in the array " + this.state.userAccessRights )

        if( this.state.userAccessRights.indexOf( checkAccessRightID ) >= 0 ){
            return true
        } else{
            return false
        }
    }

    setLoggedInStatus = ( sessionToken, userID, userAccessRights, firstname, lastname, type, entityID ) => {
        //console.log( "App :  Clicked login button for user with type " + type );
        this.setState( {    appStatus       : "loggedIn",
                            sessionToken    : sessionToken,
                            userID          : userID,
                            firstname       : firstname,
                            lastname        : lastname,
                            type            : type,
                            entityID        : entityID,
                            userAccessRights: userAccessRights },
        ()=>{
            localStorage.sessionToken   = sessionToken
            localStorage.userID         = userID
            localStorage.firstname      = firstname
            localStorage.lastname       = lastname
            localStorage.entityID       = entityID
            localStorage.type           = btoa( type )
            localStorage.userAccessRights = btoa( userAccessRights )
        })
    }

    setLogoutHandler = () => {
        //console.log( "App :  Clicked logout button" );
        this.setState( {    appStatus : "loggedOut",
                            sessionToken: null }, ()=>{
                                //******  CLEAR LOCALSTORAGE *******/
                                localStorage.clear();
                             } );
    }

    /***  DATA includes the full class, method and parameters block  ***/
    async getData( inputData ){
        //console.log( "getData" + document.getElementById("setdebug").checked )

        inputData["userID"] = this.state.userID //Add userID to getData data obj

        //console.log( "App.js inputData for getData" + JSON.stringify( inputData ) )

        const data = [    {
                            "auth": "IDZ",
                            "data": {
                                "securityKey": "fellowst3f"
                            }
                        },
                        inputData
                    ]

            try {
                //console.log( "Starting async transaction" );
                let resp = { result : "No call made yet" }
                    resp = await
                                    //***  LOADING INDICATOR  ***//

                                    // fetch( "https://idz.tasktyme.com/app.php/",
                                    fetch( "https://lms.sbidz.co.za/app.php/",
                                        {
                                            method: 'POST',
                                            body:  JSON.stringify( data )
                                        } )
                                    .then( function(response){
                                                console.log( "Promise " + response.text )
                                                return response.text(); } )
                                    .then(
                                        //Method to handle the received data
                                        (dataReceived) => {
                                            console.log( dataReceived )
                                            if( document.getElementById("setdebug").checked ) { console.log( dataReceived ) }   //Do this to view PHP errors
                                            return JSON.parse( dataReceived );  //Do this to return valid JSON
                                        },
                                        //Error Handling
                                        (error) => {
                                            console.log( error );
                                            return error }
                                    );

                return resp
            } catch (err) {
                console.log("Await" + err)
              }

    }

    LoadHomePage(){
        this.setState( { dynamicComponent : '' } )
    }

    ResetPassword(){
        this.setState( { dynamicComponent : "ResetPassword" } )
    }

    render(){

        let compsList = {
            "Main"          : Main,
            "LearnerMain"   : LearnerMain,
            "ServiceProviderMain" : ServiceProviderMain,
            "Login"         : Login,
            "ResetPassword" : ResetPassword,
            "NewPassword"   : NewPassword
        }

        let DynamicComponent = "";

        if( this.state.appStatus === 'loggedIn' ){

            switch( this.state.type ){

                case( "user" ):
                    DynamicComponent = compsList[ "Main" ]
                    break

                    case( "learner" ):
                    DynamicComponent = compsList[ "LearnerMain" ]
                    break

                    case( "serviceProvider" ):
                    DynamicComponent = compsList[ "ServiceProviderMain" ]
                    break

                    case( "reset" ):
                    DynamicComponent = compsList[ "NewPassword" ]
                    break

                    default:
                    break
            }

        } else{

            switch( this.state.type ){
                case( "reset" ):
                    DynamicComponent = compsList[ "NewPassword" ]
                    break

                default:
                    DynamicComponent = compsList[ "Login" ]
                    break
            }
        }

        //handle dynamic component
        if( this.state.dynamicComponent !== '' ){
            DynamicComponent = compsList[ this.state.dynamicComponent ]
        }

        return(
                <Auxiliary>
                    { /***  DEBUG ***/ }
                    <div className={ 'text-white' }>
                        <input type='hidden' id='setdebug' onClick={ this.setDebug } />
                        {/* Debug  */}
                    </div>
                    { /***  END DEBUG ***/ }
                    <DynamicComponent   appStatus               = { this.state.appStatus }
                                        userAccessRights        = { this.state.userAccessRights }
                                        sessionToken            = { this.state.sessionToken }
                                        setLoggedInStatus       = { this.setLoggedInStatus }
                                        setLogoutHandler        = { this.setLogoutHandler }
                                        ResetPassword           = { this.ResetPassword }
                                        LoadHomePage            = { this.LoadHomePage }

                                        getData                 = { this.getData }
                                        fHasAccess              = { this.fHasAccess }
                                        userID                  = { this.state.userID }
                                        firstname               = { this.state.firstname }
                                        lastname                = { this.state.lastname }
                                        resetToken              = { this.state.resetToken } />

                </Auxiliary>
        );
    }

}

export default App;
import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Loading from '../Helpers/Loading'

import HtmlModal from '../Helpers/HtmlModal'

import Auxiliary from '../HOC/Auxiliary'

import { CSVLink, CSVDownload } from "react-csv";

class Reports extends Component {

    constructor( props ){
        super( )
        this.state = {

            loadingYearView             : true,
            eventData                  : [],
            csvDownload                 : [],

            filters: {
                        events          : false,
                        serviceProviders: false,
                        learners        : true,
                        users           : false
            },


            modal:  {   title       :   '',
                        message     :   '',
                        btnAction   :   '',
                        actionMethod:   null,
                        btnCancel   :   '',
                        UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                        data            :   {} }
        }

        this.trainingYearView       = this.trainingYearView.bind( this )

    }

    componentDidMount(){
        // this.trainingYearView()
        this.allLearnersReport()
    }

    formFieldUpdate( e ){
        const name = e.target.name;
        //console.log( e.target.value )
        let newState        = this.state
        newState[ name ]    = e.target.value
        this.setState( { newState } )

        if( name === "message" ){
            this.checkMessageReadyHandler()
        }
    }

    //***  ACTUALLY DO THE SEARCH ***//
    async trainingYearView(){

        console.log( "Getting year report" )
        const inputData =   {   class   : "reports",
                                method  : "trainingYearView",
                                data    : null }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse.data )

                let arrData = []

                if( getDataResponse.result === "success" ){
                    getDataResponse.data.map( (event)=>(
                        arrData.push( event )
                    ))


                    // Prepare CSV
                    const arrCSV = [[ 'Funder','Programme','Occupation','Start','End','#Learners','#Trained','Awaiting','Q1','Q2','Q3','Q4','Total' ]]

                    if( typeof( getDataResponse.data ) !== 'undefined' && getDataResponse.data.length > 0 ){

                        for( let i = 0; i < getDataResponse.data.length; i++ ){

                            const event = getDataResponse.data[i]

                            arrCSV.push(    [   event.funder,
                                                event.programme,
                                                event.occupation,
                                                event.startDate,
                                                event.endDate,
                                                event.totalLearners,
                                                event.totalTrained,
                                                event.totalAwaiting,
                                                event.q1,
                                                event.q2,
                                                event.q3,
                                                event.q4,
                                                event.totalLearners  ] );
                        }
                    }
                }

                this.setState({     loadingYearView : false,
                                    eventData       : arrData,
                                    csvDownload     : this.arrCSV })

            } catch( err ){ console.log( err ) }
    }

    async allLearnersReport(){

        console.log( "Getting year report" )
        const inputData =   {   class   : "reports",
                                method  : "idzAllLearnersReport",
                                data    : null }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse.data )

                let arrData = []

                if( getDataResponse.result === "success" ){
                    getDataResponse.data.map( (event)=>(
                        arrData.push( event )
                    ))


                    // Prepare CSV
                    const arrCSV = [[   'studentID',
                                        'Age',
                                        'Funder',
                                        'Firstname',
                                        'Lastname',
                                        'Gender',
                                        'Competent/NYC/Dropout',
                                        'Race',
                                        'Highest Passed Grade',
                                        'Town',
                                        'Address',
                                        'Mobile 1',
                                        'Mobile 2',
                                        'Email',
                                        'Programme',
                                        'Training Centre',
                                        'Training Start Date',
                                        'Training End Date',
                                        'Exit Opportunity'  ]]

                    if( typeof( getDataResponse.data ) !== 'undefined' && getDataResponse.data.length > 0 ){

                        for( let i = 0; i < getDataResponse.data.length; i++ ){

                            const row = getDataResponse.data[i]

                            arrCSV.push(    [   row.studentID,
                                                row.age,
                                                row.funder,
                                                row.firstname,
                                                row.lastname,
                                                row.gender,
                                                row.competent,
                                                row.race,
                                                row.qualification,
                                                row.town,
                                                row.address,
                                                row.mobile1,
                                                row.mobile2,
                                                row.email,
                                                row.programme,
                                                row.trainingCentre,
                                                row.startDate,
                                                row.endDate,
                                                row.exitOpportunity ] );
                        }
                    }
                }

                this.setState({     loadingYearView : false,
                                    eventData       : arrData,
                                    csvDownload     : this.arrCSV })

            } catch( err ){ console.log( err ) }
    }

    render(){

        return(
                <Auxiliary>
                {
                    ( this.state.loadingYearView )
                    ?   <div>
                            <Loading />  &nbsp;
                            <h4 className={'text-warning'} style={{ display: 'inline-block' }}>
                                <i>...loading year review report</i>
                            </h4>
                        </div>
                    :   <div className={ 'col-sm-12 text-white' } >

                            <div className={ 'container-fluid bg-dark rounded p-2' } >

                                <div className={ 'row-fluid' } style={{ overflowY: 'auto', maxHeight: '80vh' }}>

                                    <h3 style       =   {{ display: 'inline-block' }}>ALL LEARNERS REVIEW</h3>

                                    <CSVLink    className   =   {'btn btn-warning btn-sm'}
                                                data        =   { this.state.eventData }
                                                style       =   {{  marginLeft: '50px', marginTop: '-15px' }}>
                                        Download report
                                    </CSVLink>

                                    <table  className   =   {'table table-sm table-striped table-borderless'}
                                            style       =   {{ fontSize: '0.8vw', verticalAlign: 'bottom' }}>
                                        <thead>
                                            <tr style={{ background: 'rgba(255,255,255,0.2)' }}>
                                                {/* <td style={{verticalAlign: 'bottom'}}>Funder</td>
                                                <td style={{verticalAlign: 'bottom'}}>Programme</td>
                                                <td style={{verticalAlign: 'bottom'}}>Occupation</td>
                                                <td style={{verticalAlign: 'bottom'}}>Start</td>
                                                <td style={{verticalAlign: 'bottom'}}>End</td>
                                                <td style={{verticalAlign: 'bottom'}}>#Learners</td>
                                                <td style={{verticalAlign: 'bottom'}}>#Trained</td>
                                                <td style={{verticalAlign: 'bottom'}}>Awaiting</td>
                                                <td className={'text-success text-center'} style={{verticalAlign: 'bottom'}}>Q1</td>
                                                <td className={'text-success text-center'} style={{verticalAlign: 'bottom'}}>Q2</td>
                                                <td className={'text-success text-center'} style={{verticalAlign: 'bottom'}}>Q3</td>
                                                <td className={'text-success text-center'} style={{verticalAlign: 'bottom'}}>Q4</td>
                                                <td className={'text-primary text-center'} style={{verticalAlign: 'bottom'}}>Total</td> */}
                                                <td>Student ID</td>
                                                <td>Age</td>
                                                <td>Funder</td>
                                                <td>Firstname</td>
                                                <td>Lastname</td>
                                                <td>Gender</td>
                                                <td>Competent/NYC/Dropout</td>
                                                <td>Race</td>
                                                <td>Highest Passed Grade</td>
                                                <td>Town</td>
                                                <td>Address</td>
                                                <td>Mobile 1</td>
                                                <td>Mobile 2</td>
                                                <td>Email</td>
                                                <td>Programme</td>
                                                <td>Training Centre</td>
                                                <td>Training Start Date</td>
                                                <td>Training End Date</td>
                                                <td>Exit Opportunity</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                        {
                                            this.state.eventData.map( (row, i)=>(

                                                <tr key={ 'row' + i }>
                                                    {/* <td className={'accBlock'}>{ event.funder }</td>
                                                    <td className={'accBlock'}>{ event.programme }</td>
                                                    <td className={'accBlock'}>{ event.occupation }</td>
                                                    <td className={'accBlock'}>{ event.startDate }</td>
                                                    <td className={'accBlock'}>{ event.endDate }</td>
                                                    <td className={'accBlockNumber'} style={{ borderLeft: '1px solid #ffc107' }}>{ event.totalLearners }</td>
                                                    <td className={'accBlockNumber'} style={{ borderLeft: '1px solid #ffc107' }}>{ event.totalTrained }</td>
                                                    <td className={'accBlockNumber'} style={{ borderLeft: '1px solid #ffc107' }}>{ event.totalAwaiting }</td>
                                                    <td className={'accBlockNumber text-success'} style={{ borderLeft: '1px solid #ffc107' }}>{ event.q1 }</td>
                                                    <td className={'accBlockNumber text-success'} >{ event.q2 }</td>
                                                    <td className={'accBlockNumber text-success'}>{ event.q3 }</td>
                                                    <td className={'accBlockNumber text-success'}>{ event.q4 }</td>
                                                    <td className={'accBlockNumber text-primary'}
                                                        style={{ borderLeft: '1px solid #007bff', fontWeight: 'bold' }}>{ event.totalLearners }</td> */}
                                                    <td>{row.studentID}</td>
                                                    <td>{row.age}</td>
                                                    <td>{row.funder}</td>
                                                    <td>{row.firstname}</td>
                                                    <td>{row.lastname}</td>
                                                    <td>{row.gender}</td>
                                                    <td>{row.competent}</td>
                                                    <td>{row.race}</td>
                                                    <td>{row.qualification}</td>
                                                    <td>{row.town}</td>
                                                    <td>{row.address}</td>
                                                    <td>{row.mobile1}</td>
                                                    <td>{row.mobile2}</td>
                                                    <td>{row.email}</td>
                                                    <td>{row.programme}</td>
                                                    <td>{row.trainingCentre}</td>
                                                    <td>{row.startDate}</td>
                                                    <td>{row.endDate}</td>
                                                    <td>{row.exitOpportunity}</td>
                                                </tr>

                                            ))
                                        }

                                        </tbody>
                                    </table>

                                </div>

                            </div>


                        </div>
                }

                    <HtmlModal modal={ this.state.modal } />
                </Auxiliary>
        );
    }
}

export default Reports;
import React from 'react';
import Auxiliary from '../HOC/Auxiliary';

const Loading = ( props ) =>{

    return(
        <Auxiliary>
            <img src={ require( '../../icon/loadingS.gif') } className={ 'loadingGif' } alt='Loading icon' />
        </Auxiliary>
    );
}

export default Loading;
import React from 'react';


const SidebarHome = ( props ) =>{

    return(
        <div className={ 'col-sm-3 text-white'}>
            <div className={ 'idzDark p-2' }>
                {
                    ( props.fHasAccess(8) )
                        ?   <div className={ null }>
                                <button className   =   { 'btn btn-dark btn-lg border-warning btn-block' }
                                        onClick     =   { props.CreateLearnerHandler }
                                        style       =   {{ fontSize: '1.5vw', whiteSpace: 'normal' }} >
                                    Add New Learner
                                </button>

                                <br />
                            </div>
                        : ""
                }


                <div className={ 'text-center' }>
                    <h4 style={{ fontSize: '1.6vw' }}>Search for Learner</h4>
                    <input  type        =   'text'
                            id          =   'searchBox'
                            className   =   { "form-control form-control-sm" }
                            value       =   { props.searchTerm }
                            onChange    =   { props.updateSearchTermHandler }
                            placeholder =   'enter search text'/><br />

                    <div className={ 'text-center text-warning' }>
                        <i>{ ( props.searchResults !== '' ) ? props.searchResults.count : 0 } results to display</i>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SidebarHome;
import React from 'react'

const NewLearnerDocument = ( props ) =>{
        return(
                <div>

                    <div className={ 'input-group mb-3 mt-3' } style={{ width: '100%' }}>

                        <input  type        =   'text'
                                id          =   { props.refName }
                                className   =   { 'form-control' }
                                placeholder =   'document description' />

                        <div className      =   { 'input-group-append' }>
                            <button     className   =   { 'btn btn-outline-info' }
                                        type        =   'button'
                                        doctype     =   { props.doctype }
                                        inputref    =   { props.refName }
                                        modalid     =   { props.modalID }
                                        onClick     =   { props.SaveNewFilenameHandler }>
                                Save
                            </button>
                        </div>

                    </div>

                </div>
        )
}

export default NewLearnerDocument
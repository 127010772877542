import React from 'react'

const RowAddLearner = ( props ) =>{

    return(
        <div className={ 'card bg-dark border-white text-white mb-2' } >

            <div className={'container-fluid'}>
                <div className={'row p-1'}>
                    <div className={'col-sm-9'} style={{ fontSize: "1vw" }}>
                        <div>{ props.data.firstname + ' ' + props.data.lastname }</div>
                        { props.data.learnerID }
                    </div>

                    <div className={'col-sm-2 text-right'}>
                        <button className       =   { 'btn btn-dark btn-sm border-success text-success' }
                                style           =   {{ marginTop: '0px', scale: "0.7" }}
                                firstname       =   { props.data.firstname }
                                lastname        =   { props.data.lastname }
                                learnerid       =   { props.data.learnerID }
                                onClick         =   { props.AddLearnerToEventHandler } >

                            <b style={{ pointerEvents: "none" }}>&#43;</b>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RowAddLearner
import React from 'react';

import StatsBlock from '../Helpers/StatsBlock'

const BoxDash = ( props ) =>{

    return(
        <div className={ 'col-9 learnerContent text-white' }>
                <div className= { 'row border-warning' }>
                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Active Service Providers' statistic={ props.serviceProvidersCount } color='primary' />
                    </div>

                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Placeholder' statistic='0' color='primary' />
                    </div>

                </div>

                <div className= { 'row idzDark m-2' } style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}>
                    <div className={ 'col-12 text-center' }>
                        <h4>Search Results</h4>
                    </div>
                        {
                            ( props.searchResultsHtml !== '' )
                                ? <div className={"container"}>{ props.searchResultsHtml }</div>
                                : <div className={ 'col-sm-12 text-center text-warning' }>No search results to display</div>
                        }

                </div>
        </div>
    );

}

export default BoxDash;
import React from 'react';

/*

    title
    message
    actionMethod
    btnAction
    btnCancel
    data : null //this stuff will be spread and dynamically added to the action button as key->value pairs
*/

const HtmlModal = ( props ) =>{

    //console.log( JSON.stringify( props) )

    const closeModal = () =>{
        //console.log( "Closing modal" );
        document.querySelector( "#" + ( props.modal.UUID ) ).style.display = 'none'
    }

    //*** TTILE ***/
    const title = ( props.modal.title !== "" ) ? <h4 className={ 'card-title' }><strong>{ props.modal.title }</strong></h4> : ""

    //*** MESSAGE ***/
    const message = ( typeof( props.modal.message ) !== undefined )
                    ? <div className={ 'mt-3' }>{ props.modal.message }</div>
                    : ""

    //***  INSERT DATA ATTRIBUTES ON THE BUTTON DYNAMICALLY BY USING THE SPREAD OPERATOR ***/


    //*** ACTION BUTTON ***/
    const btnAction = ( props.modal.btnAction !== "" )
                    ? <div className={ 'mt-3' }>
                            <button     onClick     =   { ( ( typeof( props.modal.actionMethod ) !== undefined )
                                                            ? props.modal.actionMethod : null ) }
                                        onMouseUp   =   { closeModal }
                                        className   =   { 'btn btn-dark btn-sm border-info' }
                                        { ...props.modal.data } >
                                { props.modal.btnAction }
                            </button>
                      </div>
                    : ""

    //*** CANCEL BUTTON ***/
    const btnCancel = ( props.modal.btnCancel !== "" )
                    ? <div className={ 'mt-3' }>
                            <button onClick={ closeModal }
                                className={ 'btn btn-dark border-danger btn-sm' }>
                                { props.modal.btnCancel }
                            </button>
                        </div>
                    : ""

    return(
        <div id={ props.modal.UUID } key="sexy" className={ "modal" }>

              <div className={ "modal-content card border-info text-white bg-dark" }>
                    <div className={ 'card-body text-center' }>
                        <span className={ "close" } onClick={ closeModal }>&times;</span>
                        { title }
                        { message }
                        { btnAction }
                        { btnCancel }
                    </div>
              </div>

        </div>
    );
}

export default HtmlModal;
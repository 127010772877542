import React from 'react';

import StatsBlock from '../Helpers/StatsBlock'

const BoxDash = ( props ) =>{

    return(
        <div className={ 'col-9 learnerContent text-white' }>
                <div className= { 'row' }>
                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Interventions Count' statistic={ props.eventsCount } color='primary' />
                    </div>

                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Active Interventions' statistic={ props.activeEvents } color='primary' />
                    </div>

                </div>

                <div className= { 'row idzDark mt-2' }>
                    <div className={ 'col-12' }>
                        <div className={ 'text-center' }><h4>Search Results</h4></div>
                        {
                            ( props.searchEventsDisplay !== null )
                                ?   <div>
                                        { props.searchEventsDisplay}
                                    </div>

                                : <div className={ 'text-center text-warning' }>No search results to display</div>
                        }
                    </div>
                </div>
        </div>
    );

}

export default BoxDash;
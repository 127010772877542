import React from 'react';


const SidebarHome = ( props ) =>{

    //console.log( "USERS SIDEBAR HOME " + props )

    return(
        <div className={ 'col-sm-3'}>
            <div className={ 'idzDark p-2' }>
                {
                    ( props.fHasAccess( 3 ) )
                    ?   <div>
                            <button className   =   { "btn btn-dark btn-lg border-warning btn-sm btn-block" }
                                    onClick     =   { props.addNewUserHandler }
                                    style       =   {{ fontSize: '2vw' }} >
                                Add New User
                            </button>
                            <br />
                        </div>
                    : ""
                }


                <div className={ 'text-center' }>
                    {/* HEADING */}
                    <h4 className={ "text-white" } style={{ fontSize: '2vw' }}>Search for User</h4>

                    <div className={ "input-group" }>

                        <input type="text"
                                value           =   { props.searchTerm }
                                onChange        =   { props.UpdateSearchTermHandler }
                                className       =   { "form-control form-control-sm" }
                                placeholder     =   "Search for User" />

                        <span className={ "input-group-btn" }>

                                <button     className   =   { "btn btn-search btn-sm btn-outline-info" }
                                            type        =   "button"
                                            onClick     =   { props.getAdminUsers }>

                                    <img    src         =   { require( '../../icon/icon_magnifier.png') }
                                            className   =   { "tblIcon" }
                                            alt         =   'Magnifier icon'
                                            style       =   {{ pointerEvents: "none" }} />

                                </button>
                        </span>

                    </div>

                    <div className={ 'text-center text-white' }>
                        {/* PLACEHOLDER FOR SEARCH RESULT COUNT */}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SidebarHome;
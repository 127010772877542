import React, { Component } from 'react'
import Auxiliary from '../HOC/Auxiliary';
//import HtmlModal from '../Helpers/HtmlModal';
import Loading from '../Helpers/Loading'

class ManageStudents extends Component{

    constructor( props ){
        super( props )
        this.state = {

                box : "Dashboard",

                arrListOfActiveEvents: [],

                searchEventString   : '',
                searchLearnerString : '',

                selectedEventID     : 0,
                selectedEventName   : '',
                selectedEventDates  : [],

                learnersSearchFeedback : '',

                selectedLearnerID   :       '',
                selectedLearnerEnrolmentID: '',
                selectedLearnerName :       '',
                selectedLearnerComment:     '',
                selectedLearnerStatus:      '',
                selectedLearnerDates:       [],
                selectedLearnerSaving:      false,

                arrLearnersForEvent : [],

                modal: {
                    title       : '',
                    message     : '',
                    btnAction   : '',
                    actionMethod: null,
                    btnCancel   : '',
                    UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()
                }
        }

        this.SearchLearnerHandler       = this.SearchLearnersHandler.bind( this )

        this.SelectInterventionHandler  = this.SelectInterventionHandler.bind( this )

        this.SelectLearner              = this.SelectLearner.bind( this )
        this.ClearSelectedLearner       = this.ClearSelectedLearner.bind( this )
        this.UpdateLearnerInterventionStatus    =   this.UpdateLearnerInterventionStatus.bind( this )
        this.UpdateLearnerAttendanceForDate     =   this.UpdateLearnerAttendanceForDate.bind( this )
        this.UpdateLearnerComment       = this.UpdateLearnerComment.bind( this )
        this.UpdateLearnerEnrolment     = this.UpdateLearnerEnrolment.bind( this )
        // this.DashboardHandler       = this.DashboardHandler.bind( this )
        // this.CommunicationHandler   = this.CommunicationHandler.bind( this )
        // this.DocumentsHandler       = this.DocumentsHandler.bind( this )
        // this.TrainingHistoryHandler = this.TrainingHistoryHandler.bind( this )
        // this.InformationHandler     = this.InformationHandler.bind( this )
    }

    componentWillMount(){
        this.FetchActiveEventsForServiceProvider();
    }

    async FetchActiveEventsForServiceProvider(){
        const inputData =   {
            class: "serviceproviders",
            method: "fetchActiveEventsForServiceProvider",
            data: { serviceProviderID   : localStorage.entityID }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                //console.log( getDataResponse )

                if( !getDataResponse || getDataResponse.result !== "success" ){
                    //console.log( "User autehntication failed for the relevant service provider" );

                    console.log( "No data found" )
                } else{
                    console.log( "Found data" )
                    console.log( getDataResponse.data )

                    const arrEvents = this.state.arrListOfActiveEvents

                    getDataResponse.data.map( item =>{
                        //console.log( "mapping: " + JSON.stringify( item ) )
                        //console.log( "Array elements : " + arrEvents.push( item ) )
                        arrEvents.push( item )
                    } )

                    this.setState( { arrListOfActiveEvents : arrEvents }, ()=>{
                        console.log( "View mapped stated : " + JSON.stringify( this.state.arrListOfActiveEvents ) )
                    } )
                }
            } catch( err ){ console.log( err ) }
    }

    //search learners based on selected event
    async SearchLearnersHandler(){

        console.log( " Searching learners " );
        const inputData =   {
            class: "events",
            method: "returnLearnersForEvent",
            data: { eventID   : this.state.selectedEventID }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                //console.log( getDataResponse )

                if( !getDataResponse || getDataResponse.result !== "success" ){

                    this.setState( { learnersSearchFeedback : "No learners found for this event" } )

                } else{

                    const arrLearners = this.state.arrLearnersForEvent

                    getDataResponse.data.map( learner =>{
                        arrLearners.push( learner )
                    } )

                    this.setState( { arrLearnersForEvent : arrLearners }, ()=>{
                        console.log( "View mapped learners : " + JSON.stringify( this.state.arrLearnersForEvent ) )
                    } )
                }
            } catch( err ){ console.log( err ) }
    }

    SelectInterventionHandler( e ){
        //console.log( e.target.value )
        if( e.target.value !== "0" ){

            //find the event name
            if( this.state.arrListOfActiveEvents.length > 0 ){
                for( let i = 0; i < this.state.arrListOfActiveEvents.length; i++ ){
                    const event = this.state.arrListOfActiveEvents[i]

                    let tempState = this.state

                    if( event.eventID === e.target.value ){

                        tempState.selectedEventID       = event.eventID
                        tempState.selectedEventName     = event.eventName
                        tempState.selectedEventDates    = event.eventDates.split( ',' )

                        this.setState( { state : tempState } )
                    }
                }
            }

            const tempState = this.state
            tempState.selectedEventID       = e.target.value
            tempState.arrLearnersForEvent   = []
            this.setState( { state : tempState }, ()=>{ this.SearchLearnersHandler() } );
        }
    }

    SelectLearner( e ){
        // console.log( e.target.getAttribute( "data-learnerid" ) )
        // console.log( e.target.getAttribute( "data-eventid" ) )

        const tempState = this.state

        tempState.selectedLearnerID     = e.target.getAttribute( "data-learnerid" )
        tempState.selectedEventID       = e.target.getAttribute( "data-eventid" )
        tempState.selectedLearnerName   = e.target.getAttribute( "data-name" )
        tempState.selectedLearnerComment= ''
        tempState.selectedLearnerDates  = []
        tempState.selectedLearnerStatus = ''

        this.setState( { state: tempState }, ()=>{ this.FetchLearnerDataForCourse() } )
    }

    async FetchLearnerDataForCourse(){
        console.log( ` Fetching the learner's data ${ this.state.selectedLearnerID } : ${ this.state.selectedEventID }` );
        const inputData =   {
            class: "events",
            method: "returnLearnerDataForEvent",
            data: { learnerID : this.state.selectedLearnerID,
                    eventID   : this.state.selectedEventID }
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                //console.log( getDataResponse )

                let arrData = getDataResponse.data[0]

                console.log( arrData )

                let tempState = this.state

                tempState.selectedLearnerEnrolmentID    = arrData.id
                tempState.selectedLearnerStatus         = arrData.courseStatus
                tempState.selectedLearnerDates          = ( arrData.dateAttendance ) ? arrData.dateAttendance.split( ',' ) : []
                tempState.selectedLearnerComment        = arrData.comment

                //console.log ( tempState )
                this.setState( { state : tempState },
                    ()=>{
                        console.log(  `${ this.state.selectedLearnerStatus } -
                                        ${ this.state.selectedLearnerDates } -
                                        ${ this.state.selectedLearnerComment }` )
                    } )

            } catch( err ){ console.log( err ) }
    }

    UpdateLearnerInterventionStatus( e ){
        console.log( e.target.value )

        this.setState( { selectedLearnerStatus : e.target.value } )
    }

    UpdateLearnerAttendanceForDate( e ){
        //console.log( e.target );
        //console.log( e.target.getAttribute( "data-date" ) )

        const testDate = e.target.getAttribute( "data-date" )

        const arrLearnerDates = this.state.selectedLearnerDates

        if( e.target.checked ){
            //console.log( "checked" )
            if( arrLearnerDates.indexOf( testDate ) < 0 ){
                arrLearnerDates.push( testDate )        //add to array
            }
        } else{
            //console.log( "not checked" )
            if( arrLearnerDates.indexOf( testDate ) > -1 ){
                let arrIndex = arrLearnerDates.indexOf( testDate )
                arrLearnerDates.splice( arrIndex, 1 )   //remove from array
            }
        }

        //console.log( "Saved dates : " + arrLearnerDates )
        this.setState( { selectedLearnerDates : arrLearnerDates },
                                ()=>{ console.log( this.state.selectedLearnerDates ) } )
    }

    UpdateLearnerComment( e ){
        const comment = e.target.value;
        console.log( comment )

        this.setState( { selectedLearnerComment : comment }, ()=>{ console.log( this.state.selectedLearnerComment) } );
    }

    SaveButtonToLoading(){
        this.setState( { selectedLearnerSaving: true } )
    }

    async UpdateLearnerEnrolment( e ){

        this.SaveButtonToLoading()

        //Save new learner enrolment data to db
        const data = {
            enrolmentID : this.state.selectedLearnerEnrolmentID,
            learnerID   : this.state.selectedLearnerID,
            status      : this.state.selectedLearnerStatus,
            comment     : this.state.selectedLearnerComment,
            dates       : this.state.selectedLearnerDates.join()
        }

        const inputData =   {
            class: "events",
            method: "updateLearnerDataForEvent",
            data: data
            }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                console.log( getDataResponse )

                if( getDataResponse.result ===  "success" ){
                    console.log( "Save complete" )
                    this.setState( { selectedLearnerSaving: false } )
                } else{
                    console.log( "Save failed" )
                }

            } catch( err ){ console.log( err ) }
    }

    ClearSelectedLearner(){

        const tempState = this.state

            tempState.selectedLearnerID         = ''
            tempState.selectedLearnerName       = ''
            tempState.selectedLearnerDates      = []
            tempState.selectedLearnerComment    = ''

        this.setState( { state : tempState } )
    }

    render(){
        return(
            <Auxiliary>

                <div className={ 'container-fluid' }>
                    <div className='row'>

                        <div className='col-4 text-center'>
                            <div className='card border-primary bg-dark p-1'>
                                <h4 className='text-primary' style={{ fontSize: "3.5vh" }} >
                                        FILTER BY<br />
                                        <b className='text-white'>INTERVENTION</b></h4>

                                        <select  className  = 'form-control form-control-sm'
                                                 style      = {{ fontSize: "2vh" }}
                                                 onChange   = { this.SelectInterventionHandler } >
                                            <option value='0'>Select Intervention</option>
                                            {this.state.arrListOfActiveEvents.map((event) =>
                                                <option key={event.eventID} value={event.eventID}>{event.eventName}</option>)}
                                        </select>

                            </div>

                                <div className='clearfix p-3'></div>

                                <div    className = 'card border-success bg-dark p-1 text-white'
                                        id        = 'displayStudents'>
                                    {
                                        ( this.state.learnersSearchFeedback !== "" )
                                        ? <i> select a learner </i>
                                        : <i>...select a student...</i>
                                    }

                                    {
                                        ( this.state.arrLearnersForEvent !== '' )
                                        ? this.state.arrLearnersForEvent.map( learner =>(
                                            <button className='btn btn-dark btn-outline-success m-1'
                                                    key             =   { 'btnStudent' + learner.userID }
                                                    style           =   {{ fontSize: "1.2vw", whiteSpace: "normal" }}
                                                    data-learnerid  =   { learner.userID }
                                                    data-eventid    =   { this.state.selectedEventID }
                                                    data-name       =   { learner.lastname + ',' + learner.firstname }
                                                    onClick         =   { this.SelectLearner }>
                                                { learner.userID + ':' + learner.firstname + ' ' + learner.lastname}
                                            </button>
                                        ))
                                        : "No learners"
                                    }

                                </div>

                            </div>

                        <div className='col-8 text-center text-white' >
                            <div className={ 'container idzDark pb-3'}>

                                <h4 style={{ whiteSpace: "normal" }}>{ this.state.selectedEventName }</h4>

                                <div    className   =   {
                                                            ( this.state.selectedLearnerID )
                                                            ? 'card bg-dark border-warning p-2 text-left'
                                                            : 'hidden'
                                                        } >
                                    <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-12'>

                                                <button className   =   'btn btn-dark btn-outline-danger float-right'
                                                        style       =   {{ display: "inline-block" }}
                                                        onClick     =   { this.ClearSelectedLearner } >
                                                    X
                                                </button>

                                                    <h2 className='text-warning'>
                                                        { this.state.selectedLearnerName }
                                                    </h2>

                                                    <h4 className   =   'text-warning'
                                                        style       =   {{ display: "inline-block" }}>
                                                        Capture learner data for this event
                                                    </h4>

                                                </div>
                                            </div>

                                            <div    className   =   'row'
                                                    style       =   {{  borderBottom: "1px solid lightgray",
                                                                        padding: "5px" }}>

                                                <div className='col-6'>Confirm attendance</div>
                                                <div className='col-6'>

                                                    <table className='table table-sm'>
                                                        <tbody>
                                                        {
                                                            this.state.selectedEventDates.map( date =>(
                                                                <tr key={ date }>
                                                                    <td>
                                                                        { date }
                                                                    </td>
                                                                    <td>
                                                                        <input  type        =   'checkbox'
                                                                                checked     =  { this.state.selectedLearnerDates.indexOf( date ) > -1 }
                                                                                onChange    =   { this.UpdateLearnerAttendanceForDate }
                                                                                data-date   =  { date } />
                                                                    </td>
                                                                </tr>
                                                            ) )
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                            <div    className   =   'row'
                                                    style       =   {{  borderBottom: "1px solid lightgray",
                                                                        padding: "5px" }}>

                                                <div className='col-6'>Competency Level</div>
                                                <div className='col-6'>
                                                    <select className   =   'form-control'
                                                            value       =   { this.state.selectedLearnerStatus }
                                                            onChange    =   { this.UpdateLearnerInterventionStatus }>
                                                        <option value='2' >Not yet competent</option>
                                                        <option value='1' >Competent</option>
                                                        <option value='3' >Complete</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div    className   =   'row'
                                                    style       =   {{  borderBottom: "1px solid lightgray",
                                                                        padding: "5px" }}>

                                                <div className='col-6'>Comment</div>
                                                <div className='col-6'>
                                                    <textarea className = 'form-control'
                                                                onChange  = { this.UpdateLearnerComment }
                                                                value   = {  ( this.state.selectedLearnerComment )
                                                                                ? this.state.selectedLearnerComment
                                                                                : "" } ></textarea>
                                                </div>

                                            </div>

                                            <div    className   =   'row'
                                                    style       =   {{  borderBottom: "1px solid lightgray",
                                                                        padding: "5px" }}>

                                                <div className='col-12 text-center'>
                                                    {
                                                        ( this.state.selectedLearnerSaving )
                                                        ?   <Loading />
                                                        :   <button className   =   'btn btn-dark btn-outline-warning'
                                                                    onClick     =   { this.UpdateLearnerEnrolment }>
                                                                SAVE LEARNER DATA
                                                            </button>
                                                    }

                                                </div>

                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

export default ManageStudents
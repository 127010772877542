import React from 'react';

const SystemRightRow = ( props ) =>{

    //console.log( props.data )

    return(
        <tr style={{ fontSize: "0.9vw" }}>
            <td className={ 'text-white' }>
                - &nbsp; &nbsp;
            </td>
            <td className={ 'text-white' }>{ props.data.name } ({ props.data.id })</td>
        </tr>
    );

}

export default SystemRightRow;
import React from 'react'

import Label from '../Helpers/Label'

const UserLinkSystemRole = ( props ) => {

    return(
        <div>
            <table>

                <tbody>
                    <tr>
                        <td>
                            <input  type        =   'checkbox'
                                    userid      =   { props.userID }
                                    roleid      =   { props.roleID }
                                    style       =   {{ marginTop: '20px' }}
                                    defaultChecked     =   { ( props.selected === 'selected' ) ? true : false  }
                                    onMouseUp   =   { props.CheckboxLinkHandler } />
                        </td>
                        <td className={ 'pl-2' }>
                            <Label text={ props.name } />
                        </td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}

export default UserLinkSystemRole
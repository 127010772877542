import React from 'react'

const RowSearchCourse =( props ) =>{

    return(
        <div     className               =   { 'btn btn-success btn-outline-success btn-sm btn-block text-white' }
                    style                   =   {{ fontSize: "1.0vw", whiteSpace: "normal" }}
                    courseid                =   { props.data.courseID }
                    serviceproviderid       =   { props.data.serviceProviderID }
                    coursename              =   { props.data.courseName }
                    serviceprovidername     =   { props.data.serviceProviderName }
                    onClick                 =   { props.SelectCourseHandler }>
            { props.data.courseName }<br />
            <span className={ 'text-warning' } style={{ fontSize: "0.9vw" }}>{ props.data.serviceProviderName }</span>
        </div>
    )
}

export default RowSearchCourse
import React, { Component } from 'react';

import Feedback from '../Helpers/Feedback';

class Login extends Component{

    constructor( props ){
        super();
        this.state = {      email   : '',
                            password: '',
                            feedback: ''
        }
    }

    updateLoginState( event ){
        event.preventDefault();
        const name = event.target.name;

        this.setState( {    [name] : event.target.value,
                            feedback : "" } );
    }

    async attemptLogin(){
        const inputData =   {
                                class: "login",
                                method: "",
                                data: {
                                        email       : this.state.email,
                                        password    : this.state.password
                                }
        }

            try{
                //console.log("Login Component request data");
                const getDataResponse = await this.props.getData( inputData, function( response){
                        //console.log( "Async complete" )
                        return response  } )
                //console.log( "LC" + getDataResponse.result
                this.setState( { feedback : getDataResponse.result } );

                if( getDataResponse.result === "success" ){
                    //console.log( "logging in userID : " + getDataResponse.userID )
                    this.props.setLoggedInStatus(   getDataResponse.sessionToken,
                                                    getDataResponse.userID,
                                                    getDataResponse.accessRights,
                                                    getDataResponse.firstname,
                                                    getDataResponse.lastname,
                                                    getDataResponse.type,
                                                    getDataResponse.entityID )
                }

            } catch( err ){
                console.log( err );
            }
    }

    render(){

        return(
                    <div className={ 'container vertical-center' } >
                        <div className={ "row" } >

                            <div className={ "col-4 mx-auto text-center text-white  p-3" } >
                                {/* <h1>I D Z</h1> */}

                                <img src={ require('../../icon/idz_logo_sml.png') }  alt='Test' />

                                <h5 className={ "text text-info"} >Industrial Development Zone</h5>

                                <div className={ "idzDark p-3" } style={{ borderTop: "6px solid #17a2b8" }} >

                                                        <h2>Sign In</h2>
                                                        <br />

                                                        <div className={ "form-group" } >
                                                                <input  type        =   'text'
                                                                    placeholder =   'email address'
                                                                    name          =   'email'
                                                                    className   =   { 'form-control col-sm-8 mx-auto' }
                                                                    value       =   { this.state.email }
                                                                    onChange    =   { this.updateLoginState.bind(this) }/>
                                                        </div>

                                                        <div className={ "form-group" } >
                                                                <input  type        =   'password'
                                                                    placeholder =   'password'
                                                                    name          =   'password'
                                                                    className   =   { 'form-control col-sm-8 mx-auto' }
                                                                    value       =   { this.state.password }
                                                                    onChange    =   { this.updateLoginState.bind(this) } />
                                                        </div>

                                                        <br />

                                                        <button type='button'
                                                            className={ 'btn btn-dark mx-auto m-2 border-info' }
                                                            // onClick={ this.props.setLoggedInStatus }
                                                            onClick={ this.attemptLogin.bind( this ) }>
                                                        LOGIN
                                                        </button>
                                                        <br />
                                                        <Feedback  message={ this.state.feedback } />

                                        <button     type        =   "button"
                                                    className   =   { "btn btn-outline-info btn-block mt-3" }
                                                    onClick     =   { this.props.ResetPassword }  >
                                            Forgot password
                                        </button>

                                </div>


                        </div>

                    </div>
            </div>
        );
    }
}

export default Login;
import React from 'react'


const Information = ( props ) =>{

    let data = props.spData

    return(

        <div className={'container-fluid'}>
            <div className='row'>
                <div className='col-12 text-white bg-dark p-2 rounded'>

                    <div className={'border border-white rounded p-2 mb-2'}>
                        <h2 className={"text-white"}>Company details</h2>
                        <table className={'table table-sm table-borderless'}>
                            <tbody>
                                <tr><th>Entity Name</th><td>{ data.entityName }</td></tr>
                                <tr><th>Trading Name</th><td>{ data.tradingName }</td></tr>
                                <tr><th>Registration Number</th><td>{ data.registrationNumber }</td></tr>
                                <tr><th>Status</th><td>{ data.status }</td></tr>
                                <tr><th>SDL Number</th><td>{ data.sdlNumber }</td></tr>
                                <tr><th>SIC Code</th><td>{ data.sicCode }</td></tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={"border border-info rounded"}>
                        <h2 className={"text-info"}>Contact Person</h2>
                        <table  className   =   {'table table-sm table-borderless'}
                                style       =   {{ borderRadius: "0.2em" }}>
                            <tbody>
                                <tr><th>Firstname</th><td>{ data.contactFirstname }</td></tr>
                                <tr><th>Surname</th><td>{ data.contactLastname }</td></tr>
                                <tr><th>Email</th><td>{ data.email }</td></tr>
                                <tr><th>Cellphone</th><td>{ data.mobileNumber }</td></tr>
                                <tr><th>Work Number</th><td>{ data.workNumber }</td></tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Information
import React from 'react';

const SystemRoleRow = ( props ) =>{

    //console.log( props.data )

    return(
        <tr>
            <td>
                <button     onClick     =   { props.LinkRightsHandler }
                            data-roleid =   { props.data.id }
                            data-rolename   = { props.data.name }
                            className   =   { 'btn btn-secondary btn-sm btn-outline-warning' } >LINK ACCESS RIGHTS</button>
            </td>
            <td className={ 'text-white' }>{ props.data.name }</td>
        </tr>
    );

}

export default SystemRoleRow;
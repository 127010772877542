import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Filters from './Filters'
import Search from './Search'
import ListRecipients from './ListRecipients'
import Message from './Message'
import RowSearchResult from './RowSearchResult';
import RowRecipient from './RowRecipient'

import HtmlModal from '../Helpers/HtmlModal'

class Communication extends Component {

    constructor( props ){
        super( )
        this.state = {
            searchTerm      : '',
            searchResults   : '',
            listRecipients  : [],

            recipientsHTML  : '',

            filters: {
                        events          : false,
                        serviceProviders: false,
                        learners        : true,
                        users           : false
            },
            messageReady    : false,
            message         : "",

            loadingSearch   : false,

            modal:  {   title       :   '',
                        message     :   '',
                        btnAction   :   '',
                        actionMethod:   null,
                        btnCancel   :   '',
                        UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                        data            :   {} }
        };

        this.checkMessageReadyHandler   = this.checkMessageReadyHandler.bind( this )
        this.formFieldUpdate            = this.formFieldUpdate.bind( this )
        this.updateFilters              = this.updateFilters.bind( this )
        this.searchTermHandler          = this.searchTermHandler.bind( this )
        this.doSearchHandler            = this.doSearchHandler.bind( this )
        this.recipientHandler           = this.recipientHandler.bind( this )
        this.sendMessageHandler         = this.sendMessageHandler.bind( this )

        //console.log( "UserID in Communicator : " + props.userID )
    }

    //Just the message state
    checkMessageReadyHandler(){
        if( this.state.message.length > 0 && this.state.listRecipients.length > 0 ){
            this.setState( { messageReady:  true } );
        } else{
            this.setState( { messageReady:  false } );
        }
    }

    sendMessageHandler( e ){
        //console.log( this.state.message )

        if( this.state.messageReady ){
            console.log("Ready to send message")

            const tempModal = this.state.modal

            tempModal.title     =   "SYSTEM CONFIRMATION"
            tempModal.message   =   "Do you really want to send the message now?"
            tempModal.btnAction =   "YES, send message now"
            tempModal.actionMethod  =   this.storeMessageSendHandler.bind( this )
            tempModal.btnCancel =   "Cancel"

            this.setState(  {   modal   :   tempModal } )

            document.querySelector("#" + this.state.modal.UUID).style.display = "block"
        } else{
            console.log( "Not ready to send" )
        }
    }

    //This is the final command to store the message and clear the screen
    async storeMessageSendHandler( e ){

        //console.log( "Saving messageing list : " + JSON.stringify( this.state.listRecipients ) )

        const inputData =   {   class   : "messages",
                                method  : "sendMessage",
                                data    : { sender          : this.props.userID,
                                            listRecipients  : this.state.listRecipients,
                                            message         : this.state.message  } }

        try{ const getDataResponse = await this.props.getData(  inputData,
                                                                function( response){ return response  } )

            if( getDataResponse.result === "success" ){
                //***  CLEAR MESSAGE SETTINGS ***//
                this.setState( {
                                searchTerm      : '',
                                searchResults   : '',
                                listRecipients  : [],
                                recipientsHTML  : '',
                                filters: {
                                            events          : false,
                                            serviceProviders: false,
                                            learners        : true,
                                            users           : false
                                },
                                messageReady    : false,
                                message         : "",
                                loadingSearch   : false

                } )

                //Confirmation modal popup
                const tempModal = this.state.modal

                tempModal.title     =   "SYSTEM CONFIRMATION"
                tempModal.message   =   "Your message was successfully sent"
                tempModal.btnAction =   "OK"
                tempModal.actionMethod  =   null
                tempModal.btnCancel =   ""

                this.setState(  {   modal   :   tempModal } )

                document.querySelector("#" + this.state.modal.UUID).style.display = "block"
            }

        } catch( err ){ console.log( err ) }
    }


    /* DD RECIPIENT LIST */
    /*
            This method handles both the add and delete of profiles to the list of recipients

    */
    recipientHandler( e ){
        const myList = this.state.listRecipients
        //get the action from the button
        const action = e.target.getAttribute( "action" )
        //setup the profile to add it to the array of recipients
        let obj = {};
        if( action === "add" ){
                    obj = { "profileid"     :   e.target.getAttribute( "profileid" ),
                            "profilename"   :   e.target.getAttribute( "profilename" ),
                            "email"         :   e.target.getAttribute( "email" ),
                            "type"          :   e.target.getAttribute( "type" ) }
        }
       // console.log( JSON.stringify( arr  ) )

        /* If we need to add the profile to the list of recipients */
        let canAdd = true

        for( let i = 0; i < myList.length; i ++ ){
            //console.log( "XYX" + this.state.listRecipients[i].profileid )
            //if we find a match with any of the existing profiles we simply set the flag to false

            //***    WHILE WE ARE LOOPING THROUGH THE ARRAY OF OBJECTS, REMOVE THE ITEM IF THAT WAS THE COMMAND    ***/

            if( e.target.getAttribute( "profileid" ) === myList[i].profileid ) {
                canAdd = false

                if( action === "remove" ){
                    myList.splice( i, 1)
                }
            }

        }

        //Must run through the whole list before we can know if the element can be added to the array
        if( canAdd && action === "add" ){
            myList.push( obj )
            this.checkMessageReadyHandler();
        }

        /*  Update the list of recipients in state */
        this.setState( { listRecipients : myList }, this.createRecipientsDisplay() )
    }

    createRecipientsDisplay(){

        //console.log( "createRecipientsDisplay : " + JSON.stringify( this.state.listRecipients ) )

        let html = ""

        if( this.state.listRecipients.length > 0 ){
            html = this.state.listRecipients.map( (row) =>
                                <RowRecipient   data                =   { row }
                                                key                 =   { row.profileid }
                                                recipientHandler    =   { this.recipientHandler } />
                            )

            this.setState( { recipientsHTML : html } )
        } else{
            this.setState( { recipientsHTML : '' } )
        }
    }

    /* READ SEARCH CHARACTER FROM INPUT FIELD */
    searchTermHandler( e ){
        //console.log( e.target.name + " : " + e.target.value )
        this.setState( { searchTerm: e.target.value } )
    }

    updateFilters( e ){
        //shallow copy
        const filters               = this.state.filters
        filters[ e.target.name ]    = e.target.checked
        this.setState( { filters },
            ()=>{ console.log( JSON.stringify( this.state.filters ) )
        } )
    }

    formFieldUpdate( e ){
        const name = e.target.name;
        //console.log( e.target.value )
        let newState        = this.state
        newState[ name ]    = e.target.value
        this.setState( { newState } )

        if( name === "message" ){
            this.checkMessageReadyHandler()
        }
    }

    //***  ACTUALLY DO THE SEARCH ***//
    async doSearchHandler(){
        this.setState( { loadingSearch : true } )

        if( this.state.searchTerm.length === 0 ){ return }

        const inputData =   {   class   : "search",
                                method  : "communicatorSearch",
                                data    : { filters         : this.state.filters,
                                            searchTerm      : this.state.searchTerm  } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( { loadingSearch : false } )

                    if( getDataResponse.data !== undefined ){
                        let html = getDataResponse.data.map( ( row )=>
                                    <RowSearchResult    key                 =   { row.id }
                                                        data                =   { row }
                                                        recipientHandler    =   { this.recipientHandler } />
                          )

                        this.setState( { searchResults : html } )
                    }
                } else{
                    this.setState( { loadingSearch : false } )
                    this.setState( { searchResults : '' } )
                }

            } catch( err ){ console.log( err ) }
    }

    render(){


        return(
                <div className={ 'col-sm-12 text-white' } >

                    <div className={ 'container' } >

                        {/*
                            //message is ready when recipients more than one and message.length > 0
                            ( this.state.messageReady )
                            ?   <div className={ 'row' }>
                                    <div className={ 'col-12 p-2' }>

                                        <button className={ 'btn btn-dark btn-sm border-warning float-right m-1' } >
                                            SEND
                                        </button>

                                        <button className={ 'btn btn-dark btn-sm border-warning float-right m-1' } >
                                            CANCEL
                                        </button>
                                    </div>
                                </div>
                            : ""
                        */}
                        <div className={ 'row' }>

                            {/* LEFT COLUMN */}
                            <div className={ 'col-sm-6' }>

                                    <div className={ 'idzDark p-2' } style={{ display: "none" }}>
                                        <Filters    filters                 =   { this.state.filters }
                                                    updateFilters           =   { this.updateFilters }
                                                    checkboxClickHandler    =   { this.checkboxClickHandler } />
                                    </div>

                                    <div className={ 'idzDark p-2' } >
                                        <Search     filters             =   { this.state.filters }
                                                    searchResults       =   { this.state.searchResults }
                                                    loadingSearch       =   { this.state.loadingSearch }

                                                    searchTermHandler   =   { this.searchTermHandler }
                                                    doSearchHandler     =   { this.doSearchHandler } />
                                    </div>
                            </div>

                            {/* RIGHT COLUMN */}
                            <div className={ 'col-sm-6' }>

                                    <div className={ 'idzDark p-2' } >
                                        <ListRecipients recipientsHTML  =   { this.state.recipientsHTML }
                                                        listRecipients  =   { this.state.listRecipients }/>
                                    </div>

                                    <div className={ 'idzDark p-2 mt-3' } >
                                        <Message    formFieldUpdate     =   { this.formFieldUpdate }
                                                    sendMessageHandler  =   { this.sendMessageHandler }
                                                    message             =   { this.state.message } />
                                    </div>
                            </div>

                        </div>

                        <HtmlModal modal={ this.state.modal } />

                    </div>
            </div>
        );
    }


}

export default Communication;
import React from 'react'


const Information = ( props ) =>{


    return(

        <div className={'container-fluid p-1 text-white'}>

            <div className={'row bg-dark rounded'}>

                <div className='col-12'>
                    <h4>My profile information</h4>

                    <div className={'border border-white rounded p-2'}>

                        <table className={'table table-sm table-borderless'}>

                            <tbody>
                                <tr>
                                    <th>Firstname</th>
                                    <td>{ props.learnerData.firstname }</td>
                                </tr>

                                <tr>
                                    <th>Lastname</th>
                                    <td>{ props.learnerData.lastname }</td>
                                </tr>

                                <tr>
                                    <th>Email</th>
                                    <td>{ props.learnerData.email }</td>
                                </tr>

                                <tr>
                                    <th>Cellphone</th>
                                    <td>{ props.learnerData.mobile1 }</td>
                                </tr>

                                <tr>
                                    <th>South African ID nr.</th>
                                    <td>{ props.learnerData.idNumber }</td>
                                </tr>

                                <tr>
                                    <th>Birthdate</th>
                                    <td>{ props.learnerData.birthDate }</td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Information
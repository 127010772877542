import React from 'react'

const RowRemoveLearner = ( props ) =>{

    //console.log( "RowRemoveLearner : " + JSON.stringify( props.data ) )

    return( <div    className   =   {'container-fluid m-1'}
                    style       =   {{ border: "1px solid rgba( 255, 255, 255, 0.5 )", borderRadius: "0.3em" }}>
                <div className={'row p-1'}>
                    <div className={'col-sm-9'} style={{ fontSize: "1vw" }}>
                        <div>{ props.data.firstname + ' ' + props.data.lastname }</div>
                        { props.data.id }
                    </div>

                    <div className={'col-sm-2 text-right'}>
                        <button className       =   { 'btn btn-dark btn-sm border-danger text-success' }
                                style           =   {{ marginTop: '0px', scale: "0.6" }}
                                firstname       =   { props.data.firstname }
                                lastname        =   { props.data.lastname }
                                learnerid       =   { props.data.learnerID }
                                onClick         =   { props.RemoveAllocatedLearnerHandler } >

                            <img    src     =   { require( '../../icon/icon_delete.png' ) }
                                    style   =   {{ pointerEvents: "none", height: "12px" }}
                                    alt     =   "Remove icon" />
                        </button>
                    </div>
                </div>
            </div>
    )
}

export default RowRemoveLearner
import React, {Component} from 'react';

import Loading from '../Helpers/Loading'
import SystemRoleRow from './SystemRoleRow'

class RolesList extends Component{

    constructor( props ){
        super( props )
        this.state = {
                        listOfRoles : null,
                        loading     : false
        }
    }

    //***  GET SYSTEM ROLES FOR DISPLAY  ***/
    async getSystemRoles(){

        this.setState( { loading : true } )

        const inputData =   {
                                class: "access",
                                method: "getAllRoles",
                                data : ''
        }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        //console.log( "Async complete" )
                        return response  } )
                //console.log( "LC" + getDataResponse.result )
                this.setState( { feedback : getDataResponse.result } );

                if( getDataResponse.result === "success" ){
                    //console.log( JSON.stringify( getDataResponse.data ) )
                    this.setState( {    loading : false,
                                        listOfRoles : getDataResponse.data.map( ( row ) =>
                                            <SystemRoleRow  LinkRightsHandler       =   { this.props.LinkRightsHandler }
                                                            showRolesListHandler    =   { this.props.showRolesListHandler }
                                                            data={ row }
                                                            key={ row.id } />
                                        ) } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    componentDidMount(){
        this.getSystemRoles();
    }

    render(){

        let loading = ( this.state.loading ) ? <Loading /> : "";

        return(
            <div className={ 'section' }>
                <div className= { 'row' }>
                    <div className={ 'col-md-12' }>

                        <table>
                            <tbody>
                                <tr>
                                    <td><h4 className={ 'text-warning' }>List of Roles</h4></td>
                                    <td className={ 'pl-4' }>
                                            <button className={ 'btn btn-sm btn-success btn-outline-success float-right' }
                                                    onClick={ this.props.UpdateSystemRoleHandler }>
                                                ADD SYSTEM ROLE
                                            </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                            { loading }     {/*  LOADING ICON */}

                            <table className={ 'table table-striped table-sm mt-2' }>
                                <tbody>
                                    { this.state.listOfRoles }
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default RolesList;
import React, { Component } from 'react';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

//***  SIDEBAR COMPONENTS  ***//
import SidebarHome from './SidebarHome';
import SidebarBlank from './SidebarBlank';
import SidebarViewLearner from './SidebarViewLearner';

//***  CONTENT COMPONENTS  ***//
import BoxDash from './BoxDash';
import BoxLearnerData from './BoxLearnerData';
import BoxLearnerDocuments from './BoxLearnerDocuments';
import Employer from './Employer';
import TrainingHistory from './TrainingHistory'
import LearnerMessages from './LearnerMessages'

import HtmlModal from '../Helpers/HtmlModal'
import RowLearner from './RowLearner'

import DownloadCSV from '../CSV/DownloadCSV'

class Learners extends Component {

    constructor( props ){
        super( props );

        const cookies = new Cookies();

            this.state = {
                sidebar   : props.sidebar,
                box       : props.box,
                cookies   : cookies,

                //stats
                learnersCount : '...loading...',
                activeLearners: '...loading...',

                editLearnerID : '',
                messagesCount : 0,

                downloadBox : [],

                profileStatus : '',

                searchTerm    : '',

                searchResults : {   count   : 0,
                                    data    : '' },
                displaySearchResults : null,

                modal:  {   title       :      '',
                            message     :    '',
                            btnAction   :  '',
                            btnCancel   :   '',
                            UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                            actionMethod:   null,
                            data        :   null }
            }

            this.ClickToEditLearnerHandler          =   this.ClickToEditLearnerHandler.bind( this )
            this.LearnerPersonalDataEditHandler     =   this.LearnerPersonalDataEditHandler.bind( this )
            this.SearchLearners                     =   this.SearchLearners.bind( this )
            this.CreateNewLearnerSetEditLearnerID   =   this.CreateNewLearnerSetEditLearnerID.bind( this )
            this.DisplayLearnerProfileStatusHandler =   this.DisplayLearnerProfileStatusHandler.bind( this )
            this.deleteLearnerProfile               =   this.deleteLearnerProfile.bind( this )
            this.updateSearchTermHandler            =   this.updateSearchTermHandler.bind( this )
            this.ViewMessagesHandler                =   this.ViewMessagesHandler.bind( this )
            this.FDownloadCSV                       =   this.FDownloadCSV.bind( this )
            this.HideDownloadBox                    =   this.HideDownloadBox.bind( this )
            this.ConfirmMakeLearnerActive           =   this.ConfirmMakeLearnerActive.bind( this )
            this.makeProfileActive                  =   this.makeProfileActive.bind( this )

            //console.log( "LEARNERS.js " + JSON.stringify( this.props ) )
    }

    async FDownloadCSV( e ){
        console.log( "Called download csv" )

        const filename  = e.target.getAttribute( "filename" )

        const inputData =   {   class: "learners",
                                method: "exportActiveLearners",
                                data: { data : null } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                if( getDataResponse.result === "success" ){
                    //Prepare the CSV data
                    //headers first
                        const headerData    = getDataResponse.data.headers
                        let headers         = []

                        for( let i = 0; i < headerData.length; i ++ ){
                            headers.push( { label: headerData[i], key: headerData[i]  } )
                        }

                    //body data
                    //console.log( "Found " + getDataResponse.data.data.length + " learners" )

                        let data = []

                        for( let x = 0; x < getDataResponse.data.data.length; x++ ){
                            data.push( getDataResponse.data.data[x] )
                            //console.log( JSON.stringify( getDataResponse.data.data[x] ) )
                        }

                        this.setState({  downloadBox :  <DownloadCSV    filename    =   { filename }
                                                                        headers     =   { headers }
                                                                        data        =   { data }
                                                                        hide        =   { this.HideDownloadBox } /> } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    HideDownloadBox(){
        this.setState( {    downloadBox : [] } )
    }

    ConfirmMakeLearnerActive(){
        const modal = this.state.modal

        modal.title         = 'System Confirmation'
        modal.message       = 'Do you wish to make this learner profile active?'
        modal.btnAction     = 'Yes, make active'
        modal.actionMethod  = this.makeProfileActive
        modal.btnCancel     = 'Cancel'

        this.setState( { modal: modal }, ()=>{
            document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
        })
    }



    //Get the number of active learners ( STATS )
    async makeProfileActive(){

        this.setState( { loading : true } );

        const inputData =   {   class: "learners",
                                method: "makeProfileActive",
                                data: { learnerID: this.state.editLearnerID } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                if( getDataResponse.result === "success" ){
                    const modal = this.state.modal

                    modal.title         = 'System Confirmation'
                    modal.message       = 'The learner profile has been activated'
                    modal.btnAction     = 'OK'
                    modal.actionMethod  = this.RefreshLearnerProfileAfterMakeActive()
                    modal.btnCancel     = ''

                    this.setState( { modal: modal }, ()=>{
                        document.querySelector( '#' + this.state.modal.UUID ).style.display = 'block'
                    })
                }

            } catch( err ){
                console.log( err );
            }
    }

    RefreshLearnerProfileAfterMakeActive(){
            this.setState( {    profileStatus         : "active" } )
    }

    //Get the number of active learners ( STATS )
    async getActiveLearnerData(){

        this.setState( { loading : true } );

        const inputData =   {   class: "learners",
                                method: "returnHomePageStats",
                                data: { data: null } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                        return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( {    learnersCount   :   getDataResponse.data.learnersCount,
                                        activeLearners  :   getDataResponse.data.activeLearners } )
                }

            } catch( err ){
                console.log( err );
            }
    }

    //Get the number of active learners
    async deleteLearnerProfile( e ){
        const learnerID = e.target.getAttribute( "learnerid" )

        /* STEP 1 CHECK THAT CONFIRM ATTRIBUTE IS NOT SET  */
        if( !e.target.getAttribute( "confirm" ) ){
            //Popup Learner delete confirmation
            const modal = this.state.modal
            modal["title"]          =   "SYSTEM CONFIRMATION"
            modal["message"]        =   "Do you really want to delete the learner?"
            modal["btnAction"]      =   "Yes, delete this learner profile"
            modal["actionMethod"]   =   this.deleteLearnerProfile
            modal["btnCancel"]      =   "Cancel"
            modal["data"]           =   {       learnerid   :  learnerID,
                                                confirm     :  "true" }

            this.setState( { modal } )

            //Make the modal visible
            document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"

            return
        }
        /* IF CONFIRM IS SET, THEN ACTUALLY DELETE LEARNER  */
        else{
            const inputData =   {   class: "learners",
                                    method: "deleteLearnerProfile",
                                    data: { learnerID : learnerID } }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){
                    return response  } )

                if( getDataResponse.result === "success" ){
                    this.SearchLearners()
                }

            } catch( err ){ console.log( err ) }

        }
    }

    updateSearchTermHandler( e ){
        const searchTerm = e.target.value;
        //console.log( "Found entered search text : " + searchTerm )
        this.setState( { searchTerm : searchTerm }, () => {
            this.SearchLearners();
        } )
    }

    //Search learners
    async SearchLearners(){
        if( this.state.searchTerm.length === 0 ){
            this.setState( {    searchResults : {   count: 0,
                                                    data: '' },
                                displaySearchResults : null } )
            return false        //exit the method
        }

        this.setState( { loading : true } );

        const inputData =   {
                                class: "learners",
                                method: "searchLearners",
                                data: { searchTerm: this.state.searchTerm }
        }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){

                    if( getDataResponse.data ){

                        //shallow copy
                        const sResults      = this.state.searchResults

                        sResults["count"]   = getDataResponse.count
                        sResults["data"]    = getDataResponse.data

                        this.setState( {    sResults ,
                                            displaySearchResults : getDataResponse.data.map( ( row ) =>
                                                        <RowLearner loadUserViewHandler         ={ this.loadUserViewHandler }
                                                                    data                        ={ row }
                                                                    key                         ={ row.id }
                                                                    deleteLearnerProfile        = { this.deleteLearnerProfile }
                                                                    ClickToEditLearnerHandler   = { this.ClickToEditLearnerHandler } /> ) } )

                    } else{
                    }
                }

            } catch( err ){ console.log( err ) }
    }

    componentDidMount(){  /* get the stats */ this.getActiveLearnerData() }

    //After BoxLearnerData fetches the learner's data it calls this function to update the learner's profile status
    DisplayLearnerProfileStatusHandler( profileStatus ){
        this.setState( { profileStatus : profileStatus } )
    }

    LoadDashHandler = () => {
        this.setState( { sidebar : "SidebarHome", box: "BoxDash" } );
    }

    //From "Create Learner" button
    CreateLearnerHandler = ( e ) => {
        this.setState( {    sidebar         : "SidebarBlank",
                            box             : "BoxLearnerData" ,
                            editLearnerID   : "",
                            profileStatus   : "...creating..."  } )
    }

    //Update state : editLearnerID after creating new learner
    CreateNewLearnerSetEditLearnerID( learnerID, profileStatus ){
        this.setState( {    editLearnerID : learnerID,
                            profileStatus : profileStatus } )
        this.LearnerPersonalDataEditHandler()
    }

    //From any dialogue that allows one to select a learner to edit
    ClickToEditLearnerHandler( e ){
        const learnerID =  e.target.getAttribute( "learnerid" )

        this.setState( {    sidebar         : "SidebarViewLearner",
                            box             : "BoxLearnerData",
                            editLearnerID   : learnerID,
                            profileStatus   : <i className={ 'text-white' }>profile status loading</i> } );

        this.LearnerPersonalDataEditHandler()
    }

    ViewMessagesHandler(){
        this.setState( { box: "LearnerMessages" } )
    }

    //*********  LOAD DIFFERENT BOXES  *********/
        //Any button on the SidebarHome panel allows editing of the profile
        LearnerPersonalDataEditHandler(){ this.setState( {      sidebar     : "SidebarViewLearner",
                                                                box         : "BoxLearnerData" } ) }


        LoadViewLearnerDocuments = () => { this.setState( {    sidebar  : "SidebarViewLearner",
                                                                box     : "BoxLearnerDocuments" } ) }

        LoadEmployerDetails = () => {   this.setState( {    sidebar     : "SidebarViewLearner",
                                                            box         : "Employer" } ) }

        LoadTrainingHistory = () => { this.setState( { sidebar : "SidebarViewLearner", box: "TrainingHistory" } ) }
    //******************************************/

    render(){

        var componentList = {
            "SidebarHome"       : SidebarHome,
            "SidebarBlank"      : SidebarBlank,
            "SidebarViewLearner": SidebarViewLearner,

            "BoxDash"           : BoxDash,
            "BoxLearnerData"    : BoxLearnerData,
            "BoxLearnerDocuments" : BoxLearnerDocuments,
            "Employer"          : Employer,
            "TrainingHistory"   : TrainingHistory,
            "LearnerMessages"   : LearnerMessages
        };

        //GET THE NAME OF THE EXPECTED SIDEBAR AND BOX
        const componentSidebar  = this.state.sidebar;
        const componentBox      = this.state.box;

        //RETURN A HANDLE TO THE COMPONENT
        const DynSidebar    = componentList[  componentSidebar ];
        const DynBox        = componentList[  componentBox ];

        return(
                <div className={ 'section' } >
                    <div className={ 'row' }>

                    { /* SIDEBAR */ }       <DynSidebar
                                                        EditLearnerHandler      = { this.EditLearnerHandler }
                                                        LoadViewLearnerDocuments= { this.LoadViewLearnerDocuments }
                                                        LearnerPersonalDataEditHandler  ={ this.LearnerPersonalDataEditHandler }
                                                        LoadEmployerDetails     = { this.LoadEmployerDetails }
                                                        LoadTrainingHistory     = { this.LoadTrainingHistory }
                                                        CreateLearnerHandler    = { this.CreateLearnerHandler}
                                                        updateSearchTermHandler = { this.updateSearchTermHandler }
                                                        ViewMessagesHandler     = { this.ViewMessagesHandler }
                                                        fHasAccess              = { this.props.fHasAccess }
                                                        ConfirmMakeLearnerActive= { this.ConfirmMakeLearnerActive }
                                                        makeProfileActive       = { this.makeProfileActive }

                                                        editLearnerID           = { this.state.editLearnerID }
                                                        searchResults           = { this.state.searchResults }
                                                        profileStatus           = { this.state.profileStatus }
                                                        searchTerm              = { this.searchTerm }
                                                        userAccessRights        = { this.userAccessRights } />

                    { /* BOX CONTENT */ }   <DynBox
                                                        LoadDashHandler                 ={ this.LoadDashHandler }
                                                        LearnerPersonalDataEditHandler  ={ this.LearnerPersonalDataEditHandler }
                                                        ClickToEditLearnerHandler       ={ this.ClickToEditLearnerHandler }
                                                        LoadViewLearnerDocuments        ={ this.LoadViewLearnerDocuments }
                                                        EditLearnerHandler              ={ this.EditLearnerHandler }
                                                        getData                         ={ this.props.getData }
                                                        learnersCount                   ={ this.state.learnersCount }
                                                        CreateNewLearnerSetEditLearnerID={ this.CreateNewLearnerSetEditLearnerID }
                                                        DisplayLearnerProfileStatusHandler={ this.DisplayLearnerProfileStatusHandler }
                                                        deleteLearnerProfile            = { this.deleteLearnerProfile }
                                                        fHasAccess                      = { this.props.fHasAccess }

                                                        FDownloadCSV             ={ this.FDownloadCSV }
                                                        downloadBox             ={ this.state.downloadBox }

                                                        editLearnerID           ={ this.state.editLearnerID }
                                                        searchTerm              ={ this.state.searchTerm }
                                                        searchResults           ={ this.state.searchResults }
                                                        displaySearchResults    ={ this.state.displaySearchResults }
                                                        activeLearners          ={ this.state.activeLearners }
                                                        />

                    </div>

                    <HtmlModal modal = { this.state.modal } />
                </div>
        );
    }


}

export default Learners;
import React, { Component } from 'react'

import Label from '../Helpers/Label'
import HtmlModal from '../Helpers/HtmlModal'

import Auxiliary from '../HOC/Auxiliary'

class EditServiceProvider extends Component{

    constructor( props ){
        super( props )

        // const fakeAutoComplete = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

        this.state = {      editingServiceProvider              : false,

                            editServiceProviderID               : props.editServiceProviderID,

                            btnSaveUpdateDisabled               : true,

                            spData:{    entityName              : '',
                                        tradingName             : '',
                                        registrationNumber      : '',
                                        leadProvider            : '0',
                                        sdlNumber               : '',
                                        sicCode                 : '',
                                        accreditationNumber     : '',
                                        accreditationReviewDate : '',
                                        contactFirstname        : '',
                                        contactLastname         : '',
                                        workNumber              : '',
                                        mobileNumber            : '',
                                        email                   : '',
                                        password                : '',
                                        canlogin                : 0,
                                        status                  : 'pending',

                                        businessAddress         : '',
                                        businessCity            : '',
                                        businessPostalCode      : '',
                                        businessProvince        : '' },

                            modal:  {   title       :      '',
                            message     :    '',
                            btnAction   :  '',
                            btnCancel   :   '',
                            UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                            actionMethod:   null,
                            data        :   null }
        }
        this.UpdateFormField                = this.UpdateFormField.bind( this )
        this.UpdateServiceProvider          = this.UpdateServiceProvider.bind( this )
        this.SwitchEditingMode              = this.SwitchEditingMode.bind( this )
        this.UpdateCanLogIn                 = this.UpdateCanLogIn.bind( this )

        //console.log( "Edit Service Provider loaded with SP ID : " + this.props.editServiceProviderID )
    }

    UpdateCanLogIn( e ){

        const spData = this.state.spData

        console.log( "Checkbox : " + e.target.checked )

        if( e.target.checked ){
            spData.canlogin = "1"
        } else{
            spData.canlogin = "0"
        }

        this.setState( { spData : spData }, ()=>{
            this.OnClickUpdateSPLogin();
        } );
    }

    SwitchEditingMode( e ){
        console.log( e.target.getAttribute( "action" ) )
        const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
        this.setState( { editingServiceProvider : value } )
    }

    componentDidMount(){
        if( this.state.editServiceProviderID !== '' ){
                //console.log( "Fetching SP data from PHP after componentdidupdate" )
                this.OnLoadFetchSPData();
        } else{
                this.setState( { editingServiceProvider : true } )
        }
    }

    UpdateFormField( event ){
        // event.preventDefault()
        const name  = event.target.name
        const value = event.target.value

        console.log( name + ' : ' + value );

        let data = this.state.spData
        data[name] = value;
        //update state
        this.setState( { data }, () => {

                //***  REQUIRED FIELDS COMPLETE  ***//
                if( this.state.spData.entityName !== '' && this.state.spData.tradingName  !== '' ){
                        this.setState( { btnSaveUpdateDisabled : false } )
                } else{
                        this.setState( { btnSaveUpdateDisabled : true } )
                }
        } )
    }

    //***  UPDATE SERVICE PROVIDER USER LOGIN  ***//
    async OnClickUpdateSPLogin(){

        const inputData =   {
                                class: "serviceproviders",
                                method: "updateSPUserLogin",
                                data: { firstname   : this.state.spData.contactFirstname,
                                        lastname    : this.state.spData.contactLastname,
                                        email       : this.state.spData.email,
                                        password    : this.state.spData.password,
                                        entityID    : this.state.editServiceProviderID,
                                        canlogin    : this.state.spData.canlogin }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                     console.log( "login status update" )
                     if( typeof( getDataResponse ) !== "undefined" ){

                     }
                } catch( err ){ console.log( err ) }
    }

    //***  ON LOAD, FETCH SERVICE PROVIDER DATA  ***//
    async OnLoadFetchSPData(){

        //console.log( "Fetchy fetchy" )

        const inputData =   {
                                class: "serviceproviders",
                                method: "fetchSPData",
                                data: { editServiceProviderID   : this.state.editServiceProviderID }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){

                            //console.log( "Fetch loaded SP data from PHP " + JSON.stringify( getDataResponse.data ) )
                            this.setState( { spData: getDataResponse.data },
                                                    ()=> this.props.SetProfileStatus( this.state.spData.status ) )
                            //this.setState( { editServiceProviderID : getDataResponse.data.serviceProviderID } )
                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATE, EDIT SAVE SERVICE PROVIDER  ***//
    async UpdateServiceProvider( e ){

        //console.log( "Sending to PHP : " + JSON.stringify( this.state.spData ) )

        const inputData =   {
                                class: "serviceproviders",
                                method: "updateServiceProvider",
                                data: { editServiceProviderID   : this.state.editServiceProviderID,
                                        spData                  : this.state.spData }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){

                                // console.log( "After updating SP PHP returned ID : " + getDataResponse.serviceProviderID )

                                this.setState( { editServiceProviderID : getDataResponse.serviceProviderID }, ()=>{
                                        //Confirm update modal
                                        const modal = this.state.modal
                                        modal.title        = "System confirmation"
                                        modal.message      = getDataResponse.message
                                        modal.btnAction    = ''
                                        modal.actionMethod = null
                                        modal.btnCancel    = "OK"

                                        this.setState( { modal : modal } )

                                        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                                } )
                        } else{
                                this.setState( { editServiceProviderID : '' }, ()=>{
                                            //Confirm update modal
                                            const modal = this.state.modal
                                            modal.title        = "System confirmation"
                                            modal.message      = getDataResponse.message
                                            modal.btnAction    = ''
                                            modal.actionMethod = null
                                            modal.btnCancel    = "OK"

                                            this.setState( { modal : modal } )

                                            document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                                    } )
                        }

                } catch( err ){ console.log( err ) }
    }

    render(){

        return(
            <div className = { ( ( this.state.editServiceProviderID === '' ) ? "col-sm-12" : "col-sm-9" ) }>
                <div className={ 'container idzDark'}>

                        {
                                ( this.state.editServiceProviderID !== '' )
                                ?       <div className= { 'row' }>
                                                {
                                                        ( this.props.fHasAccess( 15 ) )
                                                        ?   <div className={ 'col-sm-3 p-1' }>
                                                                <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                                                        action      = 'true'
                                                                        onClick     = { this.SwitchEditingMode } >Edit
                                                                </button>
                                                        </div>
                                                        : ""
                                                }


                                                <div className={ 'col-sm-3 p-1' }>
                                                        <button className={'btn btn-dark btn-sm border-warning btn-block'} onClick={ this.props.LoadDashHandler }>Back</button>
                                                </div>
                                        </div>
                                : ""
                        }

                    <div className= { 'row' }>

                            {/* CANCEL & SAVE */}
                            <div className={ 'col-sm-12 text-white mb-2' } >
                                <h4 className={ 'inlineBlock' }>
                                        { ( this.state.editServiceProviderID !== "" ) ? "Edit" : "Add" } ServiceProvider &nbsp; &nbsp;
                                        { ( this.state.editServiceProviderID !== "" )
                                                ? <span className={ 'text-warning' }>
                                                        { this.state.spData.entityName }
                                                </span>
                                                : "" }
                                </h4>

                                {
                                        ( this.state.editingServiceProvider )
                                        ?       <Auxiliary>
                                                        <button     className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                        disabled        =       { this.state.btnSaveUpdateDisabled }
                                                                        onClick         =       { this.UpdateServiceProvider } >
                                                                {  this.state.editServiceProviderID !== undefined
                                                                        ? "Update" : "Save" }
                                                        </button>

                                                        <button className       = { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                onClick         = { ( this.state.editServiceProviderID ) ? this.SwitchEditingMode : this.props.LoadDashHandler }
                                                                action          = 'cancel' >
                                                                Cancel
                                                        </button>
                                                </Auxiliary>
                                        : ""
                                }
                            </div>
                    </div>

                    <div className={ 'row' }>

                        <div className={ 'col-sm-4 p-2 text-white text-left' }>

                            <input type='hidden' value='prayer' />

                            <Label text='Entity Name' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-danger'
                                    id          ='entityName'
                                    name        ='entityName'
                                    placeholder = 'name'
                                    value       ={ this.state.spData.entityName }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Trading Name' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-danger'
                                    id          ='tradingName'
                                    name        ='tradingName'
                                    placeholder = 'name'
                                    value       ={ this.state.spData.tradingName }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Registration Number' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='registrationNumber'
                                    name        ='registrationNumber'
                                    placeholder = 'name'
                                    value       ={ this.state.spData.registrationNumber }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Lead Service Provider' />
                            <input  type        =   'radio'
                                    value       =   '1'
                                    id          =   'leadProvider'
                                    name        =   'leadProvider'
                                    checked     =   { this.state.spData.leadProvider === "1" }
                                    onChange    =   { this.UpdateFormField } /> &nbsp; Yes &nbsp;

                            <input  type        =   'radio'
                                    value       =   '0'
                                    id          =   'leadProvider'
                                    name        =   'leadProvider'
                                    checked     =   { this.state.spData.leadProvider === "0" }
                                    onChange    =   { this.UpdateFormField } /> &nbsp; No

                            <Label text='SDL Number' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='sdlNumber'
                                    name        ='sdlNumber'
                                    placeholder = 'sdl number'
                                    value       ={ this.state.spData.sdlNumber }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='SIC Code' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='sicCode'
                                    name        ='sicCode'
                                    placeholder = 'sic code'
                                    value       ={ this.state.spData.sicCode }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Accreditation Number' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='accreditationNumber'
                                    name        ='accreditationNumber'
                                    placeholder = 'accreditation nr'
                                    value       ={ this.state.spData.accreditationNumber }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Accreditation Review Date' />
                            <input  type        ='date'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='accreditationReviewDate'
                                    name        ='accreditationReviewDate'
                                    placeholder = 'name'
                                    value       = { this.state.spData.accreditationReviewDate }
                                    onChange    = { this.UpdateFormField }
                                    readOnly    = { !this.state.editingServiceProvider } />


                        </div>

                        <div className={ 'col-sm-4 p-2 text-white text-left' }>

                            <div>
                                <input  type    =   'checkbox'
                                        id      =   'checkboxCanLogIn'
                                        checked =  { ( this.state.spData.canlogin ==='1' ) ? "checked" : "" }
                                        onChange =   { this.UpdateCanLogIn }
                                        disabled = { !this.state.editingServiceProvider } />
                                    &nbsp;
                                    This user can log in
                            </div>

                            <Label text='Contact Person Name' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='contactFirstname'
                                    name        ='contactFirstname'
                                    placeholder = 'contact firstname'
                                    value       ={ this.state.spData.contactFirstname }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Contact Person Surname' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='contactLastname'
                                    name        ='contactLastname'
                                    placeholder = 'contact surname'
                                    value       ={ this.state.spData.contactLastname }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Work Number' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='workNumber'
                                    name        ='workNumber'
                                    placeholder = 'work number'
                                    value       ={ this.state.spData.workNumber }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Mobile Number' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='mobileNumber'
                                    name        ='mobileNumber'
                                    placeholder = 'mobile number'
                                    value       ={ this.state.spData.mobileNumber }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Email Address' />
                            <input  type        ='search'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='email'
                                    name        ='email'
                                    placeholder = 'email address'
                                    autoComplete= 'nope'
                                    value       ={ this.state.spData.email }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                            <Label text='Password' />
                            <input  type        ='password'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='password'
                                    name        ='password'
                                    autoComplete='new-password'
                                    placeholder = 'password'
                                    value       ={ (this.state.spData.password !== null) ? this.state.spData.password : '' }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingServiceProvider } />

                        </div>

                        <div className={ 'col-sm-4 p-2 text-white text-left' }>

                                <div className={ 'text-center' }>
                                    <h5 style={{ fontSize: '1.3vw' }}>BUSINESS ADDRESS</h5>


                                    <Label text='Employer Business Address' />
                                    <textarea
                                            id          =   'businessAddress'
                                            name        =   'businessAddress'
                                            value       =   { this.state.spData.businessAddress }
                                            onChange    =   { this.UpdateFormField }
                                            className   =   'form-control form-control-sm bg-dark text-white'
                                            readOnly    =   { !this.state.editingServiceProvider }   ></textarea>

                                    <Label text='City / Town' />
                                    <input  type        =   'text'
                                            id          =   'businessCity'
                                            name        =   'businessCity'
                                            value       =   { this.state.spData.businessCity }
                                            onChange    =   { this.UpdateFormField }
                                            className   =   'form-control form-control-sm bg-dark border-light text-white'
                                            readOnly    =   { !this.state.editingServiceProvider }  />

                                    <Label text='Postal Code' />
                                    <input  type        =   'text'
                                            id          =   'businessPostalCode'
                                            name        =   'businessPostalCode'
                                            value       =   { this.state.spData.businessPostalCode }
                                            onChange    =   { this.UpdateFormField }
                                            className   =   'form-control form-control-sm bg-dark border-light text-white'
                                            readOnly    =   { !this.state.editingServiceProvider } />

                                    <Label text='Province' />
                                    <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                            id          =   'businessProvince'
                                            name        =   'businessProvince'
                                            value       =   { this.state.spData.businessProvince }
                                            onChange    =   { this.UpdateFormField }
                                            disabled    =   { !this.state.editingServiceProvider }>
                                                <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                                <option value={ 'Free State' } >Free State</option>
                                                <option value={ 'Gauteng' } >Gauteng</option>
                                                <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                                <option value={ 'Limpopo' } >Limpopo</option>
                                                <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                                <option value={ 'North West' } >North West</option>
                                                <option value={ 'Northern Cape' } >Northern Cape</option>
                                                <option value={ 'Western Cape' } >Western Cape</option>
                                    </select>

                                </div>

                    </div>

                    </div>
                    <HtmlModal modal={ this.state.modal } />
                </div>
            </div>
        )
    }
}

export default EditServiceProvider
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
//import Cookies from 'universal-cookie';

import Auxiliary from './HOC/Auxiliary';

import Home from './Home/Home';
import Reports from './Reports/Reports'
import Learners from './Learners/Learners';
import ServiceProviders from './ServiceProviders/ServiceProviders';
import Courses from './Courses/Courses';
import Events from './Events/Events';
import Communication from './Communication/Communication';
import Users from './Users/Users';
import AccessRights from './AccessRights/AccessRights';

import HtmlModal from './Helpers/HtmlModal';

//Main is called by App.js

class Main extends Component{

    constructor( props ){
        super( props )
        this.state = {

                model:  {
                        loading:    false,
                        class:      null,
                        method:     null,
                        askData:    null,
                        getData:    null
                },

                modal: {
                    title       : '',
                    message     : '',
                    btnCancel   : '',
                    UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now()
                }

        }
    }

    componentWillMount() {
        //this.state =  { userId: Cookies.load('userId') }
      }


    componentDidMount(){
        //console.log( "UUID : " + this.state.modal.UUID )
    }

    setCookieHandler = ( par1 ) =>{
        //console.log( "Attempting to set cookie value" );

        //Use the cookie object save in state to set the cookie value
        this.state.cookies.set( 'idzApp', par1, { path: '/' } );

        //Get state from the cookie
        this.setState( { idzApp: this.state.cookies.get( 'idzApp' ) } );
    }

    modalLogoutHandler = () =>{
        //Setup all the values for the modal
        const data = {  title       : "Do you really want to log out?",
                        message     : "",
                        btnAction   : "Yes, LOG OUT",
                        actionMethod: this.props.setLogoutHandler,
                        btnCancel   : "Cancel",
                        UUID        : this.state.modal.UUID,
                        data : null //this stuff will be spread and dynamically added to the action button as key->value pairs
                     }

        //Set the state so that the modal can update
        this.setState( { modal : data } );

        //Make the modal visible
        document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
    }

    render(){
        //const cookies = new Cookies();

        const loadingIcon = ( this.state.model.loading )
                            ? <img  src={ require( "../icon/loadingS.gif" ) }
                                    className={ "loadingGif" }
                                    alt=""/>  : "";

        return (
                <Auxiliary>
                <Router>
                <div className={ 'container-fluid' }>
                    <div className={ 'row p-1' }>

                        <div className={ 'col-sm-3' } >
                            { loadingIcon }
                        </div>

                        <div className={ 'col-sm-9 p-1' }>
                            <img src={ require( '../icon/icon_logout.png') }
                                        alt         = "Log out button"
                                        className   = { 'logOutBtn float-right' }
                                        onClick     = { this.modalLogoutHandler }
                                        style       = {{ marginTop: '5px' }} />

                            <span className={ 'float-right text-white small p-1' }>STATUS : { this.props.appStatus } &nbsp; &nbsp; </span>
                        </div>

                        <div className={ 'col-sm-3' }>

                                    <ul className={ "nav nav-pills flex-column idzDark p-2" }>

                                        <div className={'text-center text-white'} style={{ fontSize : "1.4vw" }}>
                                            { this.props.firstname + ' ' + this.props.lastname }
                                        </div>

                                        <li className={ "nav-item" }>
                                            <Link  to='/' className={ "nav-link bg-dark text-white text-center active" }>
                                                {/* <h1 style={{ fontSize : '4vw' }}>I D Z</h1> */}

                                                <img src={ require('../icon/idz_logo_sml.png') }  alt='Test' />
                                            </Link>
                                        </li>

                                        { /*  IMAGE  */ }
                                        { /*
                                        <img src={ require( "../icon/icon_person.png" ) }
                                                className={ "m-2 mx-auto" } alt="Icon for person" />
                                        */ }

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 active mainMenuFont p-1 pl-3' } to='/' >
                                                Home
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Reports'>
                                                Reports
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Learners'>
                                                Learners
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/ServiceProviders'>
                                                Service Providers
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Training_Programs'>
                                                Training Programs
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Interventions'>
                                                Interventions
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Communication'>
                                                Communication
                                            </Link>
                                        </li>

                                        <li className={ "nav-item" }>
                                            <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/Users'>
                                                Users
                                            </Link>
                                        </li>

                                        {
                                            ( this.props.fHasAccess(10) )
                                            ?   <li className={ "nav-item" }>
                                                    <Link className={ 'nav-link bg-dark text-white mt-1 mainMenuFont p-1 pl-3' } to='/AccessRights'>
                                                        <img src={ require( '../icon/icon_shield.png' ) } className={ "tblIcon" } alt='Security icon' /> &nbsp;
                                                        Access Rights
                                                    </Link>
                                                </li>
                                            : ""
                                        }

                                    </ul>
                        </div>

                        <div className={ 'col-sm-9' }>
                                <Route exact path='/'
                                        render={ () => <Home        getData         =   { this.props.getData } />  } />

                                <Route path='/Reports'
                                        render={ () => <Reports     getData         =   { this.props.getData }
                                                                    fHasAccess      =   { this.props.fHasAccess }
                                                                    userAccessRights=   { this.props.userAccessRights } />  } />

                                <Route path='/Learners'
                                        render={ () => <Learners    sidebar         =   { 'SidebarHome' }
                                                                    box             =   { 'BoxDash' }
                                                                    getData         =   { this.props.getData }
                                                                    fHasAccess      =   { this.props.fHasAccess }
                                                                    userAccessRights=   { this.props.userAccessRights } />  } />
                                <Route path='/ServiceProviders'
                                        render={ () => <ServiceProviders    sidebar         =   { 'SidebarHome'}
                                                                            box             =   {'BoxDash'}
                                                                            getData         =   { this.props.getData }
                                                                            fHasAccess      =   { this.props.fHasAccess }
                                                                            userAccessRights=   { this.props.userAccessRights } />  } />
                                <Route path='/Training_Programs'
                                        render={ () => <Courses     sidebar         =   {'SidebarHome'}
                                                                    box             =   {'BoxDash'}
                                                                    getData         =   { this.props.getData }
                                                                    fHasAccess      =   { this.props.fHasAccess }
                                                                    userAccessRights=   { this.props.userAccessRights } />  } />
                                <Route path='/Interventions'
                                        render={ () => <Events      sidebar         =   {'SidebarHome'}
                                                                    box             =   {'BoxDash'}
                                                                    getData         =   { this.props.getData }
                                                                    fHasAccess      =   { this.props.fHasAccess }
                                                                    userAccessRights=   { this.props.userAccessRights } />  } />

                                <Route path='/Communication'
                                        render={ () => <Communication       getData         = { this.props.getData }
                                                                            userID          = { this.props.userID }
                                                                            fHasAccess      =   { this.props.fHasAccess }
                                                                            userAccessRights=   { this.props.userAccessRights } />  } />

                                <Route path='/Users'
                                        render={ () => <Users   sidebar         =   {'SidebarHome'}
                                                                box             =   {'BoxDash'}
                                                                getData         =   { this.props.getData }
                                                                fHasAccess      =   { this.props.fHasAccess }
                                                                userAccessRights=   { this.props.userAccessRights } /> } />

                                <Route path='/AccessRights'
                                        render={ () => <AccessRights sidebar    =   {'SidebarHome'}
                                                                box             =   {'RolesList'}
                                                                getData         =   { this.props.getData }
                                                                modalHandler    =   { this.props.modalHandler }
                                                                fHasAccess      =   { this.props.fHasAccess }
                                                                userAccessRights=   { this.props.userAccessRights } />  } />
                        </div>

                    </div>

                </div>

            </Router>
            <HtmlModal modal={ this.state.modal } />
            </Auxiliary>
        );

    }

}

export default Main;
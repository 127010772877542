import React from 'react'

const DocumentsList = ( props ) => {


    return(
        <div className={ 'row p-1' }>
            <div className={ 'col-sm-8' }>
                { props.doc.description }
            </div>

            <div className={ 'col-sm-2 text-center' }>

                {/*  Download request sends the base64 encoded filename to PHP as an identifier    */}
                {
                    ( props.doc.filename )
                        ?   <button className       =   { 'btn btn-info btn-sm' }
                                    filereference   =   { btoa( props.doc.filename ) }
                                    onClick         =   { props.FileDownloadHandler } >
                                Download
                            </button>
                        :   <img    src         ={ require( '../../icon/icon_folder_open.png' ) }
                                    alt         ='Add document file'
                                    documentid  ={ props.doc.documentID }
                                    className   ={ 'tblIcon' }
                                    style       ={{ cursor: "pointer" }}
                                    onClick     ={ props.PopupUploadDocumentHandler } />
                }

            </div>

            {
                ( props.fHasAccess(21) )
                ?   <div className={ 'col-sm-2 text-center' }>
                        <img    src         ={ require( '../../icon/icon_delete.png' ) }
                                alt         ='Delete document'
                                documentid  ={ props.doc.documentID }
                                className   ={ 'tblIcon' }
                                style       ={{ cursor: "pointer" }}
                                onClick     ={ props.RequestRemoveDocumentHandler } />
                    </div>
                :   ""
            }

        </div>
    )
}

export default DocumentsList
import React from 'react'

const RowEventDate =( props ) =>{

        return(
            <div className={'input-group'} >
                <input  type        =   'date'
                        className   =   'form-control form-control-sm text-white bg-dark border-white mt-2 mr-2'
                        id          =   { props.dateIndex }
                        value       =   { props.date }
                        readOnly    =   { !props.editingEvent }
                        onChange    =   { props.ManageEventDates } />

                <span className={ "input-group-btn" }>
                    <button     className   =   { "btn btn-search btn-sm btn-outline-danger text-white mt-2" }
                                type        =   "button"
                                action      =   "decrease"
                                dateindex   =   { props.dateIndex }
                                disabled    =   { !props.editingEvent }
                                onClick     =   { props.AdjustEventDaysHandler }>
                                    &#x2212;
                    </button>
                </span>
            </div>
        )
}

export default RowEventDate
import React from 'react'

const AddLearnersSearch = ( props ) =>{

    return(
        <div className={ 'card border-success text-white bg-dark p-2'}>
            <h3>Add Learners</h3>

            {/** SEARCH **/}
                <div className={ 'text-center input-group' }>
                    <input  type        =   'text'
                                id          =   'searchTerm'
                                name        =   'searchTerm'
                                className   =   { "form-control form-control-sm" }
                                value       =   { props.searchTerm }
                                onChange    =   { props.UpdateSearchTermHandler }
                                placeholder =   'search for learners'/>

                    <span className={ "input-group-btn" }>
                        <button     className   =   { "btn btn-search btn-sm btn-outline-success" }
                                    type        =   "button"
                                    onClick     =   { props.SearchToAddLearnersHandler }>

                            <img    src         =   { require( '../../icon/icon_magnifier.png') }
                                    className   =   { "tblIcon" } alt='Magnifier icon'
                                    style       =   {{ pointerEvents: "none" }} />
                        </button>
                    </span>
                </div>
            {/************/}

            <div className={ 'p-1' }>
                {

                    ( props.displaySearchResults.length > 0 )
                    ? <div className={ 'pr-2' } style={{ maxHeight: "65vh", overflowY: "auto" }}>{ props.displaySearchResults }</div>
                    : <div className={'text-warning text-center'}>No results found</div>
                }
            </div>
        </div>
    )
}

export default AddLearnersSearch
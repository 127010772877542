import React from 'react'

const AddLearnersList = ( props ) =>{

    console.log( 'AddLearnersList' + JSON.stringify( props ) )

    return(
        <div className={ 'card border-success text-white bg-dark p-2'}>
            <h3>Allocated Learners</h3>

            {/** SEARCH **/}
                <div className={ 'text-center input-group' }>
                    <input  type            =   'text'
                                id          =   'searchTerm'
                                name        =   'searchTerm'
                                className   =   { "form-control form-control-sm" }
                                value       =   { props.searchAllocatedLearners }
                                onChange    =   { props.UpdateSearchAllocatedLearnersHandler }
                                placeholder =   'search for learners'/>

                    <span className={ "input-group-btn" }>
                        <button     className   =   { "btn btn-search btn-sm btn-outline-success" }
                                    type        =   "button"
                                    onClick     =   { props.FilterAllocatedLearners }>

                            <img    src         =   { require( '../../icon/icon_magnifier.png') }
                                    className   =   { "tblIcon" } alt='Magnifier icon'
                                    style       =   {{ pointerEvents: "none" }} />

                        </button>
                    </span>
                </div>
            {/************/}

            <div className={ 'p-2' }>
                {
                    ( props.displayAllocatedLearners.length !== undefined )
                    ? <div className={ 'pr-2' } style={{ maxHeight: "65vh", overflowY: "auto" }}>{ props.displayAllocatedLearners }</div>
                    : <div className={ 'text-center text-warning' }>No results found</div>
                }
            </div>
        </div>
    )
}

export default AddLearnersList
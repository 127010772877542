import React, { Component } from 'react';

import Label from '../Helpers/Label'
// import Auxiliary from '../HOC/Auxiliary'
import UserLinkSystemRole from './UserLinkSystemRole'

import HtmlModal from '../Helpers/HtmlModal'

class BoxUserData extends Component{

        constructor( props ){
                super( props )

                this.state = {

                        editingUser : false,

                        user:   {
                                userID   : this.props.editUserID,
                                firstname   : '',
                                lastname    : '',
                                password    : '',
                                email       : '',
                                contactNo   : '',
                                department  : '' },
                        systemRoles     : [],
                        saveUserLoading : false,
                        rolesListLinkDisplay : '',

                        modal:  {       title       :      '',
                                        message     :    '',
                                        btnAction   :  '',
                                        btnCancel   :   '',
                                        UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                        actionMethod:   null,
                                        data        :   null }
                }
                this.fetchsystemRoles   = this.fetchsystemRoles.bind( this )
                this.updateUser         = this.updateUser.bind( this )
                this.updateFormField    = this.updateFormField.bind( this )
                this.CheckboxLinkHandler= this.CheckboxLinkHandler.bind( this )
                this.SwitchEditingMode  = this.SwitchEditingMode.bind( this )

                //console.log("BuxUserData : " +  this.props )
        }

        SwitchEditingMode( e ){
                console.log( e.target.getAttribute( "action" ) )
                const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
                this.setState( { editingUser : value } )
        }

        componentWillMount(){
                if( this.props.editUserID !== '' ){
                        this.fetchUserData()
                        this.fetchsystemRoles()
                } else{
                        this.setState( { editingUser : true } )
                }
        }

        async CheckboxLinkHandler( e ){
                console.log( "Clicked on " + e.target.getAttribute( "roleid" ) + " for userID " + e.target.getAttribute( "userid" ) )
                let action = "remove"

                if( !e.target.checked ){
                        console.log( "checked" )
                        action = "add"
                }


                const inputData =   {   class   : "access",
                                        method  : "linkUserRole",
                                        data    : {     userID     :    e.target.getAttribute( "userid" ),
                                                        roleID     :    e.target.getAttribute( "roleid" ),
                                                        action     :    action }
                                        }
                                        try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                                                if( getDataResponse.result === "success" ){
                                                //this.setState( { editEventID : getDataResponse.eventID } )
                                                }
                                        } catch( err ){ console.log( err ) }
        }

        //***  FETCH THE LEARNER DATA IF learnerID exists ***//
        async fetchsystemRoles(){

                const inputData =   {   class: "access",
                                        method: "getAllRoles",
                                        data: { userID       : this.state.user.userID  } }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                return response  } )

                        this.setState( { feedback : getDataResponse.result } );

                        if( getDataResponse.result === "success" ){

                                this.setState( { systemRoles : getDataResponse.data } )
                                const roles = this.state.systemRoles;
                                this.setState( { rolesListLinkDisplay :   roles.map( ( row ) =>
                                                                                <UserLinkSystemRole     id                  = { row.id }
                                                                                                        key                 = { row.id }
                                                                                                        name                = { row.name }
                                                                                                        userID              = { this.state.user.userID }
                                                                                                        roleID              = { row.id }
                                                                                                        selected            = { row.selected }
                                                                                                        CheckboxLinkHandler = { this.CheckboxLinkHandler } />
                                ) } )
                        }

                    } catch( err ){
                        console.log( err );
                    }
            }

        async updateUser(){
                this.setState( { saveUserLoading : true } );

                const inputData =   {   class: "users",
                                        method: "updateUser",
                                        data: {
                                                userID      : this.state.user.userID,
                                                firstname   : this.state.user.firstname,
                                                lastname    : this.state.user.lastname,
                                                password    : this.state.user.password,
                                                email       : this.state.user.email,
                                                contactNo   : this.state.user.contactNo,
                                                department  : this.state.user.department
                                        }
                        }

                        console.log( "Log userData before update : " + JSON.stringify( inputData ) )

                try{
                        //console.log("Login Component request data");
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                //console.log( "Async complete" )
                                return response  } )
                                //console.log( "LC" + getDataResponse.result )
                                //this.setState( { feedback : getDataResponse.result } );

                        if( getDataResponse.result === "success" ){
                                //shallow copy
                                let userData = this.state.user

                                if(  getDataResponse.data !== "" ){
                                        userData["userID"] = getDataResponse.userID
                                        this.setState( { user : userData }, ()=>{
                                                //Confirm update modal
                                                const modal = this.state.modal
                                                modal.title        = "System confirmation"
                                                modal.message      = "Update complete"
                                                modal.btnAction    = ''
                                                modal.actionMethod = null
                                                modal.btnCancel    = "OK"

                                                this.setState( { modal : modal } )

                                                document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                                        } )
                                }
                        }

                } catch( err ){
                        console.log( err );
                }
        }

        saveUserHandler = ( userData ) =>{
                console.log( "Updating the user data from here" + JSON.stringify( userData ) );
        }

        updateFormField( event ){
                event.preventDefault()
                const name  = event.target.name
                const value = event.target.value

                //shallow copy
                let user = this.state.user
                //Assign new value to specific property of deeply nested value
                user[name] = value;
                //update state
                this.setState( { user } )
        }

        //***  FETCH THE LEARNER DATA IF learnerID exists ***//
        async fetchUserData(){

                const inputData =   {
                                        class   : "users",
                                        method  : "returnUserData",
                                        data    : { userID       : this.props.editUserID  }
                }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                //console.log( "Async complete" )
                                return response  } )
                        //console.log( "LC" + getDataResponse.result )
                        this.setState( { feedback : getDataResponse.result } );

                        if( getDataResponse.result === "success" ){
                                //console.log( "XXX" + JSON.stringify( getDataResponse.data ) )
                                this.setState( { user: getDataResponse.data } )
                        }

                    } catch( err ){
                        console.log( err );
                    }
            }

        render(){

                return(
                        <div className={  'col-sm-12' } >

                                {
                                        ( this.state.user.userID !== '' )
                                        ?       <div className= { 'row' }>
                                                        {
                                                                ( this.props.fHasAccess( 13 ) )
                                                                ?   <div className={ 'col-sm-3 p-1' }>
                                                                        <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                                                                action      = 'true'
                                                                                onClick     = { this.SwitchEditingMode } >Edit
                                                                        </button>
                                                                </div>
                                                                : ""
                                                        }


                                                        <div className={ 'col-sm-3 p-1' }>
                                                                <button className={'btn btn-dark btn-sm border-warning btn-block'} onClick={ this.props.loadUserDashHandler }>Back</button>
                                                        </div>
                                                </div>
                                        : ""
                                }


                                {
                                        ( this.state.editingUser)
                                        ?       <div className= { 'row' }>
                                                        <div className={ 'col-12 mb-2' } >
                                                                <h4 className={ 'inlineBlock text-white' }>

                                                                        { ( ( this.state.user.userID !== '' )
                                                                                ? this.state.user.firstname + " " + this.state.user.lastname
                                                                                : "Add User" ) }

                                                                </h4>

                                                                <button className={ 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                        onClick={ this.updateUser }>
                                                                        { ( ( this.state.user.userID !== '' ) ? "Update" : "Save" ) }
                                                                </button>

                                                                <button className = { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                        onClick   = { ( this.state.user.userID !== '' )
                                                                                        ? this.SwitchEditingMode
                                                                                        : this.props.loadUserDashHandler }
                                                                        action    = 'Cancel' >
                                                                        Cancel
                                                                </button>

                                                                <span className={ 'float-right' }> { this.state.saveUserLoading } </span>
                                                        </div>
                                                </div>
                                        :       ""
                                }


                                <div    className={ 'row text-white' }>

                                        <div className={ 'col-sm-6 pl-2 p3-2' } >

                                                <div className={ 'idzDark p-2' }>

                                                        <Label text='Name' />
                                                        <input  type        ='text'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                id          ='firstname'
                                                                name        ='firstname'
                                                                placeholder = 'name'
                                                                value       ={ this.state.user.firstname }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />

                                                        <Label text='Surname' />
                                                        <input  type        ='text'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                name        ='lastname'
                                                                placeholder = 'surname'
                                                                value       ={ this.state.user.lastname }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />

                                                        <Label text         ='Password' />
                                                        <input  type        ='password'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                name        ='password'
                                                                placeholder = 'password'
                                                                value       ={ this.state.user.password }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />

                                                        <Label text='Email' />
                                                        <input  type        ='text'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                name        ='email'
                                                                placeholder = 'email'
                                                                value       ={ this.state.user.email }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />

                                                        <Label text='Contact number' />
                                                        <input  type        ='text'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                name        ='contactNo'
                                                                placeholder = 'contact number'
                                                                value       ={ this.state.user.contactNo }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />


                                                        <Label text='Department' />
                                                        <input  type        ='text'
                                                                className   ='form-control form-control-sm text-white bg-dark border-danger'
                                                                name        ='department'
                                                                placeholder = 'department'
                                                                value       ={ this.state.user.department }
                                                                onChange    ={ this.updateFormField }
                                                                readOnly    ={ !this.state.editingUser } />
                                                </div>
                                        </div>

                                        <div    className ={ 'col-sm-6 idzDark p2 text-center' }
                                                style     ={{ display: ( ( this.state.user.userID !== '' ) ? "block" : "none") }}>

                                                <h4     className       =       { 'text-warning' }
                                                        style           =       {{ fontWeiht: 'bold' }}>
                                                        Assign System Roles
                                                </h4>

                                                { this.state.rolesListLinkDisplay }

                                        </div>
                                </div>

                                <HtmlModal modal={this.state.modal} />
                        </div>
                )
        }
}

export default BoxUserData
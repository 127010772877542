import React, { Component } from 'react';

import Label from '../Helpers/Label'

import Auxiliary from '../HOC/Auxiliary'

import Validation from '../Helpers/Validation'

class Employer extends Component {

        constructor( props ){
                super( props )

                this.state = {  editingEmployer         : false,

                                readOnly                :  true,

                                employerData : {
                                        learnerID       :       this.props.editLearnerID,
                                        firstname       :       '',
                                        lastname        :       '',
                                        occupation      :       '',
                                        employerName    :       '',
                                        employerSDL     :       '',
                                        employerSETA    :       '',
                                        employerSIC     :       '',
                                        leadEmployer    :       '',

                                        businessAddress         :       '',
                                        businessCity            :       '',
                                        businessPostalCode      :       '',
                                        businessProvince        :       '',

                                        postalAddress      :       '',
                                        postalCity         :       '',
                                        postalPostalCode   :       '',
                                        postalProvince     :       ''
                                }
                }
                //console.log( this.props.editLearnerID )
                //console.log( this.state.employerData )
                this.updateFormField    = this.updateFormField.bind( this )
                this.fetchEmployerData  = this.fetchEmployerData.bind( this )
                this.updateEmployer     = this.updateEmployer.bind( this )
                this.SwitchEditingMode  = this.SwitchEditingMode.bind( this )
        }

        SwitchEditingMode( e ){
                console.log( e.target.getAttribute( "action" ) )
                const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
                this.setState( {    editingEmployer : value,
                                    readOnly        : !value } )
        }

        //called before render
        componentWillMount(){

                if( this.props.editLearnerID ) {
                        // let data                = this.state.employerData;
                        // this.setState( { data } )
                        this.fetchEmployerData();
                } else{
                        //console.log( "Learner Employer information no data" )
                }
        }

        updateFormField( event ){
                event.preventDefault()
                const name  = event.target.name
                let value = event.target.value

                //******  VALIDATION ******/
                if( event.target.getAttribute("validation") ){
                        const validationType = event.target.getAttribute("validation") //this must be set on the element
                        value                = Validation( value, validationType ) //use the imported helper function
                }
                //******************************/

                //shallow copy
                let data = this.state.employerData
                //Assign new value to specific property of deeply nested value
                data[name] = value;
                //update state
                this.setState( { data } )
        }

        //***  FETCH THE LEARNER DATA IF learnerID exists ***//
        async fetchEmployerData(){

                console.log( "Attempting to fetch learner data" );
                //this.setState( { loading : true } );

                const inputData =   {
                                        class: "learners",
                                        method: "returnEmployerData",
                                        data: { learnerID       : this.state.employerData.learnerID  }
                }

                    try{
                        const getDataResponse = await this.props.getData( inputData, function( response){
                                //console.log( "Async complete" )
                                return response  } )
                        //console.log( "LC" + getDataResponse.result )
                        this.setState( { feedback : getDataResponse.result } );

                        if( getDataResponse.result === "success" ){

                                //Shallow copy
                                let oldData     =       this.state.employerData
                                //now overwrite it with the data from the web

                                //console.log( "Fetched learner data " + JSON.stringify( getDataResponse.count ) + " rows" )
                                oldData         =       this.state.employerData
                                oldData         =       getDataResponse.data
                                this.setState( { employerData : oldData } )

                                //*** UPDATE LEARNER PROFILE STATUS  ***/
                                /*
                                        Send learner profile status to Learner.js to push it down to the sidebar view
                                */
                               this.props.DisplayLearnerProfileStatusHandler( getDataResponse.data.status )
                        }

                    } catch( err ){
                        console.log( err );
                    }
            }

        async updateEmployer(){

        //console.log( "Running update user method for creating and updating learner " + this.props.editLearnerID );
        this.setState( { loading : true } );

        const inputData =   {
                                class: "learners",
                                method: "updateEmployer",
                                data: {
                                        learnerID               : this.props.editLearnerID,
                                        occupation              : this.state.employerData.occupation,

                                        employerName            : this.state.employerData.employerName,
                                        employerSDL             : this.state.employerData.employerSDL,
                                        employerSETA            : this.state.employerData.employerSETA,
                                        employerSIC             : this.state.employerData.employerSIC,
                                        leadEmployer            : this.state.employerData.leadEmployer,

                                        businessAddress         : this.state.employerData.businessAddress,
                                        businessCity            : this.state.employerData.businessCity,
                                        businessPostalCode      : this.state.employerData.businessPostalCode,
                                        businessProvince        : this.state.employerData.businessProvince,

                                        postalAddress           : this.state.employerData.postalAddress,
                                        postalCity              : this.state.employerData.postalCity,
                                        postalPostalCode        : this.state.employerData.postalPostalCode,
                                        postalProvince          : this.state.employerData.postalProvince
                                }
        }

                try{
                console.log( JSON.stringify( inputData ) )
                //console.log("Login Component request data");
                const getDataResponse = await this.props.getData( inputData, function( response){
                        //console.log( "Async complete" )
                        return response  } )
                //console.log( "LC" + getDataResponse.result )
                //this.setState( { feedback : getDataResponse.result } );

                if( getDataResponse.result === "success" ){
                //     console.log( "Learner created" );
                //     console.log( "Learner ID from PHP : " + getDataResponse.learnerID );
                        this.setState( { loading : false } );
                }

                } catch( err ){
                console.log( err );
                }
        }


        render(){

                return(
                        <div className={ 'col-9 text-white' } >

                                <div className = { 'container' }>

                                        {
                                                ( this.props.fHasAccess( 20 ) )
                                                ?       <div className= { 'row' }>
                                                                {
                                                                        ( this.props.fHasAccess( 19 ) )
                                                                        ?   <div className={ 'col-sm-3 p-1' }>
                                                                                <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                                                                        action      = 'true'
                                                                                        onClick     = { this.SwitchEditingMode } >Edit
                                                                                </button>
                                                                        </div>
                                                                        : ""
                                                                }
                                                        </div>
                                                :       ""
                                        }

                                        <div className= { 'row' }>

                                                {/* CANCEL & SAVE */}
                                                <div className={ 'col-sm-12 text-white mb-2' } >
                                                        <h4 className={ 'inlineBlock' }>
                                                                Employer Data for &nbsp; &nbsp;
                                                                { ( this.state.employerData.learnerID !== '' )
                                                                        ? <span className={ 'text-warning' }>
                                                                                { this.state.employerData.firstname + " " + this.state.employerData.lastname }
                                                                        </span>
                                                                        : "" }
                                                        </h4>

                                                        {
                                                                ( this.state.editingEmployer )
                                                                ?       <Auxiliary>
                                                                                <button         className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                                                disabled        =       { this.state.btnSave }
                                                                                                onClick         =       { this.updateEmployer } >
                                                                                        Update
                                                                                </button>

                                                                                <button className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                                                        onClick         =       {  this.SwitchEditingMode }
                                                                                        action          =       'cancel' >
                                                                                        Cancel
                                                                                </button>
                                                                        </Auxiliary>
                                                                :       ""
                                                        }

                                                </div>
                                        </div>

                                        <div className={ 'row pl-2' } >

                                                <div className={ 'col-sm-4 idzDark p-2' }>

                                                        <div className={ 'text-center' }>
                                                        <h5 style={{ fontSize: '1.3vw' }}>EMPLOYER<br />INFORMATION</h5>
                                                        </div>

                                                        <Label text='Learner Occupation' />
                                                        <input  type        =   'text'
                                                                id          =   'occupation'
                                                                name        =   'occupation'
                                                                value       =   { this.state.employerData.occupation }
                                                                onChange    =   { this.updateFormField }
                                                                readOnly    =   { this.state.readOnly }
                                                                className   =   'form-control form-control-sm bg-dark text-white' />

                                                        <Label text='Employer Name' />
                                                        <input  type        =   'text'
                                                                id          =   'employerName'
                                                                name        =   'employerName'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.employerName }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark text-white' />

                                                        <Label text='Employer SDL Number' />
                                                        <input  type        =   'text'
                                                                id          =   'employerSDL'
                                                                name        =   'employerSDL'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.employerSDL }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark text-white' />

                                                        <Label text='Employer SETA' />
                                                        <input  type        =   'text'
                                                                id          =   'employerSETA'
                                                                name        =   'employerSETA'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.employerSETA }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark text-white' />

                                                        <Label text='Employer SIC Code' />
                                                        <input  type        =   'text'
                                                                id          =   'employerSIC'
                                                                name        =   'employerSIC'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.employerSIC }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark text-white' />

                                                        <Label text='Host Employer' />
                                                        <input  type        =   'radio'
                                                                value       =   'yes'
                                                                id          =   'leadEmployer'
                                                                name        =   'leadEmployer'
                                                                readOnly    =   { this.state.readOnly }
                                                                onChange    =   { ( !this.state.readOnly ) ? this.updateFormField : ()=>{} }
                                                                checked     =   { this.state.employerData.leadEmployer === "yes" } />
                                                                &nbsp; Yes &nbsp;

                                                        <input  type        =   'radio'
                                                                value       =   'no'
                                                                id          =   'leadEmployer'
                                                                name        =   'leadEmployer'
                                                                readOnly    =   { this.state.readOnly }
                                                                checked     =   { this.state.employerData.leadEmployer === "no" }
                                                                onChange    =   { ( !this.state.readOnly ) ? this.updateFormField : ()=>{} }
                                                                 /> &nbsp; No

                                                </div>

                                                <div className={ 'col-sm-4 idzDark p-2' }>

                                                        <div className={ 'text-center' }>
                                                        <h5 style={{ fontSize: '1.3vw' }}>EMPLOYER<br />BUSINESS ADDRESS</h5>
                                                        </div>

                                                        <Label text='Employer Business Address' />
                                                        <textarea
                                                                id          =   'businessAddress'
                                                                name        =   'businessAddress'
                                                                value       =   { this.state.employerData.businessAddress }
                                                                onChange    =   { this.updateFormField }
                                                                readOnly    =   { this.state.readOnly }
                                                                className   =   'form-control form-control-sm bg-dark text-white' ></textarea>

                                                        <Label text='City / Town' />
                                                        <input  type        =   'text'
                                                                id          =   'businessCity'
                                                                name        =   'businessCity'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.businessCity }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark border-light text-white' />

                                                        <Label text='Postal Code' />
                                                        <input  type        =   'text'
                                                                id          =   'businessPostalCode'
                                                                name        =   'businessPostalCode'
                                                                validation  =   'postalcode'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.businessPostalCode }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark border-light text-white' />

                                                        <Label text='Province' />
                                                        <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                                                id          =   'businessProvince'
                                                                name        =   'businessProvince'
                                                                disabled    =  { this.state.readOnly }
                                                                value       =   { this.state.employerData.businessProvince }
                                                                onChange    =   { this.updateFormField } >
                                                                <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                                                <option value={ 'Free State' } >Free State</option>
                                                                <option value={ 'Gauteng' } >Gauteng</option>
                                                                <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                                                <option value={ 'Limpopo' } >Limpopo</option>
                                                                <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                                                <option value={ 'North West' } >North West</option>
                                                                <option value={ 'Northern Cape' } >Northern Cape</option>
                                                                <option value={ 'Western Cape' } >Western Cape</option>
                                                        </select>
                                                </div>

                                                <div className={ 'col-sm-4 idzDark p-2' }>

                                                        <div className={ 'text-center' }>
                                                        <h5 style={{ fontSize: '1.3vw' }}>EMPLOYER<br />POSTAL ADDRESS</h5>
                                                        </div>

                                                        <Label text='Employer Postal Address' />
                                                        <textarea
                                                                id          =   'postalAddress'
                                                                name        =   'postalAddress'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.postalAddress }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark text-white' ></textarea>

                                                        <Label text='City / Town' />
                                                        <input  type        =   'text'
                                                                id          =   'postalCity'
                                                                name        =   'postalCity'
                                                                readOnly    =   { this.state.readOnly }
                                                                value       =   { this.state.employerData.postalCity }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark border-light text-white' />

                                                        <Label text='Postal Code' />
                                                        <input  type        =   'text'
                                                                id          =   'postalPostalCode'
                                                                name        =   'postalPostalCode'
                                                                validation  =   'postalcode'
                                                                value       =   { this.state.employerData.postalPostalCode }
                                                                onChange    =   { this.updateFormField }
                                                                className   =   'form-control form-control-sm bg-dark border-light text-white' />

                                                        <Label text='Province' />
                                                        <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                                                id          =   'postalProvince'
                                                                name        =   'postalProvince'
                                                                disabled    =  { this.state.editingLearner }
                                                                value       =   { this.state.employerData.postalProvince }
                                                                onChange    =   { this.updateFormField } >

                                                                <option value={ 'Eastern Cape' } >Eastern Cape</option>
                                                                <option value={ 'Free State' } >Free State</option>
                                                                <option value={ 'Gauteng' } >Gauteng</option>
                                                                <option value={ 'KwaZulu Natal' } >KwaZulu Natal</option>
                                                                <option value={ 'Limpopo' } >Limpopo</option>
                                                                <option value={ 'Mpumalanga' } >Mpumalanga</option>
                                                                <option value={ 'North West' } >North West</option>
                                                                <option value={ 'Northern Cape' } >Northern Cape</option>
                                                                <option value={ 'Western Cape' } >Western Cape</option>

                                                        </select>
                                                </div>

                                        </div>
                                </div>
                        </div>

        )


        }



}

export default Employer
import React from 'react';

import StatsBlock from '../Helpers/StatsBlock'

const BoxDash = ( props ) =>{

    return(
        <div className={ 'col-9 learnerContent text-white' }>
                <div className= { 'row' }>
                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Training Programs Count' statistic={ props.coursesCount } color='primary' />
                    </div>

                    <div className={ 'col-sm-6' }>
                        <StatsBlock heading='Active Training Programs' statistic={ props.activeCourses } color='primary' />
                    </div>

                </div>

                <div className= { 'row idzDark mt-2' }>
                    {   ( !props.deletingCourse )
                            ?   <div className={ 'col-12' }>
                                    <div className={ 'text-center' }><h4>Search Results</h4></div>
                                    {
                                        ( props.searchResultsHtml !== '' )
                                            ?   <div style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}>{ props.searchResultsHtml }</div>

                                            :   <div className={ 'text-center text-warning' }>No search results to display</div>
                                    }
                                </div>
                            :   <div className={ 'col-12' }>
                                    <i>...deleting content...</i>
                                </div>
                    }
                </div>
        </div>
    );

}

export default BoxDash;
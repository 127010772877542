import React, { Component } from 'react'

import HtmlModal from '../Helpers/HtmlModal'
import Feedback from '../Helpers/Feedback'

class NewPassword extends Component{

    constructor( props ){
        super( props )

        this.state  =   {   feedback : '',

                            password1   : '',
                            password2   : '',
                            tokenOK     : false,
                            resetReady  : false,

                            modal   :   {   title           : '',
                                            message         : '',
                                            UUID            : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                                            btnAction       : '',
                                            actionMethod    : null,
                                            btnCancel       : ''
                                        }
                        }
        this.UpdatePassword1 = this.UpdatePassword1.bind( this )
        this.UpdatePassword2 = this.UpdatePassword2.bind( this )
        this.SaveNewPassword = this.SaveNewPassword.bind( this )
    }

    componentDidMount(){
        console.log( "Reset loaded" + this.props.resetToken );
        this.ConfirmResetToken();
    }

    async ConfirmResetToken(){

        const inputData =   {   class: "users",
                                method: "confirmResetToken",
                                data: { token   : this.props.resetToken } }

            try{ const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){
                    this.setState( { tokenOK : true } )

                }

            } catch( err ){ console.log( err ) }
    }

    async SaveNewPassword(){

        const inputData =   {   class: "users",
                                method: "saveNewPassword",
                                data: { token       : this.props.resetToken,
                                        password    : this.state.password1 } }

            try{ const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                if( getDataResponse.result === "success" ){
                    const modal = this.state.modal
                    modal.title     = 'Password reset successfully'
                    modal.message   = 'Your password was reset successfully. You can now return to the login page to log in.'
                    modal.btnAction = 'Back to login page'
                    modal.actionMethod = ()=>{ window.location.assign( "/" ) }
                    modal.btnCancel = ''

                    this.setState( { modal: modal }, ()=>{
                        document.querySelector( "#" + this.state.modal.UUID ).style.display = 'block'
                    } )
                }

            } catch( err ){ console.log( err ) }
    }

    UpdatePassword1( e ){
        this.setState( { password1 : e.target.value },()=>{
            this.CheckPasswordResetReady()
        } )
    }

    UpdatePassword2( e ){
        this.setState( { password2 : e.target.value },()=>{
            this.CheckPasswordResetReady()
        } )
    }

    CheckPasswordResetReady(){
        if( this.state.password1.length > 4 && this.state.password2.length >4 &&
            this.state.password1 === this.state.password2 ){
            this.setState( {    resetReady  : true,
                                feedback    : '' } )
        } else{
            this.setState( {    resetReady  : false,
                                feedback    : 'Passwords need to be five characters in length and must match.' } )
        }
    }

    render(){
        return(
            <div className={ 'container vertical-center' } >
                    <div className={ "row" } >

                        <div className={ "col-6 mx-auto text-center text-white  p-3" } >
                            <h1>I D Z</h1>

                            <h5 className={ "text text-info"} >Industrial Development Zone</h5>

                            {/* token is invalid */}
                            { !this.state.tokenOK &&
                              <div>
                                    <div className={'alert alert-danger'}>
                                        Sorry. That token is invalid. Please request a new password reset token.
                                    </div>
                                    <br />
                                    <a      href='/'
                                            className   =   { 'btn btn-primary' } >
                                        Go back to the login page
                                    </a>
                                </div> }

                            {/* token is VALID */}
                            { this.state.tokenOK &&
                                <div className={ "idzDark p-3" } style={{ borderTop: "6px solid #17a2b8" }} >

                                    <h2 style={{ fontSize: "1.5vw" }}>Enter a new password</h2>
                                    <br />

                                    { !this.state.resetReady &&
                                        <div className={'alert alert-warning text-center p-1'}>
                                            Your password must be at least 5 characters in length.<br />
                                            You may use letters and digits.<br />
                                        </div> }
                                    <br />
                                    <div className={ "form-group" } >
                                            <input  type    =   'password'
                                                placeholder =   'new password'
                                                name        =   'password1'
                                                id          =   'password1'
                                                className   =   { 'form-control col-sm-8 mx-auto' }
                                                value       =   { this.state.password1 }
                                                onChange    =   { this.UpdatePassword1 }/>
                                    </div>

                                    <div className={ "form-group mt-2" } >
                                            <input  type    =   'password'
                                                placeholder =   'type the password again'
                                                name        =   'password2'
                                                id          =   'password2'
                                                className   =   { 'form-control col-sm-8 mx-auto' }
                                                value       =   { this.state.password2 }
                                                onChange    =   { this.UpdatePassword2 }/>
                                    </div>

                                    { this.state.resetReady &&
                                        <button type='button'
                                            className   =   { 'btn btn-dark mx-auto m-2 border-info mx-auto d-block' }
                                            onClick     =   { this.SaveNewPassword } >
                                            SAVE NEW PASSWORD NOW
                                        </button> }

                                    {   this.state.feedback.length > 1 &&
                                        <Feedback  message={ this.state.feedback } />
                                    }

                                    <a  href        = '/'
                                        type        =   "button"
                                        className   =   { "btn btn-outline-white bg-dark mt-5 text-white mx-auto" }
                                        onClick     =   { null }  >
                                        <i>Back to Login page</i>
                                    </a>
                                </div> }

                        </div>
                    </div>
                    <HtmlModal modal = { this.state.modal } />
            </div>
        )
    }
}

export default NewPassword
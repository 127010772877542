import React from 'react'

const UploadDocument = ( props ) =>{

    return(
        <div>
            <input  type        =   'file'
                    name        =   'uploadFile'
                    documentid  =   { props.documentid }
                    accept      =   "application/pdf"
                    onChange    =   { props.FileSelectedHandler } />

            <br />
            <br />
                <button     className   =   { 'btn btn-success border-success bg-dark' }
                            id          =   "btnUploadDocument"
                            documentid  =   { props.documentid }
                            onClick     =   { props.FileUploadHandler } >
                    Upload document now
                </button>
        </div>
    )
}

export default UploadDocument
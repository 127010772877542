import React, { Component } from 'react'

import Label from '../Helpers/Label'
import HtmlModal from '../Helpers/HtmlModal'

import Auxiliary from '../HOC/Auxiliary'

class BoxEditCourse extends Component{

    constructor( props ){
        super( props )

        this.state = {      editCourseID                    : this.props.editCourseID,

                            editingCourse                   : false,

                            searchTerm                      : '',

                            btnSaveUpdateDisabled           : true,

                            courseData:{    name                : '',
                                            serviceProviderName : '',
                                            serviceProviderID   : 0,
                                            credits             : '',
                                            category            : '',
                                            nominalHours        : 0,
                                            code                : '',
                                            seta                : '',
                                            memo                : '',
                                            status              : '' },

                            listCourseCategories    : this.props.listCourseCategories,
                            listServiceProviders    : this.props.listServiceProviders,

                            modal:  {   title       :      '',
                            message     :    '',
                            btnAction   :  '',
                            btnCancel   :   '',
                            UUID        : String.fromCharCode(65 + Math.floor(Math.random() * 26)) + Date.now(),
                            actionMethod:   null,
                            data        :   null }
        }
        this.UpdateFormField       = this.UpdateFormField.bind( this )
        this.UpdateCourse          = this.UpdateCourse.bind( this )
        this.SwitchEditingMode     = this.SwitchEditingMode.bind( this )
    }

    SwitchEditingMode( e ){
        // console.log( e.target.getAttribute( "action" ) )
        const value = ( e.target.getAttribute( "action" ) === 'true' ) ? true : false
        this.setState( { editingCourse : value } )
    }

    componentDidMount(){
        if( this.props.editCourseID !== '' ){
                //console.log( "Fetching course data from PHP after componentdidupdate with ID : " + this.props.editCourseID )
                this.OnLoadFetchCourseData();
        } else{
                this.setState( { editingCourse : true } )
        }
    }

    UpdateFormField( event ){
        event.preventDefault()
        const name  = event.target.name
        const value = event.target.value

        let data = this.state.courseData
        data[name] = value;
        //update state
        this.setState( { data }, function(){

                //***  REQUIRED FIELDS COMPLETE  ***//
                if( this.state.courseData.name !== '' && this.state.courseData.serviceProviderID !== 0 ){
                        this.setState( { btnSaveUpdateDisabled : false } )
                } else{
                        this.setState( { btnSaveUpdateDisabled : true } )
                }

        } )

    }

    //***  ON LOAD, FETCH COURSE DATA  ***//
    async OnLoadFetchCourseData(){

        const inputData =   {
                                class: "courses",
                                method: "fetchCourseData",
                                data: { editCourseID   : this.props.editCourseID }
        }
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){

                            //console.log( "Fetch loaded SP data from PHP " + JSON.stringify( getDataResponse.data ) )
                            this.setState( { courseData: getDataResponse.data },
                                ()=>{
                                        console.log( 'loaded ' + getDataResponse.data.status )
                                        //this.props.status = getDataResponse.data.status
                                        this.props.fUpdateTraingProgramStatusAfterSelectingIt( getDataResponse.data.status )
                                } )

                        }
                } catch( err ){ console.log( err ) }
    }

    //***  UPDATE, EDIT SAVE COURSE  ***//
    async UpdateCourse(){
        const inputData =   {
                                class: "courses",
                                method: "updateCourse",
                                data: { editCourseID   : this.state.editCourseID,
                                        courseData     : this.state.courseData }
        }

        // console.log( this.state.courseData );
            try {    const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )
                        if( getDataResponse.result === "success" ){
                                this.setState( { editCourseID : getDataResponse.courseID },

                                        ()=>{
                                                this.props.UpdateEditCourseID( getDataResponse.courseID )
                                                //Confirm update modal
                                                const modal = this.state.modal
                                                modal.title        = "System confirmation"
                                                modal.message      = "Update complete"
                                                modal.btnAction    = ''
                                                modal.actionMethod = null
                                                modal.btnCancel    = "OK"

                                                this.setState( { modal : modal } )

                                                document.querySelector( "#" + this.state.modal.UUID ).style.display = "block"
                                        } )
                        }
                } catch( err ){ console.log( err ) }
    }

    render(){

        return(
            <div className = { ( ( this.props.editCourseID === "" ) ? "col-sm-12" : "col-sm-9" ) }>
                <div className={ 'container idzDark'}>

                    {
                            ( this.state.editCourseID !== '' )
                            ?       <div className= { 'row' }>
                                            {
                                                    ( this.props.fHasAccess( 17 ) )
                                                    ?   <div className={ 'col-sm-3 p-1' }>
                                                            <button     className   = { 'btn btn-dark btn-sm border-warning btn-block' }
                                                                    action      = 'true'
                                                                    onClick     = { this.SwitchEditingMode } >Edit
                                                            </button>
                                                    </div>
                                                    : ""
                                            }


                                            <div className={ 'col-sm-3 p-1' }>
                                                    <button className={'btn btn-dark btn-sm border-warning btn-block'} onClick={ this.props.LoadDashHandler }>Back</button>
                                            </div>
                                    </div>
                            : ""
                    }

                    <div className= { 'row' }>


                            {/* CANCEL & SAVE */}
                            <div className={ 'col-sm-12 text-white mb-2' } >
                                <h4 className={ 'inlineBlock' }>
                                        { ( this.state.editCourseID !== "" ) ? "Edit" : "Add" } Program &nbsp; &nbsp;
                                        { ( this.state.editCourseID !== "" )
                                                ? <span className={ 'text-warning' }>
                                                        { this.state.courseData.name }
                                                </span>
                                                : "" }
                                </h4>


                                {/***  SAVE / UPDATE  ***/}

                                {
                                    ( this.state.editingCourse )
                                    ?   <Auxiliary>
                                            <button     className       =       { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                            disabled        =       { this.state.btnSaveUpdateDisabled }
                                                            onClick         =       { this.UpdateCourse } >
                                                    {  this.state.editCourseID !== undefined
                                                            ? "Update" : "Save" }
                                            </button>

                                            {/***  CANCEL  ***/}
                                            <button className   = { 'btn  text-white btn-outline-warning btn-sm float-right m-1' }
                                                    onClick     = { ( this.state.editCourseID !== '' ) ? this.SwitchEditingMode : this.props.LoadDashHandler }
                                                    action      = 'cancel' >
                                                    Cancel
                                            </button>
                                        </Auxiliary>
                                    : ""
                                }

                            </div>
                    </div>

                    <div className={ 'row' }>

                        <div className={ 'col-sm-6 p-2 text-white text-left' }>

                            <Label text='Course Name' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-danger'
                                    id          ='name'
                                    name        ='name'
                                    placeholder = 'course name'
                                    value       ={ this.state.courseData.name }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingCourse } />

                            <Label text='Service Provider' />
                            <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                    id          =   'serviceProviderID'
                                    name        =   'serviceProviderID'
                                    value       =   { this.state.courseData.serviceProviderID }
                                    onChange    =   { this.UpdateFormField }
                                    disabled    =   { !this.state.editingCourse } >
                                    <option>Select a Service Provider</option>
                                    { this.state.listServiceProviders.map( ( row )=>(
                                        <option key={ row["id"] } value={ row["id"] }>{ row["entityName"] }</option>
                                    ))  }
                            </select>

                            <Label text='Course Category' />
                            <select className   =   { 'btn btn-outline-info btn-block btn-sm selectCustomClassInfo' }
                                    id          =   'category'
                                    name        =   'category'
                                    value       =   { this.state.courseData.category }
                                    onChange    =   { this.UpdateFormField }
                                    disabled    =   { !this.state.editingCourse } >
                                    <option>Select a Category</option>
                                    { this.state.listCourseCategories.map( ( row )=>(
                                        <option key={ row["id"] } value={ row["id"] }>{ row["value"] }</option>
                                    ))  }
                            </select>

                        </div>


                        <div className={ 'col-sm-6 p-2 text-white text-left' }>
                            <Label text='Course Credits' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='credits'
                                    name        ='credits'
                                    placeholder = 'credits'
                                    value       ={ this.state.courseData.credits }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingCourse } />



                            <Label text='Notional Hours' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='nominalHours'
                                    name        ='nominalHours'
                                    placeholder = 'notional hours'
                                    value       ={ this.state.courseData.nominalHours }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingCourse } />

                            <Label text='Course Code' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='code'
                                    name        ='code'
                                    placeholder = 'code'
                                    value       ={ this.state.courseData.code }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingCourse } />

                            <Label text='SETA' />
                            <input  type        ='text'
                                    className   ='form-control form-control-sm text-white bg-dark border-white'
                                    id          ='seta'
                                    name        ='seta'
                                    placeholder = 'seta'
                                    value       ={ this.state.courseData.seta }
                                    onChange    ={ this.UpdateFormField }
                                    readOnly    ={ !this.state.editingCourse } />
                        </div>
                    </div>

                    <div className={ "row" }>
                        <div className={ 'col-sm-12 p-2 text-white text-left' }>
                            <Label text='Information about the course' />
                            <textarea   type        ='date'
                                        className   ='form-control form-control-sm text-white bg-dark border-white'
                                        id          ='memo'
                                        name        ='memo'
                                        placeholder = 'name'
                                        style       = {{ height: "100px" }}
                                        value       ={ this.state.courseData.memo }
                                        onChange    ={ this.UpdateFormField } />


                        </div>
                    </div>

                    <HtmlModal modal={ this.state.modal } />
                </div>
            </div>
        )
    }
}

export default BoxEditCourse
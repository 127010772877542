import React, {Component} from 'react'

import StatsBlock from '../Helpers/StatsBlock'

class Home extends Component {

    constructor( props ){
        super( props )

        this.state  =   {   learnerCount        : 0,
                            serviceProviders    : 0,
                            interventions       : 0,
                            trainingPrograms    : 0 }
    }

    componentDidMount(){
        this.fetchHomePageStats()
    }

    async fetchHomePageStats(){

        const inputData =   {   class: "stats",
                                method: "homepageStats",
                                data: null }

            try{
                const getDataResponse = await this.props.getData( inputData, function( response){ return response  } )

                    if( getDataResponse.result === "success" ){
                        const data = getDataResponse.data
                        this.setState(  {   learnerCount        : data.learners,
                                            serviceProviders    : data.serviceProviders,
                                            interventions       : data.interventions,
                                            trainingPrograms    : data.trainingPrograms  } )
                    }


            } catch( err ){ console.log( err ) }
    }

    render(){

        return(
                <div className={ 'section' } >

                    <div className={ 'row' }>

                        <div className={ 'col-6 p-3'}>
                            <StatsBlock     heading     =   'Registered Learner Count'
                                            statistic   =   { this.state.learnerCount }
                                            color       =   'primary'
                                            image       =   'icon_graduation_cap.png' />
                        </div>

                        <div className={ 'col-6 p-3'}>
                            <StatsBlock     heading     =   'Service Providers'
                                            statistic   =   { this.state.serviceProviders }
                                            color       =   'danger'
                                            image       =   'icon_envelope.png' />
                        </div>

                    </div>

                    <div className={ 'row' }>

                        <div className={ 'col-6 p-3'}>
                            <StatsBlock     heading     =   'Interventions'
                                            statistic   =   { this.state.interventions }
                                            color       =   'success'
                                            image       =   'icon_id_card.png' />
                        </div>

                        <div className={ 'col-6 p-3'}>
                            <StatsBlock     heading     =   'Training Programs'
                                            statistic   =   { this.state.trainingPrograms }
                                            color       =   'warning'
                                            image       =   'icon_clipboard.png' />
                        </div>

                    </div>

                </div>
        );
    }
}

export default Home
import React from 'react'

const RowSearchResult = ( props ) =>{


    return(

            <div className={ 'card card-warning bg-dark border-warning m-1 p-2 text-white' }>

                <div className={ "container" }>
                    <div className={ "row" }>

                        <div className={ "col-sm-3" }>
                            <button     onClick             =   { props.SelectServiceProviderHandler }
                                        serviceproviderid   =   { props.data.providerID }
                                        className           =   { 'btn btn-dark border-warning btn-sm' } >
                                VIEW
                            </button>
                        </div>

                        <div className={ "col-sm-6" } style={{ fontSize: "1.3vw" }}>
                            <div>
                                { props.data.entityName }<br />
                                { props.data.tradingName }<br />

                                {   ( props.data.email !== "" ) ?   <span>
                                                                        <img    src     =   { require( "../../icon/icon_mail.png" ) }
                                                                                className   =   { 'mr-2' }
                                                                                style   =   {{ height: "20px" }}
                                                                                alt     =   'Icon mail' />
                                                                        <span   className=  { 'text-warning smaller mr-2' }
                                                                                style   =   {{ fontSize: "1vw" }} >
                                                                                { props.data.email }
                                                                        </span>
                                                                    </span>
                                                                :   "" }

                                {   ( props.data.email !== "" ) ?   <span>
                                                                        <img    src     =   { require( "../../icon/icon_phone.png" ) }
                                                                                className   =   { '' }
                                                                                style   =   {{ height: "20px" }}
                                                                                alt     =   'Icon mail' />
                                                                        <span   className=  { 'text-warning smaller' }
                                                                                style   =   {{ fontSize: "1vw" }} >
                                                                            { props.data.mobileNumber }
                                                                        </span>
                                                                    </span>
                                                                :   "" }
                            </div>

                        </div>

                        <div className={ "col-sm-3" }>
                            <img    src                 =   { require( "../../icon/icon_delete.png" ) }
                                    className           =  { "float-right" }
                                    onClick             =   { props.DeleteServiceProviderHandler }
                                    action              =   "confirm"
                                    serviceproviderid   =   { props.data.providerID }
                                    style               =   {{  height: "20px",
                                                                cursor: "pointer",
                                                                borderRadius: "0.3em",
                                                                border: "1px solid red",
                                                                padding: "3px" }}
                                    alt                 =   'Icon delete' />
                        </div>

                    </div>
                </div>
            </div>
    )
}

export default RowSearchResult